import {
  Component,
  Inject,
  OnInit,
  Optional,
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-news-dialog",
  templateUrl: "./news-dialog.html",
  styleUrls: ["./news-dialog.scss"],
})
export class NewsDialog implements OnInit, AfterViewChecked {
  action: string;
  local_data: any;
  setupForm = new FormGroup({
    content: new FormControl("", [Validators.required]),
    title: new FormControl("", [Validators.required]),
    videoUrl: new FormControl("", []),
    logoAttachmentId: new FormControl("", []),
    attachmentType: new FormControl("", []),
    isEvent: new FormControl("", []),
    eventAttachmentId: new FormControl("", []),
  });

  constructor(
    public dialogRef: MatDialogRef<NewsDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private common: CommonService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  onValChange(val: any) {
    this.local_data.status = val;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {}

  doAction() {
    if (this.validate() === true) {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
  }

  validate() {
    if (
      this.local_data.logoAttachmentId === 0 ||
      _.isNull(this.local_data.logoAttachmentId) ||
      _.isUndefined(this.local_data.logoAttachmentId)
    ) {
      this.common.openSnackBar(
        "Article Photo is required, please upload an photo.",
        "Close"
      );
      return false;
    }
    if (this.local_data.isEvent) {
      if (
        this.local_data.eventAttachmentId === 0 ||
        _.isNull(this.local_data.eventAttachmentId) ||
        _.isUndefined(this.local_data.eventAttachmentId)
      ) {
        this.common.openSnackBar(
          "Event Photo is required, please upload an photo.",
          "Close"
        );
        return false;
      }
    }
    return true;
  }
  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== "Delete") {
      return true;
    }
    return false;
  }
  fileUploaded(val: any) {
    if (_.isUndefined(val.val)) {
      delete this.local_data[val.key];
    } else {
      this.local_data[val.key] = val.val.id;
    }
  }
  tabOnChange(val: any) {
    this.local_data.attachmentType = val;
  }
  toggleOnChange() {
    if (this.local_data.isEvent === false) {
      this.local_data.eventAttachmentId = null;
    }
  }
}
