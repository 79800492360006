import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-default",
  templateUrl: "./default.component.html",
  styleUrls: ["./default.component.scss"],
})
export class DefaultComponent implements OnInit {
  constructor(private router: Router, private common: CommonService) {}
  currentRoute: any;

  allRoute = this.common.getNavRoute();

  ngOnInit(): void {
    let routes = _.flatMap(this.allRoute, (ele: any) => {
      if (ele.path !== "") {
        return ele;
      } else if (ele.path === "") {
        return ele.childrens;
      }
    });

    let fndIdx = _.findIndex(routes, (ele) => {
      return ele["path"] === this.router.url;
    });
    if (fndIdx >= 0) {
      this.currentRoute = routes[fndIdx];
    }
  }
  menuOnClicked(val: any) {
    this.currentRoute = val;
  }
}
