import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ApiReportService } from 'src/app/service/api.report.service';

import { CommonService } from 'src/app/service/common.service';
import { RequestDialog } from './requests-dialog/requests-dialog';

@Component({
  selector: 'report-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api: ApiReportService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    this.changeDetectorRef
  );
  page = 0;
  pageSize = 10;
  totalLength = 0;
  formData = { search: '' };
  displayedColumns = [
    'id',
    'createdDate',

    'areaCity',
    'areaPhase',
    'areaTitle',
    'address',
    'unitNumber',
    'requestedByFullName',
    'estimateWeightKg',
    'remark',
    'status',
    'action',
  ];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData({
      page: this.page || 0,
      pageSize: this.pageSize,
      sortByColumn: 'createdDate',
      order: 'desc',
      search: this.formData.search,
    });
  }
  ngAfterViewInit() {}
  async initData(val: any) {
    let requests = await this.getRequests(val);
    this.dataSource = new MatTableDataSource(requests);
  }
  search() {
    this.initData({
      page: this.page || 0,
      pageSize: this.pageSize,
      search: this.formData.search,
      sortByColumn: 'createdDate',
      order: 'desc',
    });
  }

  getRequests(body: any): any {
    return new Promise((resolve, reject) => {
      this.api.getRequests(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.totalLength = Number(res.headers.get('totalRecordCount')) || 0;
            console.log(res.body);
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getStatus(val: any) {
    return this.common.getRequestStatus(val);
  }

  sortData(col: any) {
    this.initData({
      page: this.page || 0,
      pageSize: this.pageSize,
      sortByColumn: col.active,
      order: col.direction,
      search: this.formData.search,
    });
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(RequestDialog, {
      width: '100%',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.initData({
        page: this.page || 0,
        pageSize: this.pageSize,
        search: this.formData.search,
        sortByColumn: 'createdDate',
        order: 'desc',
      });
    });
  }

  async pageChanged(val: any) {
    this.page = val['pageIndex'] || 0;
    this.pageSize = val['pageSize'] || 10;

    let nextRequests = await this.getRequests({
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      order: 'desc',
    });

    // this. = _.cloneDeep(nextRequests);
    this.dataSource = new MatTableDataSource(nextRequests);
  }
}
