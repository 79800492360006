import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-smart-table-button",
  templateUrl: "./smart-table-button.component.html",
  styleUrls: ["./smart-table-button.component.scss"],
})
export class SmartTableButtonComponent implements OnInit {
  @Input() rowData: any;
  @Input() iconName: any;
  @Output() click: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  buttonClicked(val: any) {
    this.click.emit(val);
  }
}
