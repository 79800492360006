import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { throwError } from "rxjs";
import { v4 as uuidv4 } from "uuid";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private _snackBar: MatSnackBar) {}

  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  openSnackBar(msg: any, buttonName: any = "Ok") {
    this._snackBar.open(msg, buttonName, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 5000,
    });
  }

  getUniqDevice() {
    let device = {
      deviceId: uuidv4(),
      deviceType: 1,
      pushNotificationData:
        "send push notification token here later.. now u can put empty",
      guiVersion: "1.0.0",
    };
    return device;
  }
  getStatus(val: any) {
    if (val === 0) {
      return "Draft";
    }
    if (val === 1) {
      return "Scheduled";
    }
    if (val === 2) {
      return "Started";
    }
    if (val === 3) {
      return "Reached";
    }
    if (val === 4) {
      return "Completed";
    }
    if (val === 5) {
      return "Cancelled";
    }
    if (val === 7) {
      return "P.Complete";
    }
    return "";
  }
  getRequestStatus(val: any) {
    if (val === 0) {
      return "Requested";
    }
    if (val === 1) {
      return "Completed";
    }
    if (val === 2) {
      return "Rejected";
    }
    return "";

    //   {
    //     ,
    //     COMPLETED,
    //     REJECTED
    // }
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `${error.error.errorMessage}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  getFullDateTimeString(dt: any, fromTo: string = "") {
    var today = new Date(dt);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var hh = today.getHours();
    var min = today.getMinutes();
    var sec = today.getSeconds();
    let dd2,
      mm2,
      hh2,
      min2,
      sec2 = "";
    let today2 = "";
    dd2 = String(dd);
    mm2 = String(mm);
    hh2 = String(hh);
    min2 = String(min);
    sec2 = String(sec);
    if (dd < 10) {
      dd2 = "0" + dd;
    }

    if (mm < 10) {
      mm2 = "0" + mm;
    }

    if (hh < 10) {
      hh2 = "0" + hh;
    }
    if (min < 10) {
      min2 = "0" + min;
    }
    if (sec < 10) {
      sec2 = "0" + sec;
    }

    if (fromTo !== "") {
      today2 =
        yyyy + "/" + mm2 + "/" + dd2 + " " + hh2 + ":" + min2 + ":" + sec2;
    }
    if (fromTo === "from") {
      today2 = yyyy + "/" + mm2 + "/" + dd2 + " 00:00:00";
    }
    if (fromTo === "to") {
      today2 = yyyy + "/" + mm2 + "/" + dd2 + " 23:59:59";
    }

    return today2;
  }
  getCurrentDate(dt: any = new Date()) {
    var today = new Date(dt);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    let dd2, mm2;
    let today2 = "";
    dd2 = String(dd);
    mm2 = String(mm);
    if (dd < 10) {
      dd2 = "0" + dd;
    }

    if (mm < 10) {
      mm2 = "0" + mm;
    }

    today2 = yyyy + "/" + mm2 + "/" + dd2;
    return today2;
  }
  getAPIDateString(field: any) {
    let dt = new Date(field);
    let year = String(dt.getFullYear());
    let month = String(dt.getMonth() + 1);
    month = month.length === 1 ? "0" + month : month;
    let day = String(dt.getDate());
    day = day.length === 1 ? "0" + day : day;
    return year + "-" + month + "-" + day;
  }
  getCurrentISODateString(dt: Date) {
    let backpart = "T00:01:00.000Z";
    let yr = dt.getFullYear().toString();
    let mth = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();

    mth = mth.length <= 1 ? "0" + mth : mth;
    day = day.length <= 1 ? "0" + day : day;

    return yr + "-" + mth + "-" + day + backpart;
    // '2022-07-09T00:00:00.000Z'
  }
  isJson(jsonStr: string) {
    try {
      if (JSON.parse(jsonStr)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  getNavRoute() {
    return [
      {
        path: "/",
        name: "Dashboard",
        icon: "place",
      },
      {
        path: "/schedule",
        name: "Schedule",
        icon: "schedule",
      },
      {
        path: "",
        name: "Setup",
        icon: "extension",
        childrens: [
          {
            path: "/map",
            name: "Map",
            icon: "",
          },
          {
            path: "/house",
            name: "House Unit",
            icon: "",
          },
          {
            path: "/driver",
            name: "Driver",
            icon: "",
          },
          {
            path: "/assistant",
            name: "Assistant",
            icon: "",
          },
          {
            path: "/lorry",
            name: "Lorry",
            icon: "",
          },
        ],
      },
      {
        path: "",
        name: "Configuration",
        icon: "build",
        childrens: [
          {
            path: "/admin-user",
            name: "Admin Users",
            icon: "",
          },
          {
            path: "/app-user",
            name: "Apps Users",
            icon: "",
          },
          {
            path: "/repeat",
            name: "Repeat Schedule",
            icon: "",
          },
          {
            path: "/news",
            name: "News & Article",
            icon: "",
          },
          {
            path: "/time-table",
            name: "Timetable",
            icon: "",
          },
          {
            path: "/faq",
            name: "FAQ",
            icon: "",
          },
          {
            path: "/share-info",
            name: "Share Info",
            icon: "",
          },

          {
            path: "/notification",
            name: "Alert Notification",
            icon: "",
          },
          {
            path: "/apk",
            name: "Driver Apps",
            icon: "",
          },
        ],
      },
      {
        path: "",
        name: "Report",
        icon: "",
        childrens: [
          {
            path: "/report-requests",
            name: "Requests",
            icon: "",
          },
          {
            path: "/report-collection",
            name: "Collection",
            icon: "",
          },
        ],
      },

      {
        path: "/backdate-collection",
        name: "Backdate Collection",
        icon: "schedule",
      },
      {
        path: "/bi",
        name: "BI",
        icon: "bi",
      },
    ];
  }
}
