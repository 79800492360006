import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "src/app/service/common.service";
import { LoginService } from "src/app/service/login.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: [],
})
export class LoginComponent implements OnInit {
  responsedata: any;
  dt = new Date();

  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = "";
  roles: string[] = [];

  constructor(
    private loginServ: LoginService,
    private route: Router,
    private common: CommonService
  ) {
    localStorage.clear();
  }

  ngOnInit(): void {
    setInterval(() => {
      this.dt = new Date();
    }, 60 * 1000);
  }

  Proceedlogin() {
    if (this.loginForm.valid) {
      this.loginServ.Proceddlogin(this.loginForm.value).subscribe(
        (result) => {
          this.responsedata = result;
          if (this.responsedata != null) {
            this.loginServ.SaveTokens(this.responsedata);

            this.loginServ.updatemenu.next();
            this.route.navigate([""]);
          } else {
            this.common.openSnackBar(
              "Login Failed, invalid username or password, please try again."
            );
          }
        },
        (err) => {
          console.log(err);
          this.common.openSnackBar(err.error.errorMessage, "Close");
        }
      );
    }
  }
}
