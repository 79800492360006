import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { CustomValidators } from "src/app/providers/CustomValidators";
import { ApiUserService } from "src/app/service/api.user.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.html",
  styleUrls: ["./change-password-dialog.scss"],
})
export class ChangePasswordDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private api: ApiUserService,
    private common: CommonService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }
  action: string;
  local_data: any;
  setupForm = new FormGroup(
    {
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
    },
    CustomValidators.mustMatch("password", "confirmPassword")
  );
  submitted = false;
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {}
  get f() {
    return this.setupForm.controls;
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.setupForm.invalid) {
      return;
    }

    let body = {
      id: this.local_data.id,
      newPassword: this.setupForm.value.password,
    };
    this.changePassword(body).then(
      async (res) => {
        await this.common.openSnackBar("Password Updated");
        this.dialogRef.close({ event: "Updated" });
      },
      async (err) => {
        await this.common.openSnackBar(err, "Close");
      }
    );
  }

  changePassword(body: any) {
    return new Promise((resolve, reject) => {
      this.api.changePassword(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
