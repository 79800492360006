<mat-tab-group
  mat-stretch-tabs
  style="overflow: hidden; height: 100%"
  [(selectedIndex)]="selectedTabIndex"
  (selectedTabChange)="onTabChanged($event)"
>
  <!-- dynamicHeight -->
  <mat-tab label="Live">
    <div class="main-container">
      <div class="left-container">
        <mat-accordion [multi]="true">
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            *ngFor="let schedule of schedules"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ schedule.date | date: "dd/MM EEEE" }}
              </mat-panel-title>
              <mat-panel-description>
                {{ schedule?.schedules?.length }} Schedule(s)
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              cdkDropList
              (cdkDropListDropped)="drop($event, schedule.schedules)"
            >
              <div
                class="drag-drop-list-item"
                *ngFor="let ind of schedule?.schedules; let i = index"
                cdkDrag
              >
                <div class="drag-drop-item" (click)="selectAreaChange(ind)">
                  <div class="drag-drop-item-col-1">
                    <h3>
                      {{ ind.title }}
                    </h3>
                  </div>

                  <div class="drag-drop-item">
                    <div style="width: 100px; display: inline-flex">
                      <img
                        style="border-radius: 50%; width: 56px; height: 56px"
                        matListAvatar
                        [src]="ind.driver.profilePictureUrl"
                        alt="..."
                      />
                    </div>
                    <div style="width: 80px; margin: auto">
                      <mat-chip-list matLine *ngIf="ind">
                        <mat-chip
                          [ngClass]="{
                            'mat-chip-complete': ind?.status === 4,
                            'mat-chip-complete-2': ind?.status === 7
                          }"
                          color="{{ getStatusColor(ind?.status) }}"
                          selected
                        >
                          {{ getStatus(ind?.status) }}
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="right-container">
        <div style="width: 100%; padding: 5px">
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ currentSelect?.title }}
                </mat-panel-title>
                <mat-panel-description style="text-align: right">
                  <mat-chip-list
                    *ngIf="currentSelect?.status || currentSelect?.status === 0"
                  >
                    <mat-chip
                      [ngClass]="{
                        'mat-chip-complete': currentSelect?.status === 4,
                        'mat-chip-complete-2': currentSelect?.status === 7
                      }"
                      color="{{ getStatusColor(currentSelect?.status) }}"
                      selected
                    >
                      {{ getStatus(currentSelect?.status) }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div style="width: 100%; display: inline-flex">
                <form
                  [formGroup]="scheduleForm"
                  #formDirective="ngForm"
                  style="width: 100%; display: inline-flex"
                >
                  <div style="width: 50%">
                    <mat-form-field appearance="fill">
                      <mat-label>Schedule Date</mat-label>
                      <input
                        formControlName="scheduleDate"
                        matInput
                        [matDatepicker]="datepicker"
                        [(ngModel)]="formData.scheduleDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="datepicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #datepicker>
                        <mat-datepicker-actions>
                          <button mat-button matDatepickerCancel>Cancel</button>
                          <button
                            mat-raised-button
                            color="primary"
                            matDatepickerApply
                          >
                            Apply
                          </button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      *ngIf="drivers.length > 0"
                    >
                      <mat-label>Driver</mat-label>
                      <mat-select
                        formControlName="driverUserId"
                        [(ngModel)]="formData.driverUserId"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of drivers"
                        >
                          {{ driver.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field
                      appearance="fill"
                      *ngIf="assistants.length > 0"
                    >
                      <mat-label>Assistant 1</mat-label>
                      <mat-select
                        formControlName="assistant1"
                        [(ngModel)]="formData.assistant1"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of assistants"
                        >
                          {{ driver.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div style="width: 50%">
                    <mat-form-field appearance="fill">
                      <mat-label>Schedule Title</mat-label>
                      <input
                        matInput
                        formControlName="title"
                        [(ngModel)]="formData.title"
                      />
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      *ngIf="lorries.length > 0"
                    >
                      <mat-label>Lorry</mat-label>
                      <mat-select
                        formControlName="lorryId"
                        [(ngModel)]="formData.lorryId"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of lorries"
                        >
                          {{ driver.title }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      *ngIf="assistants.length > 0"
                    >
                      <mat-label>Assistant 2</mat-label>
                      <mat-select
                        formControlName="assistant2"
                        [(ngModel)]="formData.assistant2"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of assistants"
                        >
                          {{ driver.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div style="width: 100%; padding: 5px">
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Location & Time </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>

              <div cdkDropList>
                <div
                  style="width: 100%; display: inline-flex; padding-right: 14px"
                  class="drag-drop-list-item"
                >
                  <div
                    style="width: 20%; text-align: left !important"
                    class="col-header"
                  >
                    <h3 style="font-weight: bold">Map</h3>
                  </div>
                  <div
                    style="width: 15%; text-align: left !important"
                    class="col-header"
                  >
                    <h3 style="font-weight: bold">Phase</h3>
                  </div>
                  <div style="width: 15%" class="col-header">
                    <h3 style="font-weight: bold">Status</h3>
                  </div>
                  <div style="width: 15%" class="col-header">
                    <h3 style="font-weight: bold">Scheduled</h3>
                  </div>
                  <div style="width: 15%" class="col-header">
                    <h3 style="font-weight: bold">Act. Start</h3>
                  </div>
                  <div style="width: 15%" class="col-header">
                    <h3 style="font-weight: bold">Act. End</h3>
                  </div>
                  <div style="width: 15%" class="col-header">
                    <h3 style="font-weight: bold">Duration</h3>
                  </div>
                  <div style="width: 5%; text-align: right" class="col-header">
                    <h3 style="font-weight: bold">Action</h3>
                  </div>
                </div>

                <div class="location-scrollable location-height">
                  <div
                    class="drag-drop-list-item"
                    *ngFor="let ind of formData.areas"
                    cdkDrag
                  >
                    <div class="drag-drop-item">
                      <div style="width: 100%; display: inline-flex">
                        <div
                          style="width: 20%; text-align: left"
                          class="col-detail"
                        >
                          {{ ind["areaTitle"] }}
                        </div>
                        <div
                          style="width: 15%; text-align: left"
                          class="col-detail"
                        >
                          {{ ind["areaPhase"] }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ getStatus(ind?.status) }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["estimatedStartTime"] }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["startDate"] | date: "yyyy/MM/dd hh:mm aa" }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["endDate"] | date: "yyyy/MM/dd hh:mm aa" }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{
                            getDateDiffInHours(ind["startDate"], ind["endDate"])
                          }}
                        </div>

                        <div
                          style="width: 5%; text-align: right"
                          class="col-detail"
                        >
                          <button
                            *ngIf="!showCancelButton()"
                            [disabled]="scheduleForm.disabled"
                            mat-mini-fab
                            color="warn"
                            (click)="removeLocation(ind)"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                          <button
                            *ngIf="showCancelButton()"
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu"
                          >
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button
                              mat-menu-item
                              (click)="cancelSchedule(ind, 'Reached')"
                            >
                              <mat-icon>airport_shuttle</mat-icon>
                              <span>Reached</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="cancelSchedule(ind, 'Complete')"
                            >
                              <mat-icon>done</mat-icon>
                              <span>Complete</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="cancelSchedule(ind, 'Cancel')"
                            >
                              <mat-icon>cancel</mat-icon>
                              <span>Cancel</span>
                            </button>
                          </mat-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                [disabled]="scheduleForm.disabled"
                style="width: 100%"
                mat-raised-button
                color="primary"
                (click)="openSelectLocation()"
              >
                Add Location
              </button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="button-container">
        <button
          class="fab-button"
          mat-fab
          color="primary"
          aria-label="Example icon button with a delete icon"
          (click)="btnAdd()"
          *ngIf="showButton.add"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          class="fab-button"
          mat-fab
          color="primary"
          aria-label="Example icon button with a delete icon"
          *ngIf="showButton.edit"
          (click)="btnEdit()"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          class="fab-button"
          mat-fab
          color="primary"
          aria-label="Example icon button with a delete icon"
          *ngIf="showButton.save"
          [disabled]="isAllowToSave()"
          (click)="btnSave()"
        >
          <mat-icon>save</mat-icon>
        </button>
        <button
          class="fab-button"
          mat-fab
          color="warn"
          aria-label="Example icon button with a delete icon"
          *ngIf="showButton.delete"
          (click)="btnDelete()"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          (click)="btnClose()"
          class="fab-button"
          mat-fab
          color="primary"
          aria-label="Example icon button with a delete icon"
          *ngIf="showButton.close"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          class="fab-button"
          mat-fab
          color="primary"
          aria-label="Example icon button with a delete icon"
          (click)="btnPublish()"
          *ngIf="showButton.publish"
        >
          <mat-icon>upload</mat-icon>
        </button>

        <button
          class="fab-button"
          mat-fab
          color="secondary"
          aria-label="Repeat"
          (click)="btnRepeat()"
          *ngIf="showButton.repeat"
        >
          <mat-icon>repeat</mat-icon>
        </button>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Past">
    <div class="main-container">
      <div class="left-container">
        <mat-accordion [multi]="true">
          <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            *ngFor="let schedule of schedules"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ schedule.date | date: "dd/MM EEEE" }}
              </mat-panel-title>
              <mat-panel-description>
                {{ schedule?.schedules?.length }} Schedule(s)
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              cdkDropList
              (cdkDropListDropped)="drop($event, schedule.schedules)"
            >
              <div
                class="drag-drop-list-item"
                *ngFor="let ind of schedule?.schedules; let i = index"
                cdkDrag
              >
                <div class="drag-drop-item" (click)="selectAreaChange(ind)">
                  <div class="drag-drop-item-col-1">
                    <h3>
                      {{ ind.title }}
                    </h3>
                  </div>

                  <div class="drag-drop-item">
                    <div style="width: 100px; display: inline-flex">
                      <img
                        matListAvatar
                        [src]="ind.driver.profilePictureUrl"
                        style="border-radius: 50%; width: 56px; height: 56px"
                        alt="..."
                      />
                    </div>
                    <div style="width: 80px; margin: auto">
                      <mat-chip-list matLine *ngIf="ind">
                        <mat-chip
                          [ngClass]="{
                            'mat-chip-complete': ind?.status === 4,
                            'mat-chip-complete-2': ind?.status === 7
                          }"
                          color="{{ getStatusColor(ind?.status) }}"
                          selected
                        >
                          {{ getStatus(ind?.status) }}
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="right-container">
        <div style="width: 100%; padding: 5px">
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ currentSelect?.title }}
                </mat-panel-title>
                <mat-panel-description style="text-align: right">
                  <mat-chip-list *ngIf="currentSelect.status">
                    <mat-chip
                      [ngClass]="{
                        'mat-chip-complete': currentSelect?.status === 4,
                        'mat-chip-complete-2': currentSelect?.status === 7
                      }"
                      color="{{ getStatusColor(currentSelect?.status) }}"
                      selected
                    >
                      {{ getStatus(currentSelect?.status) }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div style="width: 100%; display: inline-flex">
                <form
                  [formGroup]="scheduleForm"
                  #formDirective="ngForm"
                  style="width: 100%; display: inline-flex"
                >
                  <div style="width: 50%">
                    <mat-form-field appearance="fill">
                      <mat-label>Schedule Date</mat-label>
                      <input
                        formControlName="scheduleDate"
                        matInput
                        [matDatepicker]="datepicker2"
                        [(ngModel)]="formData.scheduleDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="datepicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #datepicker2>
                        <mat-datepicker-actions>
                          <button mat-button matDatepickerCancel>Cancel</button>
                          <button
                            mat-raised-button
                            color="primary"
                            matDatepickerApply
                          >
                            Apply
                          </button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      *ngIf="drivers.length > 0"
                    >
                      <mat-label>Driver</mat-label>
                      <mat-select
                        formControlName="driverUserId"
                        [(ngModel)]="formData.driverUserId"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of drivers"
                        >
                          {{ driver.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field
                      appearance="fill"
                      *ngIf="assistants.length > 0"
                    >
                      <mat-label>Assistant 1</mat-label>
                      <mat-select
                        formControlName="assistant1"
                        [(ngModel)]="formData.assistant1"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of assistants"
                        >
                          {{ driver.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div style="width: 50%">
                    <mat-form-field appearance="fill">
                      <mat-label>Schedule Title</mat-label>
                      <input
                        matInput
                        formControlName="title"
                        [(ngModel)]="formData.title"
                      />
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      *ngIf="lorries.length > 0"
                    >
                      <mat-label>Lorry</mat-label>
                      <mat-select
                        formControlName="lorryId"
                        [(ngModel)]="formData.lorryId"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of lorries"
                        >
                          {{ driver.title }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      *ngIf="assistants.length > 0"
                    >
                      <mat-label>Assistant 2</mat-label>
                      <mat-select
                        formControlName="assistant2"
                        [(ngModel)]="formData.assistant2"
                      >
                        <mat-option
                          [value]="driver.id"
                          *ngFor="let driver of assistants"
                        >
                          {{ driver.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div style="width: 100%; padding: 5px">
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Location & Time </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="location-scrollable location-height">
                <div cdkDropList>
                  <div
                    style="width: 100%; display: inline-flex"
                    class="drag-drop-list-item"
                  >
                    <div
                      style="width: 20%; text-align: left !important"
                      class="col-header"
                    >
                      <h3 style="font-weight: bold">Map</h3>
                    </div>
                    <div style="width: 15%" class="col-header">
                      <h3 style="font-weight: bold">Phase</h3>
                    </div>
                    <div style="width: 15%" class="col-header">
                      <h3 style="font-weight: bold">Status</h3>
                    </div>
                    <div style="width: 15%" class="col-header">
                      <h3 style="font-weight: bold">Scheduled</h3>
                    </div>
                    <div style="width: 15%" class="col-header">
                      <h3 style="font-weight: bold">Act. Start</h3>
                    </div>
                    <div style="width: 15%" class="col-header">
                      <h3 style="font-weight: bold">Act. End</h3>
                    </div>
                    <div style="width: 15%" class="col-header">
                      <h3 style="font-weight: bold">Duration</h3>
                    </div>
                  </div>

                  <div
                    class="drag-drop-list-item"
                    *ngFor="let ind of formData.areas"
                    cdkDrag
                  >
                    <div class="drag-drop-item">
                      <div style="width: 100%; display: inline-flex">
                        <div
                          style="width: 20%; text-align: left"
                          class="col-detail"
                        >
                          {{ ind["areaTitle"] }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["areaPhase"] }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ getStatus(ind?.status) }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["estimatedStartTime"] }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["startDate"] | date: "yyyy/MM/dd hh:mm" }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{ ind["endDate"] | date: "yyyy/MM/dd hh:mm" }}
                        </div>
                        <div style="width: 15%" class="col-detail">
                          {{
                            getDateDiffInHours(ind["startDate"], ind["endDate"])
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <mat-selection-list [multiple]="false">
                <mat-list-option
                  *ngFor="let ind of formData.areas"
                  [value]="ind"
                >
                  <div style="width: 100%; display: inline-flex">
                    <div style="width: 40%; margin: auto">
                      <h3>{{ ind["areaTitle"] }}</h3>
                    </div>
                    <div style="width: 40%; margin: auto">
                      {{ ind["estimatedStartTime"] }}
                    </div>
                    <div style="width: 20%; text-align: right; margin: auto">
                      <button
                        [disabled]="scheduleForm.disabled"
                        mat-mini-fab
                        color="warn"
                        (click)="removeLocation(ind)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-list-option>
              </mat-selection-list> -->
              <button
                [disabled]="scheduleForm.disabled"
                style="width: 100%"
                mat-raised-button
                color="primary"
                (click)="openSelectLocation()"
              >
                Add Location
              </button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
