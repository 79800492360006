import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { ApiConfigurationService } from "src/app/service/api.configuration.service";
import { ApiUserService } from "src/app/service/api.user.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "notification-dialog",
  templateUrl: "./notification-dialog.html",
  styleUrls: ["./notification-dialog.scss"],
})
export class NotificationDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NotificationDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private api: ApiConfigurationService,
    private common: CommonService
  ) {
    this.local_data = { ...data.data };
    this.action = data.action;
    this.list_data = data.lists;

    let fndIdx = _.findIndex(this.list_data, (ele) => {
      return JSON.stringify(ele) === JSON.stringify(this.local_data);
    });
    if (fndIdx !== -1) {
      this.currentIdx = fndIdx;
    }
  }
  currentIdx: number = -1;
  action: string;
  hide = true;
  local_data: any;
  list_data: any;
  // ""
  setupForm = new FormGroup({
    alertMessage: new FormControl("", [Validators.required]),
    alertColourCode: new FormControl("", [Validators.required]),

    active: new FormControl("", [Validators.required]),
  });
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    this.checkFormEditable();
  }

  async doAction() {
    try {
      if (this.action === "Delete") {
        this.list_data.splice(this.currentIdx, 1);
      } else if (this.currentIdx === -1) {
        this.list_data.push(this.local_data);
      } else {
        this.list_data[this.currentIdx] = _.cloneDeep(this.local_data);
      }

      let result: any = await this.add(this.list_data);
      if (result["success"]) {
        this.common.openSnackBar("Saved Successful", "Close");
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }
    } catch (error: any) {
      if (_.isString(error)) {
        let result = error.indexOf("alertIconAttachmentId");
        if (result !== -1) {
          this.common.openSnackBar("Icon is required", "Close");
        }
      } else {
        this.common.openSnackBar(error, "Close");
      }
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createAlerts(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== "Delete") {
      return true;
    }
    return false;
  }
  fileUploaded(val: any) {
    if (_.isUndefined(val.val)) {
      delete this.local_data[val.key];
    } else {
      this.local_data[val.key] = val.val.id;
    }
  }
  checkFormEditable() {
    // if (this.action === "Update") {
    //   this.setupForm.disable();
    //   this.setupForm.controls["active"].enable();
    // }
  }
}
