import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { LoginService } from "src/app/service/login.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() currentRoute!: any;
  @Output() currentRouteChange = new EventEmitter<any>();
  @Output() menuToggle = new EventEmitter<boolean>();

  constructor(private loginService: LoginService) {}
  showMenu = false;

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  toggleMenu() {
    this.menuToggle.emit(!this.showMenu);
  }

  logout() {
    this.loginService.Logout();
  }
}
