import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiTimetableService {
  apiurl = environment.api_url;
  endpoint = '/api/v1/admin/timetable';
  constructor(private http: HttpClient, private common: CommonService) {}

  getTimetables(body: any) {
    let url = this.apiurl + this.endpoint;
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&direction=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createTimetable(body: any) {
    let url = this.apiurl + this.endpoint;
    return this.http.post(url, body).pipe(catchError(this.common.handleError));
  }
  updateTimetable(body: any) {
    let url = this.apiurl + this.endpoint;

    return this.http
      .put(url + '/' + body.id, body)
      .pipe(catchError(this.common.handleError));
  }
  deleteTimetable(body: any) {
    let url = this.apiurl + this.endpoint;
    return this.http
      .delete(url + '/' + body.id)
      .pipe(catchError(this.common.handleError));
  }
}
