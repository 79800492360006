<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <mat-form-field>
      <input
        placeholder="{{action}} Question"
        matInput
        [(ngModel)]="local_data.question"
        formControlName="question"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        placeholder="{{action}} Answer"
        matInput
        [(ngModel)]="local_data.answer"
        formControlName="answer"
      />
    </mat-form-field>
  </form>

  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.question}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancel
  </button>
</div>
