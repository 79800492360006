<div style="width: 100%; display: inline-flex">
  <div
    class="container"
    *ngFor="let preview of previews; let i = index"
    style="padding: 10px"
  >
    <img
      [src]="preview"
      class="image"
      [ngClass]="{ 'border-radius': isProfile }"
      style="height: 120px; width: 120px; object-fit: cover"
    />
    <div class="middle" *ngIf="!disable">
      <div class="text" (click)="removePreview(i)">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          close
        </mat-icon>
      </div>
    </div>
  </div>
  <button
    [ngClass]="{ 'border-radius': isProfile }"
    style="height: 120px; width: 120px"
    mat-raised-button
    color="primary"
    (click)="!disable ? compressFile() : null"
    *ngIf="!isMultiple && previews.length === 0 && compress === true"
  >
    Select Photo
  </button>
  <button
    [ngClass]="{ 'border-radius': isProfile }"
    style="height: 120px; width: 120px"
    mat-raised-button
    color="primary"
    (click)="!disable ? fileInput.click() : null"
    *ngIf="!isMultiple && previews.length === 0 && compress !== true"
  >
    Select Photo
    <input
      #fileInput
      type="file"
      style="display: none"
      accept="image/*"
      multiple
      (change)="selectFiles($event)"
    />
  </button>
</div>
