<div style="width: 100%; margin: auto; display: inline-flex">
  <div style="width: 75%; display: inline-flex; margin: auto; padding: 5px">
    <mat-form-field
      style="width: 33%"
      appearance="fill"
      *ngIf="cities.length > 0"
    >
      <mat-label>City</mat-label>
      <mat-select
        (selectionChange)="cityOnChange($event)"
        [(ngModel)]="selectedCity"
      >
        <mat-option>None</mat-option>
        <mat-option *ngFor="let city of cities" [value]="city">
          {{ city }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      style="width: 33%"
      appearance="fill"
      *ngIf="phases.length > 0"
    >
      <mat-label>Phase</mat-label>
      <mat-select
        (selectionChange)="phaseOnChange($event)"
        [(ngModel)]="selectedPhase"
      >
        <mat-option>None</mat-option>
        <mat-option *ngFor="let phase of phases" [value]="phase">
          {{ phase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 33%" appearance="fill">
      <mat-label>Area</mat-label>
      <mat-select
        (selectionChange)="mapOnChange($event)"
        [(ngModel)]="selectedMap"
      >
        <mat-option *ngFor="let map of maps" [value]="map.value">
          {{ map.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="fab-button" style="width: 25%; margin: 0 auto; padding: 5px">
    <button
      *ngIf="showButton.add"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      (click)="btnAdd()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      *ngIf="showButton.edit"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      (click)="btnEdit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      *ngIf="showButton.save"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      (click)="btnSave()"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      *ngIf="showButton.delete"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      (click)="openDialog('Delete', formData)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button
      *ngIf="showButton.close"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      (click)="btnClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div style="width: 100%; height: 85%; display: inline-flex">
  <div style="width: 75%; height: 100%">
    <div
      *ngIf="loadMap"
      leaflet
      style="height: 100%"
      leafletDraw
      [leafletDrawOptions]="drawOptions"
      [leafletOptions]="options"
      (leafletMapReady)="onMapReady($event)"
      (leafletDrawCreated)="onDrawCreated($event)"
      (leafletDrawEdited)="onDrawEdited($event)"
      (leafletDrawDeleted)="onDrawDeleted($event)"
    >
      <div [leafletLayer]="drawnItems"></div>
    </div>
  </div>
  <div style="width: 25%; height: 100%">
    <mat-tab-group style="height: 100%" [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="Specification">
        <form [formGroup]="areaForm" #formDirective="ngForm">
          <mat-form-field appearance="fill">
            <mat-label>City</mat-label>
            <input
              matInput
              formControlName="city"
              [(ngModel)]="formData.city"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Phase</mat-label>
            <input
              matInput
              formControlName="phase"
              [(ngModel)]="formData.phase"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Area</mat-label>
            <input
              matInput
              formControlName="title"
              [(ngModel)]="formData.title"
            />
          </mat-form-field>
          <button
            [disabled]="isFormValid()"
            *ngIf="showButton.next"
            class="fab-btn"
            mat-fab
            color="primary"
            aria-label="Example icon button with a delete icon"
            (click)="btnNext()"
          >
            <mat-icon>forward</mat-icon>
          </button>
        </form>
      </mat-tab>
      <mat-tab label="Boundary">
        <mat-list role="list">
          <mat-list-item
            role="listitem"
            *ngFor="
              let point of formData.coveredAreaPolygonPoints;
              let i = index
            "
          >
            {{ i + 1 }}&nbsp; {{ point.latitude }}&nbsp;,&nbsp;
            {{ point.longitude }}
          </mat-list-item>
        </mat-list>
      </mat-tab>
      <mat-tab label="Route">
        <mat-list role="list">
          <mat-list-item
            role="listitem"
            *ngFor="let point of formData.areaGuidelineWayPoints; let i = index"
          >
            {{ i + 1 }}&nbsp;{{ point.latitude }}&nbsp;,&nbsp;
            {{ point.longitude }}
          </mat-list-item>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
