import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-bi",
  templateUrl: "./bi.component.html",
  styleUrls: ["./bi.component.scss"],
  providers: [],
})
export class BiComponent implements OnInit {
  constructor() {}

  async ngOnInit(): Promise<void> {}
}
