<div style="width: 100%; display: inline-flex">
  <form
    [formGroup]="shareForm"
    #formDirective="ngForm"
    style="width: 100%; display: inline-flex"
  >
    <div style="margin: 20px; min-width: 500px">
      <mat-form-field appearance="fill">
        <mat-label>Share Text</mat-label>
        <textarea
          matInput
          formControlName="shareText"
          [(ngModel)]="formData.shareText"
        ></textarea>
      </mat-form-field>
      <button
        [disabled]="shareForm.disabled"
        style="width: 100%"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </form>
</div>
