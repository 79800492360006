import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class ApiDashboardService {
  apiurl = environment.api_url;
  endpoint = "/api/v1/admin/dashboard";
  constructor(private http: HttpClient, private common: CommonService) {}

  getDashboard(body: any) {
    let url = this.apiurl + this.endpoint;
    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(catchError(this.common.handleError));
  }
}
