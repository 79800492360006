import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiScheduleService {
  apiurl = environment.api_url;
  endpoint = '/api/v1/admin/schedule';

  //          /api/v1/admin/user/driver/simple

  constructor(private http: HttpClient, private common: CommonService) {}

  getLiveSchedules(body: any) {
    let url = this.apiurl + this.endpoint + '/live';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 99;

    let para = 'page=' + pg + '&pageSize=' + pgSz;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }

  getPastSchedules(body: any) {
    let url = this.apiurl + this.endpoint + '/history';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 99;

    let para = 'page=' + pg + '&pageSize=' + pgSz;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }

  getSchedulesByDate(body: any) {
    let url = this.apiurl + this.endpoint;

    let scheduleDate = body.scheduleDate;

    let para = 'scheduleDate=' + scheduleDate;
    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }

  getMap(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body;

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  createSchedule(body: any) {
    return this.http
      .post(this.apiurl + this.endpoint, body)
      .pipe(catchError(this.common.handleError));
  }
  updateSchedule(body: any) {
    return this.http
      .put(this.apiurl + this.endpoint + '/' + body.id, body)
      .pipe(catchError(this.common.handleError));
  }
  deleteSchedule(body: any) {
    return this.http
      .delete(this.apiurl + this.endpoint + '/' + body.id)
      .pipe(catchError(this.common.handleError));
  }
  deleteArea(body: any) {
    return this.http
      .delete(this.apiurl + this.endpoint + '/' + body.id)
      .pipe(catchError(this.common.handleError));
  }

  publishSchedule(body: any) {
    return this.http

      .post(this.apiurl + this.endpoint + '/' + body.id + '/publish', {})
      .pipe(catchError(this.common.handleError));
  }

  cancelSchedule(body: any) {
    return this.http
      .post(
        this.apiurl +
          '/api/v1/admin/schedule/' +
          body.id +
          '/area/' +
          body.areaId +
          '/cancel',
        {}
      )
      .pipe(catchError(this.common.handleError));
  }
  completeSchedule(body: any) {
    return this.http
      .post(
        this.apiurl +
          '/api/v1/admin/schedule/' +
          body.id +
          '/area/' +
          body.areaId +
          '/complete',
        {}
      )
      .pipe(catchError(this.common.handleError));
  }
  reachedSchedule(body: any) {
    return this.http
      .post(
        this.apiurl +
          '/api/v1/admin/schedule/' +
          body.id +
          '/area/' +
          body.areaId +
          '/reach',
        {}
      )
      .pipe(catchError(this.common.handleError));
  }
}
