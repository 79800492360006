<!-- <div style="width: 100%; margin: auto; display: inline-flex">
  <div style="width: 100%; display: inline-flex; margin: auto; padding: 5px">
    <mat-form-field
      style="width: 33%"
      appearance="fill"
      *ngIf="cities.length > 0"
    >
      <mat-label>City</mat-label>
      <mat-select
        (selectionChange)="cityOnChange($event)"
        [(ngModel)]="selectedCity"
      >
        <mat-option *ngFor="let city of cities" [value]="city">
          {{ city }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 33%" appearance="fill">
      <mat-label>Area</mat-label>
      <mat-select
        (selectionChange)="areaOnChange($event)"
        [(ngModel)]="selectedArea"
      >
        <mat-option *ngFor="let area of areas" [value]="area.value">
          {{ area.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 33%" appearance="fill">
      <mat-label>Road</mat-label>
      <mat-select
        (selectionChange)="addressOnChange($event)"
        [(ngModel)]="selectedAddress"
      >
        <mat-option *ngFor="let address of addresses" [value]="address.value">
          {{ address.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div> -->
<!-- *ngIf="selectedAddress !== ''" -->
<div style="width: 100%; display: inline-flex">
  <div style="width: 80%">
    <button
      style="margin: 5px"
      mat-raised-button
      color="primary"
      *ngFor="let unit of units"
      (click)="editUnit(unit)"
    >
      {{ unit.unitNumber }}
    </button>
  </div>
  <div style="width: 20%">
    <mat-form-field appearance="fill" *ngIf="cities.length > 0">
      <mat-label>City</mat-label>
      <mat-select
        (selectionChange)="cityOnChange($event)"
        [(ngModel)]="selectedCity"
      >
        <mat-option *ngFor="let city of cities" [value]="city">
          {{ city }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Area</mat-label>
      <mat-select
        (selectionChange)="areaOnChange($event)"
        [(ngModel)]="selectedArea"
      >
        <mat-option *ngFor="let area of areas" [value]="area.value">
          {{ area.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div style="width: 100%; display: inline-flex; padding-bottom: 10px">
      <button
        style="width: 100%"
        matSuffix
        mat-raised-button
        color="primary"
        aria-label="Add Road"
        (click)="addNewRoadClick()"
      >
        <!-- <mat-icon>add</mat-icon> -->
        {{ !showAddNewRoad ? "Add New Road" : "Select Existing Road" }}
      </button>
    </div>

    <mat-form-field *ngIf="!showAddNewRoad" appearance="fill">
      <mat-label>Existing Road</mat-label>
      <mat-select
        (selectionChange)="addressOnChange(selectedAddress)"
        [(ngModel)]="selectedAddress"
      >
        <mat-option *ngFor="let address of addresses" [value]="address.value">
          {{ address.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <form [formGroup]="unitForm" #formDirective="ngForm">
      <mat-form-field *ngIf="showAddNewRoad" appearance="fill">
        <mat-label>New Road</mat-label>
        <input
          matInput
          formControlName="address"
          [(ngModel)]="formData.address"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Unit Number</mat-label>
        <input
          matInput
          formControlName="unitNumber"
          [(ngModel)]="formData.unitNumber"
        />
        <button
          *ngIf="formData.unitNumber"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="btnClose()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-slide-toggle
        style="margin: auto"
        [labelPosition]="'before'"
        [(ngModel)]="formData.yellowBox"
        formControlName="yellowBox"
      >
        Yellow Box
      </mat-slide-toggle>
    </form>

    <button
      [disabled]="isFormValid()"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      *ngIf="showButton.add"
      (click)="btnAddClick()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      [disabled]="isFormValid()"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      *ngIf="showButton.save"
      (click)="btnSaveClick()"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      [disabled]="isFormValid()"
      class="fab-btn"
      mat-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
      *ngIf="showButton.close"
      (click)="btnClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <button
      [disabled]="isFormValid()"
      class="fab-btn"
      mat-fab
      color="warn"
      aria-label="Example icon button with a delete icon"
      *ngIf="showButton.delete"
      (click)="btnDeleteClick()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
<!-- <div style="text-align: right; padding: 10px" *ngIf="isReady">
  <button
    mat-button
    (click)="openDialog('Add', {})"
    mat-flat-button
    color="primary"
  >
    Add New
  </button>
</div>
<mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="isReady">
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="unitNumber">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Unit Number
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.unitNumber }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      style="justify-content: flex-end"
    >
      Action
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      style="justify-content: flex-end"
    >
      <a
        style="margin: 5px"
        mat-raised-button
        color="primary"
        (click)="openDialog('Update', element)"
        >Edit</a
      >
      <a
        style="margin: 5px"
        mat-raised-button
        color="warn"
        (click)="openDialog('Delete', element)"
        >Delete</a
      >
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator
  *ngIf="isReady"
  [pageSizeOptions]="[10, 20, 50]"
  showFirstLastButtons
  aria-label="Select page of periodic elements"
>
</mat-paginator> -->
