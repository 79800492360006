import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "lodash";
import { ApiReportService } from "src/app/service/api.report.service";

import { CommonService } from "src/app/service/common.service";
import { CollectionDialog } from "./collection-dialog/collection-dialog";

@Component({
  selector: "report-collection",
  templateUrl: "./collection.component.html",
  styleUrls: ["./collection.component.scss"],
})
export class CollectionComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api: ApiReportService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    this.changeDetectorRef
  );
  page = 0;
  pageSize = 10;
  formData = {
    fromDate: new Date(),
    toDate: new Date(),
    search: "",
  };

  displayedColumns = [
    "id",
    "collectedDate",
    "collectionDate",
    "collectionTime",
    "collectionWeek",
    "scheduleTitle",
    "lorryTitle",
    "city",
    "phase",
    "areaTitle",

    "address",
    "unitNumber",
    "yellowBox",
    "fullName",
    "assistant1",
    "assistant2",
  ];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page || 0,
      pageSize: this.pageSize,
      sortByColumn: "createdDate",
      order: "desc",
    });
  }
  ngAfterViewInit() {}
  async initData(val: any) {
    let collection = await this.getCollection(val);
    this.dataSource = new MatTableDataSource(collection);
  }

  totalLength = 0;

  onDateChange(val: any) {
    this.page = 0;
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      sortByColumn: "createdDate",
      order: "desc",
    });
  }
  search() {
    this.page = 0;
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      sortByColumn: "createdDate",
      order: "desc",
    });
  }

  getCollection(body: any): any {
    return new Promise((resolve, reject) => {
      this.api.getCollection(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.totalLength = Number(res.headers.get("totalRecordCount")) || 0;
            resolve(res.body);
            console.log(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getStatus(val: any) {
    return this.common.getRequestStatus(val);
  }
  sortData(col: any) {
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      sortByColumn: col.active,
      order: col.direction,
    });
  }
  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(CollectionDialog, {
      width: "1024px",
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  async pageChanged(val: any) {
    this.page = val["pageIndex"] || 0;
    this.pageSize = val["pageSize"] || 10;

    let nextCollection = await this.getCollection({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      order: "desc",
    });

    this.dataSource = new MatTableDataSource(nextCollection);
  }
  getAssistant(ele: any, val: any) {
    if (ele.assistants.length > 0) {
      if (val === "1") {
        return ele.assistants[0].fullName;
      }
      if (val === "2") {
        if (!_.isUndefined(ele.assistants[1])) {
          return ele.assistants[1].fullName;
        }
      }
    }
    return "";
  }
  isFromToDateEmpty() {
    if (
      this.formData.fromDate.toDateString() !== "" &&
      this.formData.toDate.toDateString() !== ""
    ) {
      return false;
    }
    return true;
  }

  async downloadCSV() {
    let downloadResult = await this.getCollectioCsv({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
    });

    if (downloadResult.url !== "") {
      window.open(downloadResult.url);
    }
  }
  getCollectioCsv(body: any): any {
    return new Promise((resolve, reject) => {
      this.api.exportCollection(body).subscribe(
        (res) => {
          resolve(res.body);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
