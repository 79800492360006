import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { CommonService } from './common.service';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class ApiUserService {
  apiurl = environment.api_url;
  endpoint = '/api/v1/admin/user';

  constructor(private http: HttpClient, private common: CommonService) {}

  getDriverSimple(body: any) {
    let url = this.apiurl + this.endpoint + '/driver/simple';

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  getDrivers(body: any) {
    let url = this.apiurl + this.endpoint + '/driver';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&order=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createDriver(body: any) {
    let obj = _.cloneDeep(body);
    obj.userStatus = obj.userStatus === true ? 0 : 1;
    delete obj.action;
    let url = this.apiurl + this.endpoint + '/driver';

    return this.http.post(url, obj).pipe(catchError(this.common.handleError));
  }

  getAssistantSimple(body: any) {
    let url = this.apiurl + this.endpoint + '/assistant/simple';

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  getAssistants(body: any) {
    let url = this.apiurl + this.endpoint + '/assistant';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&order=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createAssistant(body: any) {
    let obj = _.cloneDeep(body);
    obj.userStatus = obj.userStatus === true ? 0 : 1;
    delete obj.action;
    let url = this.apiurl + this.endpoint + '/assistant';

    return this.http.post(url, obj).pipe(catchError(this.common.handleError));
  }

  getAppsUsers(body: any) {
    let url = this.apiurl + this.endpoint + '/normal';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&order=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }

  getAdmins(body: any) {
    let url = this.apiurl + this.endpoint + '/admin';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&order=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createAdmin(body: any) {
    let obj = _.cloneDeep(body);
    obj.userStatus = obj.userStatus === true ? 0 : 1;
    delete obj.action;
    let url = this.apiurl + this.endpoint + '/admin';

    return this.http.post(url, obj).pipe(catchError(this.common.handleError));
  }

  updateUserStatus(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body.id + '/status';
    let obj = { userStatus: body.userStatus };
    return this.http
      .put(url, obj, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  changePassword(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body.id + '/password';
    let obj = { newPassword: body.newPassword };
    return this.http
      .put(url, obj, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  getUserInfo(body: any) {
    let url = this.apiurl + '/api/v1/user/user/profile';

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
}
