import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Editor } from 'ngx-editor';
import { ApiMapService } from 'src/app/service/api.map.service';

@Component({
  selector: 'time-table-dialog',
  templateUrl: './time-table-dialog.html',
  styleUrls: ['./time-table-dialog.scss'],
})
export class TimeTableDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TimeTableDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private api: ApiMapService
  ) {
    this.local_data = { ...data };

    this.action = this.local_data.action;
  }

  editor: Editor = new Editor({ features: { resizeImage: true } });
  html = '';
  action: string;
  local_data: any;
  setupForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    areaId: new FormControl(null, [Validators.required]),
    description: new FormControl('', [Validators.required]),
    dayOfWeek: new FormControl('', [Validators.required]),
  });

  areas = <any>[];

  ngOnInit(): void {
    this.loadAreas();
    this.setupForm.controls['areaId'].setValue(this.local_data.areaId);
  }
  areaOnChange(val: any) {
    if (val.value !== undefined) {
      this.local_data.areaId = val.value;
    }
  }
  loadAreas() {
    this.areas = <any>[];
    this.getAreaByCity({}).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        for (let i = 0; i <= res.body.length - 1; i++) {
          res.body[i]['value'] = Number(res.body[i]['id']);
          res.body[i]['viewValue'] = res.body[i]['title'];
          res.body[i]['label'] = res.body[i]['title'];
          res.body[i]['id'] = res.body[i]['id'];
        }

        this.areas = res.body;
      }
    });
  }

  getAreaByCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAreaByCity(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== 'Delete') {
      return true;
    }
    return false;
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  dayOfWeeks = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];

  dayOfWeekOnChange(val: any) {}
  onChange(val: any) {
    this.local_data.description = val;
  }
}
