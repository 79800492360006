import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ApiApkService } from 'src/app/service/api.apk.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-apk',
  templateUrl: './apk.component.html',
  styleUrls: ['./apk.component.scss'],
})
export class ApkComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api: ApiApkService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];

  displayedColumns = ['createdDate', 'version', 'url'];

  dataSource = new MatTableDataSource<any>();

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.isLoading = true;
    this.api
      .getApk({ page: this.currentPage, pageSize: this.pageSize })
      .subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.dataSource.data = res.body;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = res.headers.get('totalrecordcount');
            });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initData();
  }

  async addRowData(row_obj: any) {
    // try {
    //   let result: any = await this.add(row_obj);
    //   if (result["success"]) {
    //     this.common.openSnackBar("Added Successful", "Close");
    //     this.initData();
    //   }
    // } catch (error) {
    //   this.common.openSnackBar(error, "Close");
    // }
  }
  async updateRowData(row_obj: any) {
    // try {
    //   // let result: any = await this.update(row_obj);
    //   // if (result["success"]) {
    //   // this.common.openSnackBar("Updated Successful", "Close");
    //   this.initData();
    //   // }
    // } catch (error) {
    //   this.common.openSnackBar(error, "Close");
    // }
  }
  async deleteRowData(row_obj: any) {
    // try {
    //   let result: any = await this.delete(row_obj);
    //   if (result["success"]) {
    //     this.common.openSnackBar("Delete Successful", "Close");
    //     this.initData();
    //   }
    // } catch (error) {
    //   this.common.openSnackBar(error, "Close");
    // }
  }

  add(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.api_user.createDriver(body).subscribe(
    //     (res) => {
    //       resolve(res);
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }
  update(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.api_lorry.updateLorry(body).subscribe(
    //     (res) => {
    //       resolve(res);
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }
  delete(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.api_lorry.deleteLorry(body).subscribe(
    //     (res) => {
    //       resolve(res);
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }

  updateUserStatus(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.api_user.updateUserStatus(body).subscribe(
    //     (res) => {
    //       resolve(res);
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }
}
