import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { ApiCollectionService } from "src/app/service/api.collection.service";
import { ApiMapService } from "src/app/service/api.map.service";
import { ApiScheduleService } from "src/app/service/api.schedule.service";
import { ApiUserService } from "src/app/service/api.user.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-backdate-collection-dialog",
  templateUrl: "./backdate-collection-dialog.html",
  styleUrls: ["./backdate-collection-dialog.scss"],
})
export class BackdateCollectionDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BackdateCollectionDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private api: ApiUserService,
    private common: CommonService,
    private apiMap: ApiMapService,
    private apiSchedule: ApiScheduleService,
    private apiCollection: ApiCollectionService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }
  action: string;
  hide = { selectAll: false };
  local_data: any;
  schedules = <any>[];
  areas = <any>[];
  addresses = <any>[];
  units = <any>[];
  newUnits = <any>[];
  newHouseUnit = {
    id: "",
    address: "",
    unitNumber: "",
    yellowBox: false,
  };

  setupForm = new FormGroup({
    collectionDate: new FormControl("", [Validators.required]),
    scheduleId: new FormControl("", [Validators.required]),
    areaId: new FormControl("", [Validators.required]),
    road: new FormControl("", [Validators.required]),
  });
  newHouseForm = new FormGroup({
    unit: new FormControl("", [Validators.required]),
    yellowBox: new FormControl("", []),
  });
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    this.checkFormEditable();
  }

  async doAction() {
    if (this.action === "Delete") {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    } else {
      try {
        let body = {
          collectedDate: this.local_data.collectionDate,
          areaId: this.local_data.areaId.areaId,
          scheduleId: this.local_data.scheduleId.id,
          latitude: 0,
          longitude: 0,
          areaHouseUnitIds: this.local_data.areaHouseUnitIds || [],
          newUnits: this.getNewUnits(),
        };
        body.collectedDate = this.common.getCurrentISODateString(
          body.collectedDate
        );

        let result: any = await this.add(body);
        if (result["success"]) {
          this.common.openSnackBar("Added Successful", "Close");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        }
      } catch (error: any) {
        this.common.openSnackBar(error, "Close");
      }
    }
  }

  getNewUnits() {
    let resp = [];
    let respObj = <any>{};
    let id;
    if (this.newUnits.length > 0 && this.local_data.newUnits.length > 0) {
      for (let i = 0; i <= this.local_data.newUnits.length - 1; i++) {
        id = this.local_data.newUnits[i];
        for (let j = 0; j <= this.newUnits.length - 1; j++) {
          respObj = {};
          if (this.newUnits[j].id === id) {
            respObj = _.cloneDeep(this.newUnits[i]);
            delete respObj.id;
            resp.push(respObj);
          }
        }
      }
    }
    return resp;
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }

  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== "Delete") {
      return true;
    } else if (this.action === "Delete") {
      return false;
    } else {
      let newCount = _.map(this.local_data.newUnits, "id");
      let unitCount = _.map(this.local_data.areaHouseUnitIds, "id");

      if (newCount.length <= 0 && unitCount.length <= 0) {
        return true;
      }
    }
    return false;
  }

  checkFormEditable() {
    if (this.action === "Update") {
      this.setupForm.disable();
      this.setupForm.controls["userStatus"].enable();
    }
  }

  //OnChange
  collectionDateChange(val: any) {
    if (_.isDate(val)) {
      let a = this.common.getCurrentDate(val);
      a = a.replace(/\//g, "-");

      this.getSchedulesByDate({ scheduleDate: a }).then((res: any) => {
        if (res.body && res.body.length > 0) {
          this.schedules = res.body;
        } else {
          this.common.openSnackBar(
            "No Schedule found for the selected date.",
            "Close"
          );
        }
      });
    }
  }
  scheduleOnChange(val: any) {
    if (val) {
      this.areas = [];
      this.areas = val.areas;
    }
  }
  areaOnChange(val: any) {
    if (val) {
      this.addresses = <any>[];
      this.getAreaAddresses(val.areaId).then((res: any) => {
        if (_.isArray(res.body) && res.body.length > 0) {
          let obj = { value: "", viewValue: "" };
          let arry = [];
          for (let i = 0; i <= res.body.length - 1; i++) {
            obj = { value: "", viewValue: "" };
            obj.value = _.snakeCase(res.body[i]);
            obj.viewValue = res.body[i];
            arry.push(obj);
          }
          this.addresses = arry;
        }
      });
    }
  }
  addressOnChange(val: any) {
    this.hide.selectAll = false;
    if (val) {
      let obj = {
        id: this.local_data.areaId.areaId,
        address: val["viewValue"],
      };

      this.getHouseUnits(obj).then((res: any) => {
        if (_.isArray(res.body) && res.body.length > 0) {
          this.units = [];
          this.units = res.body;
          this.hide.selectAll = true;
        }
      });
    }
  }

  clearAll() {
    this.schedules = <any>[];
    this.areas = <any>[];
    this.addresses = <any>[];
    this.units = <any>[];
    this.newUnits = <any>[];
    this.local_data = { ...this.data };

    this.setupForm.reset();
    this.hide.selectAll = false;
  }

  addNewHouse() {
    if (this.newHouseUnit.unitNumber !== "") {
      let listOfUnit = _.split(this.newHouseUnit.unitNumber, ",");
      let currentUnits = <any>[];
      if (listOfUnit.length > 0) {
        let newObj = <any>{};
        for (let i = 0; i <= listOfUnit.length - 1; i++) {
          newObj = {};
          newObj["unitNumber"] = listOfUnit[i];
          newObj["yellowBox"] = this.newHouseUnit.yellowBox;
          newObj["address"] = this.local_data.road.viewValue;
          newObj["id"] = _.snakeCase("new" + listOfUnit[i].trim());
          this.newUnits.push(newObj);
          currentUnits.push(newObj);
        }
      }

      if (!_.isArray(this.local_data.newUnits)) {
        this.local_data.newUnits = [];
      }
      let curIds = _.map(currentUnits, "id");
      this.local_data.newUnits = this.local_data.newUnits.concat(curIds);

      this.newHouseUnit = {
        id: "",
        address: "",
        unitNumber: "",
        yellowBox: false,
      };
    }
  }

  selectAllUnits() {
    let result = _.map(this.units, "id");
    this.local_data.areaHouseUnitIds = result;

    let result2 = _.map(this.newUnits, "id");
    this.local_data.newUnits = result2;
  }
  clearAllUnits() {
    this.local_data.areaHouseUnitIds = [];
    this.local_data.newUnits = [];
  }
  //API Call
  getSchedulesByDate(body: any) {
    return new Promise((resolve, reject) => {
      this.apiSchedule.getSchedulesByDate(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getCity(body: any) {
    return new Promise((resolve, reject) => {
      this.apiMap.getAllCity(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getHouseUnits(body: any) {
    return new Promise((resolve, reject) => {
      this.apiMap.getHouseUnits(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getAreaAddresses(body: any) {
    return new Promise((resolve, reject) => {
      this.apiMap.getAreaAddress(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  add(body: any) {
    return new Promise((resolve, reject) => {
      this.apiCollection.createCollection(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
