<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action !== 'Delete'; else elseTemplate"
  >
    <div style="width: 100%; display: flex; flex-wrap: wrap; padding: 5px">
      <!-- Schedule Date -->
      <div style="flex: 0 0 50%" *ngIf="action === 'Add' && !isFromURL ">
        <mat-form-field appearance="fill">
          <mat-label>Schedule Date</mat-label>
          <input
            formControlName="scheduleDate"
            matInput
            [matDatepicker]="datepicker2"
            [(ngModel)]="local_data.scheduleDate"
            (ngModelChange)="scheduleDateChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker2>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDatepickerApply>
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>
      <!-- Schedule -->
      <div style="flex: 0 0 50%" *ngIf="action === 'Add' && !isFromURL">
        <mat-form-field appearance="fill">
          <mat-label>Schedule</mat-label>
          <mat-select
            formControlName="scheduleId"
            [(ngModel)]="local_data.scheduleId"
          >
            <mat-option
              [value]="schedule['id']"
              *ngFor="let schedule of schedules"
            >
              {{ schedule['title'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="flex: 0 0 100%" *ngIf="action === 'Update' || isFromURL">
        <mat-form-field appearance="fill">
          <mat-label> Schedule </mat-label>
          <input
            matInput
            [(ngModel)]="local_data.scheduleTitle"
            formControlName="scheduleTitle"
          />
        </mat-form-field>
      </div>

      <!-- Repeat Type -->
      <div style="flex: 0 0 50%">
        <mat-form-field appearance="fill" *ngIf="repeatTypes.length > 0">
          <mat-label>Repeat Type</mat-label>
          <mat-select
            formControlName="scheduleRepeatType"
            [(ngModel)]="local_data.scheduleRepeatType"
          >
            <mat-option [value]="ele['id']" *ngFor="let ele of repeatTypes">
              {{ ele['title'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Repeat Status -->
      <div style="flex: 0 0 50%">
        <mat-form-field appearance="fill" *ngIf="repeatStatus.length > 0">
          <mat-label>Repeat Status</mat-label>
          <mat-select
            formControlName="scheduleRepeatStatus"
            [(ngModel)]="local_data.scheduleRepeatStatus"
          >
            <mat-option [value]="ele['id']" *ngFor="let ele of repeatStatus">
              {{ ele['title'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Status -->
      <div style="flex: 0 0 50%">
        <mat-slide-toggle
          style="margin: auto"
          [labelPosition]="'before'"
          [(ngModel)]="local_data.active"
          formControlName="active"
        >
          Status
        </mat-slide-toggle>
      </div>
    </div>
  </form>

  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.title}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>
