import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./Guard/auth.guard";
import { DefaultComponent } from "./layouts/default/default.component";
import { BackdateCollectionComponent } from "./modules/backdate-collection/backdate-collection.component";
import { BiComponent } from "./modules/bi/bi.component";
import { ConfigurationComponent } from "./modules/configuration/configuration.component";
import { DashboardComponent } from "./modules/dashboard/dashboard.component";
import { LoginComponent } from "./modules/login/login.component";
import { CollectionComponent } from "./modules/report/collection/collection.component";
import { RequestComponent } from "./modules/report/requests/requests.component";
import { ScheduleDetailComponent } from "./modules/schedule-detail/schedule-detail.component";
import { ScheduleSummaryComponent } from "./modules/schedule-summary/schedule-summary.component";
import { ScheduleComponent } from "./modules/schedule/schedule.component";
import { ApkComponent } from "./modules/setup/apk/apk.component";
import { AssistantComponent } from "./modules/setup/assistant/assistant.component";
import { DriverComponent } from "./modules/setup/driver/driver.component";
import { FaqComponent } from "./modules/setup/faq/faq.component";
import { HouseUnitComponent } from "./modules/setup/house-unit/house-unit.component";
import { LorryComponent } from "./modules/setup/lorry/lorry.component";
import { MapComponent } from "./modules/setup/map/map.component";
import { NewsComponent } from "./modules/setup/news/news.component";
import { NotificationComponent } from "./modules/setup/notification/notification.component";
import { RepeatComponent } from "./modules/setup/repeat/repeat.component";
import { ShareInfoComponent } from "./modules/setup/share-info/share-info.component";
import { TimeTableComponent } from "./modules/setup/time-table/time-table.component";
import { UserAdminComponent } from "./modules/setup/user-admin/user-admin.component";
import { UserNormalComponent } from "./modules/setup/user-normal/user-normal.component";
import { UserComponent } from "./modules/setup/user/user.component";

const routes: Routes = [
  {
    path: "",
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: DashboardComponent },
      { path: "schedule", component: ScheduleComponent },
      { path: "schedule-summary/:id", component: ScheduleSummaryComponent },
      { path: "schedule-detail/:id", component: ScheduleDetailComponent },
      { path: "map", component: MapComponent },
      { path: "assistant", component: AssistantComponent },
      { path: "driver", component: DriverComponent },
      { path: "lorry", component: LorryComponent },
      { path: "user", component: UserComponent },
      { path: "app-user", component: UserNormalComponent },
      { path: "admin-user", component: UserAdminComponent },
      { path: "configuration", component: ConfigurationComponent },
      { path: "faq", component: FaqComponent },
      { path: "news", component: NewsComponent },
      { path: "house", component: HouseUnitComponent },
      { path: "time-table", component: TimeTableComponent },
      { path: "share-info", component: ShareInfoComponent },
      { path: "notification", component: NotificationComponent },
      { path: "apk", component: ApkComponent },
      { path: "repeat", component: RepeatComponent },
      { path: "repeat/:params", component: RepeatComponent },
      { path: "backdate-collection", component: BackdateCollectionComponent },
      { path: "report-requests", component: RequestComponent },
      { path: "report-collection", component: CollectionComponent },

      { path: "bi", component: BiComponent },
    ],
  },
  { path: "login", component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
