<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <div style="width: 100%; display: inline-flex; padding: 5px">
      <div style="width: 50%">
        <app-upload-images
          [imgUrl]="local_data.profilePictureUrl"
          [fileKey]="'profilePictureAttachmentId'"
          (fileChange)="fileUploaded($event)"
          [isProfile]="true"
          [compress]="true"
        ></app-upload-images>
      </div>
      <div style="width: 50%; text-align: right">
        <mat-slide-toggle
          style="margin: auto"
          [labelPosition]="'before'"
          [(ngModel)]="local_data.userStatus"
          formControlName="userStatus"
        >
          Status
        </mat-slide-toggle>
      </div>
    </div>

    <!-- <mat-form-field appearance="fill">
      <mat-label> Username </mat-label>
      <input
        placeholder="{{action}} Username"
        matInput
        [(ngModel)]="local_data.userName"
        formControlName="userName"
      />
    </mat-form-field> -->
    <mat-form-field appearance="fill">
      <mat-label> Full Name </mat-label>
      <input
        placeholder="{{action}} Full Name"
        matInput
        [(ngModel)]="local_data.fullName"
        formControlName="fullName"
      />
    </mat-form-field>
    <!-- <mat-form-field appearance="fill"
      ><mat-label> Email </mat-label>
      <input
        type="email"
        [email]="true"
        placeholder="{{action}} Email"
        matInput
        [(ngModel)]="local_data.email"
        formControlName="email"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Phone Number </mat-label>
      <input
        placeholder="{{action}} Phone Number"
        matInput
        [(ngModel)]="local_data.phoneNumber"
        formControlName="phoneNumber"
      />
    </mat-form-field> -->

    <!-- <mat-form-field appearance="fill">
      <mat-label> Password </mat-label>
      <input
        placeholder="{{action}} Password"
        matInput
        [type]="hide ? 'password' : 'text'"
        [(ngModel)]="local_data.password"
        formControlName="password"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field> -->
  </form>

  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.title}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>
