import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ApiMapService } from "src/app/service/api.map.service";
import * as _ from "lodash";
import { LorryDialog } from "../lorry/lorry-dialog/lorry-dialog";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-house-unit",
  templateUrl: "./house-unit.component.html",
  styleUrls: ["./house-unit.component.scss"],
})
export class HouseUnitComponent implements OnInit {
  constructor(
    private api: ApiMapService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    this.changeDetectorRef
  );

  dataSource = new MatTableDataSource<any>();
  displayedColumns = ["id", "unitNumber", "action"];

  cities = <any>[];
  areas = <any>[];
  addresses = <any>[];
  units = <any>[];
  selectedCity = "";
  selectedArea = "";
  selectedAddress = "";

  unitForm = new FormGroup({
    address: new FormControl("", []),
    unitNumber: new FormControl("", [Validators.required]),
    yellowBox: new FormControl("", []),
  });
  formData = <any>{};
  showButton = {
    add: true,
    close: false,
    save: false,
    delete: false,
  };
  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.loadCities();
  }

  loadCities() {
    this.areas = <any>[];
    this.getCity({}).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        this.cities = res.body;
      }
    });
  }
  loadAreas() {
    this.areas = <any>[];
    this.getAreas({}).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        for (let i = 0; i <= res.body.length - 1; i++) {
          res.body[i]["value"] = res.body[i]["id"];
          res.body[i]["viewValue"] = res.body[i]["title"];
        }

        this.areas = res.body;
      }
    });
  }

  cityOnChange(val: any) {
    this.selectedArea = "";
    this.areas = <any>[];
    this.selectedAddress = "";
    this.addresses = <any>[];

    this.getAreaByCity(val.value).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        for (let i = 0; i <= res.body.length - 1; i++) {
          res.body[i]["value"] = res.body[i]["id"];
          res.body[i]["viewValue"] = res.body[i]["title"];
        }

        this.areas = res.body;
      }
    });
  }
  areaOnChange(val: any) {
    this.selectedAddress = "";
    this.addresses = <any>[];
    this.getAreaAddresses(val.value).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        let obj = { value: "", viewValue: "" };
        let arry = [];
        for (let i = 0; i <= res.body.length - 1; i++) {
          obj = { value: "", viewValue: "" };
          obj.value = _.snakeCase(res.body[i]);
          obj.viewValue = res.body[i];
          arry.push(obj);
        }
        this.addresses = arry;
      }
    });
  }
  isReady = false;

  addressOnChange(val: any) {
    this.resetFabButton();
    let fndIdx = _.findIndex(this.addresses, (ele: any) => {
      return ele.value === val;
    });
    if (fndIdx === -1) {
      return;
    }

    let obj = {
      id: this.selectedArea,
      address: this.addresses[fndIdx]["viewValue"],
    };

    this.getHouseUnits(obj).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        this.units = [];

        this.units = res.body;

        delete this.formData.unitNumber;
        this.isReady = true;
      }
    });
  }

  getCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAllCity(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getAreaByCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAreaByCity(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getAreas(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getMaps(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getAreaAddresses(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAreaAddress(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getHouseUnits(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getHouseUnits(body).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  isFormValid() {
    return !this.unitForm.valid;
  }

  editUnit(unit: any) {
    this.showButton.add = false;
    this.showButton.delete = true;
    this.showButton.save = true;
    this.showButton.close = true;
    this.formData = _.cloneDeep(unit);
  }

  resetFabButton() {
    this.showButton = {
      add: true,
      close: false,
      save: false,
      delete: false,
    };
  }
  btnClose() {
    this.unitForm.reset();
    this.resetFabButton();
    this.formData = <any>{};
  }
  btnAddClick() {
    let obj = {
      areaId: this.selectedArea,
      body: {
        address: "",
        unitNumber: this.formData.unitNumber,
        yellowBox: this.formData.yellowBox,
      },
    };

    if (!this.showAddNewRoad) {
      let fndIdx = _.findIndex(this.addresses, (ele: any) => {
        return ele.value === this.selectedAddress;
      });
      if (fndIdx === -1) {
        return;
      }
      obj.body.address = this.addresses[fndIdx]["viewValue"];
    } else {
      obj.body.address = this.formData.address;
      obj.body.yellowBox = this.formData.yellowBox;
    }

    this.saveHouseUnit(obj)
      .then((res) => {
        this.common.openSnackBar("House Unit Added Successful.", "Close");
        return this.getAreaAddresses(this.selectedArea);
      })
      .then(
        (res: any) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            let obj = { value: "", viewValue: "" };
            let arry = [];
            for (let i = 0; i <= res.body.length - 1; i++) {
              obj = { value: "", viewValue: "" };
              obj.value = _.snakeCase(res.body[i]);
              obj.viewValue = res.body[i];
              arry.push(obj);
            }
            this.addresses = arry;

            let fnd = _.find(this.addresses, (ele) => {
              return ele.value === _.snakeCase(this.formData.address);
            });
            if (fnd !== undefined) {
              this.selectedAddress = fnd.value;
            }

            this.addressOnChange(this.selectedAddress);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
        }
      );
  }
  btnDeleteClick() {
    let obj = {
      area: this.selectedArea,
      body: this.formData,
    };

    this.deleteHouseUnit(obj)
      .then((res) => {
        this.common.openSnackBar("House Unit Deleted Successful.", "Close");
        return this.getAreaAddresses(this.selectedArea);
      })
      .then(
        (res: any) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            let obj = { value: "", viewValue: "" };
            let arry = [];
            for (let i = 0; i <= res.body.length - 1; i++) {
              obj = { value: "", viewValue: "" };
              obj.value = _.snakeCase(res.body[i]);
              obj.viewValue = res.body[i];
              arry.push(obj);
            }
            this.addresses = arry;

            let fnd = _.find(this.addresses, (ele) => {
              return ele.value === _.snakeCase(this.formData.address);
            });
            if (fnd !== undefined) {
              this.selectedAddress = fnd.value;
            } else {
              this.selectedAddress = "";
              this.unitForm.reset();
              this.formData.unitNumber = "";
              this.formData.yellowBox = false;
              this.formData.address = "";
              this.showAddNewRoad = false;
              this.units = <any>[];
            }

            this.addressOnChange(this.selectedAddress);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
        }
      );
  }
  btnSaveClick() {
    let obj = {
      area: this.selectedArea,
      body: this.formData,
    };

    this.updateHouseUnit(obj)
      .then((res) => {
        this.common.openSnackBar("House Unit Updated Successful.", "Close");
        return this.getAreaAddresses(this.selectedArea);
      })
      .then(
        (res: any) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            let obj = { value: "", viewValue: "" };
            let arry = [];
            for (let i = 0; i <= res.body.length - 1; i++) {
              obj = { value: "", viewValue: "" };
              obj.value = _.snakeCase(res.body[i]);
              obj.viewValue = res.body[i];
              arry.push(obj);
            }
            this.addresses = arry;

            let fnd = _.find(this.addresses, (ele) => {
              return ele.value === _.snakeCase(this.formData.address);
            });
            if (fnd !== undefined) {
              this.selectedAddress = fnd.value;
            }

            this.addressOnChange(this.selectedAddress);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
        }
      );
  }

  showAddNewRoad = false;
  addNewRoadClick() {
    this.showAddNewRoad = !this.showAddNewRoad;
  }

  saveHouseUnit(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createHouseUnit(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteHouseUnit(body: any) {
    return new Promise((resolve, reject) => {
      this.api.deleteHouseUnit(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateHouseUnit(body: any) {
    return new Promise((resolve, reject) => {
      this.api.updateHouseUnit(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
