import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DefaultModule } from "./layouts/default/default.module";
import { SharedModule } from "./shared/components/shared.module";

import { HttpClientModule } from "@angular/common/http";
import { authInterceptorProviders } from "./_helpers/http.interceptor";

import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    SharedModule,
    HttpClientModule,
  ],
  providers: authInterceptorProviders,
  bootstrap: [AppComponent],
})
export class AppModule {}
