import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  apiurl = environment.api_url;

  constructor(
    private http: HttpClient,
    private router: Router,
    private common: CommonService
  ) {}

  tokenresp: any;
  private _updatemenu = new Subject<void>();
  get updatemenu() {
    return this._updatemenu;
  }

  Proceddlogin(usercred: any) {
    let body = {
      phoneNumber: usercred.email,
      password: usercred.password,
      device: this.common.getUniqDevice(),
    };
    return this.http.post(this.apiurl + "/api/v1/user/user/login", body);
  }

  GenerateRefreshToken() {
    return this.http.post(this.apiurl + "/api/v1/user/user/refresh", {});
  }

  IsLogged() {
    return localStorage.getItem("token") != null;
  }
  GetToken() {
    return localStorage.getItem("token") || "";
  }
  GetRefreshToken() {
    return localStorage.getItem("refreshtoken") || "";
  }

  SaveTokens(tokendata: any) {
    localStorage.setItem("token", tokendata.auth_token);
    localStorage.setItem("refreshtoken", tokendata.refresh_token);
  }

  Logout() {
    localStorage.clear();
    this.router.navigateByUrl("/login");
  }

  GetRolebyToken(token: any) {
    let _token = token.split(".")[1];
    this.tokenresp = JSON.parse(atob(_token));
    return this.tokenresp.role;
  }

  GetMenubyrole(role: any) {
    return this.http.get(this.apiurl + "GetMenubyRole/" + role);
  }
  HaveAccess(role: any, menu: any) {
    return this.http.get(
      this.apiurl + "HaveAccess?role=" + role + "&menu=" + menu
    );
  }
}
