<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form [formGroup]="setupForm">
    <mat-form-field>
      <input
        placeholder="Password"
        matInput
        [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
        formControlName="password"
      />
    </mat-form-field>
    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
      <div *ngIf="f['password'].errors['required']">Password is required</div>
      <div *ngIf="f['password'].errors['minlength']">
        Password must be at least 8 characters
      </div>
    </div>
    <mat-form-field>
      <input
        placeholder="Confirm Password"
        matInput
        [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }"
        formControlName="confirmPassword"
      />
    </mat-form-field>
    <div
      *ngIf="submitted && f['confirmPassword'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="f['confirmPassword'].errors['required']">
        Confirm Password is required
      </div>
      <div *ngIf="f['confirmPassword'].errors['mustMatch']">
        Password and confirm not match
      </div>
    </div>
    <!-- <button mat-button color="warning">Change Password</button> -->
  </form>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button mat-button color="primary" (click)="onSubmit()">
    Change Password
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>
