<mat-toolbar color="primary">
  <mat-toolbar-row>
    <img
      src="../../../../assets/images/logo_transparent.png"
      style="width: 50px; height: 50px"
    />
    <button mat-icon-button (click)="toggleMenu()">
      <mat-icon>menu</mat-icon>
    </button>

    <span *ngIf="currentRoute?.name !== ''">{{ currentRoute?.name }} </span>
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <!-- <li>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </li>
        <li>
          <button mat-icon-button>
            <mat-icon>help_outline</mat-icon>
          </button>
        </li> -->
        <li>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>person_outline</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              Sign out
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
