import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiLorryService {
  apiurl = environment.api_url;

  constructor(private http: HttpClient, private common: CommonService) {}

  getLorryLists(body: any) {
    let url = this.apiurl + '/api/v1/admin/lorry';
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&direction=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createLorry(body: any) {
    return this.http
      .post(this.apiurl + '/api/v1/admin/lorry', body)
      .pipe(catchError(this.common.handleError));
  }
  updateLorry(body: any) {
    return this.http
      .put(this.apiurl + '/api/v1/admin/lorry/' + body.id, body)
      .pipe(catchError(this.common.handleError));
  }
  deleteLorry(body: any) {
    return this.http
      .delete(this.apiurl + '/api/v1/admin/lorry/' + body.id)
      .pipe(catchError(this.common.handleError));
  }
}
