import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";

import * as _ from "lodash";
import { ApiConfigurationService } from "src/app/service/api.configuration.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "share-info",
  templateUrl: "./share-info.component.html",
  styleUrls: ["./share-info.component.scss"],
  providers: [FormGroupDirective],
})
export class ShareInfoComponent implements OnInit {
  formData = <any>{};
  shareForm = new FormGroup({
    shareText: new FormControl("", [Validators.required]),
  });
  constructor(
    private api: ApiConfigurationService,
    private common: CommonService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadShareText();
  }
  async loadShareText() {
    let result: any;

    result = await this.getShareText({});

    if (!_.isUndefined(result["body"])) {
      this.formData = result.body;
    }
  }
  getShareText(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getShareText(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }
  save() {
    this.saveShareText(this.formData).then(async (res) => {
      this.formData = {};
      this.common.openSnackBar("Save successful", "Close");
      await this.loadShareText();
    });
  }

  saveShareText(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createShareText(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }
}
