import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { ApiRepeatScheduleService } from "src/app/service/api.repeat-schedule.service";
import { ApiScheduleService } from "src/app/service/api.schedule.service";

import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-repeat-dialog",
  templateUrl: "./repeat-dialog.html",
  styleUrls: ["./repeat-dialog.scss"],
})
export class RepeatDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RepeatDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private apiSchedule: ApiScheduleService,
    private apiRepeat: ApiRepeatScheduleService,
    private common: CommonService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    if (
      !_.isUndefined(this.local_data["scheduleId"]) &&
      this.local_data["scheduleId"] !== ""
    ) {
      this.isFromURL = true;
    }
  }
  isFromURL = false;
  action: string;
  hide = true;
  local_data: any;
  schedules = [];
  repeatTypes = [
    {
      id: 0,
      title: "Daily",
    },
    {
      id: 1,
      title: "Weekly",
    },
  ];

  repeatStatus = [
    {
      id: 0,
      title: "Draft",
    },
    {
      id: 1,
      title: "Scheduled",
    },
  ];

  //
  setupForm = new FormGroup({
    active: new FormControl(true, []),
    scheduleDate: new FormControl("", []),
    scheduleId: new FormControl("", [Validators.required]),
    scheduleTitle: new FormControl("", []),
    scheduleRepeatType: new FormControl("", [Validators.required]),
    scheduleRepeatStatus: new FormControl("", [Validators.required]),
  });
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    if (this.isFromURL) {
      this.setupForm.controls["scheduleDate"].setValue(
        this.local_data["scheduleDate"]
      );
      this.setupForm.controls["scheduleId"].setValue(
        this.local_data["scheduleId"]
      );
      this.setupForm.controls["scheduleTitle"].setValue(
        this.local_data["scheduleTitle"]
      );
      this.setupForm.controls["scheduleTitle"].disable();
    }
    this.checkFormEditable();
  }

  async doAction() {
    if (this.action === "Delete") {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    } else if (this.action === "Add") {
      try {
        if (_.isUndefined(this.local_data.active)) {
          // this.local_data.active = true;
        }
        let result: any = await this.add(this.local_data);
        if (result["success"]) {
          this.common.openSnackBar("Added Successful", "Close");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        }
      } catch (error: any) {
        this.common.openSnackBar(error, "Close");
      }
    } else if (this.action === "Update") {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.apiRepeat.createSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== "Delete") {
      return true;
    }
    return false;
  }
  fileUploaded(val: any) {
    if (_.isUndefined(val.val)) {
      delete this.local_data[val.key];
    } else {
      this.local_data[val.key] = val.val.id;
    }
  }
  checkFormEditable() {
    if (this.action === "Update") {
      this.setupForm.disable();
      this.setupForm.controls["active"].enable();
      this.setupForm.controls["scheduleRepeatType"].enable();
      this.setupForm.controls["scheduleRepeatStatus"].enable();
    }
  }

  getScheduleByDate(body: any) {
    return new Promise((resolve, reject) => {
      this.apiSchedule.getSchedulesByDate(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  scheduleDateChange(val: any) {
    if (_.isDate(val)) {
      let a = this.common.getCurrentDate(val);
      a = a.replace(/\//g, "-");

      this.getScheduleByDate({ scheduleDate: a }).then((res: any) => {
        if (res.body && res.body.length > 0) {
          this.schedules = res.body;
        } else {
          this.common.openSnackBar(
            "No Schedule found for the selected date.",
            "Close"
          );
        }
      });
    }
  }
}
