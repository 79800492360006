<div style="text-align: right; padding: 10px">
  <button
    mat-button
    (click)="openDialog('Add', {})"
    mat-flat-button
    color="primary"
  >
    Add New
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 116px); overflow-y: scroll"
>
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fullName">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Full Name
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.fullName }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="userStatus">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Status
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <mat-slide-toggle
        [(ngModel)]="element.userStatus"
        (toggleChange)="slideToggleChange(element)"
      >
      </mat-slide-toggle>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="profilePictureUrl">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Profile Picture
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <img
        style="width: 50px; height: 50px; padding: 5px; border-radius: 50%"
        [src]="element.profilePictureUrl"
        alt="..."
      />
    </mat-cell>
  </ng-container>
  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginator
  showFirstLastButtons
  [length]="totalRows"
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
