import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ApiUserService } from 'src/app/service/api.user.service';
import { CommonService } from 'src/app/service/common.service';
import * as L from 'leaflet';
import { ApiReportService } from 'src/app/service/api.report.service';
const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
}

@Component({
  selector: 'app-requests-dialog',
  templateUrl: './requests-dialog.html',
  styleUrls: ['./requests-dialog.scss'],
})
export class RequestDialog
  implements
    OnInit,
    OnDestroy,
    AfterViewChecked,
    AfterContentChecked,
    AfterViewInit
{
  @ViewChild('chatListContainer', { static: false })
  list?: ElementRef<HTMLDivElement>;
  @ViewChild('box', { static: false }) public box?: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<RequestDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,

    private cdRef: ChangeDetectorRef,
    private api: ApiUserService,
    private common: CommonService,
    private apiR: ApiReportService
  ) {
    this.local_data = { ...data };
    console.log(this.local_data);
    this.images = _.map(this.local_data.requestAttachments, (ele) => {
      return { image: ele, thumbImage: ele };
    });
    this.local_data['userStatus'] =
      this.local_data['userStatus'] === 0 ? false : true;
    this.action = this.local_data.action;
  }

  images = <any>[];
  action: string;
  hide = true;
  local_data: any;
  userInfo: any;
  isAddMsg = false;
  oldMaxScroll = 0;
  addMsgCnt = 0;
  width: number = 500;
  height: number = 350;
  left: number = 10;
  top: number = 10;
  private boxPosition: { left: number; top: number } = { left: 10, top: 10 };
  private containerPos: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  } = { left: 0, top: 0, right: 0, bottom: 0 };
  public mouse: { x: number; y: number } = { x: 0, y: 0 };
  public status: Status = Status.OFF;
  private mouseClick: { x: number; y: number; left: number; top: number } = {
    x: 0,
    y: 0,
    left: 0,
    top: 0,
  };
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngAfterViewInit() {
    // this.loadBox();
    // this.loadContainer();
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.

    let maxScroll = Number(this.list?.nativeElement.scrollHeight);
    this.list?.nativeElement.scroll({ top: maxScroll, behavior: 'smooth' });
  }
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.checkFormEditable();
    this.initData();
  }

  async initData() {
    try {
      let result: any = await this.getUserInfo();
      if (result.body) {
        this.userInfo = result.body;
        this.scrollToBottom();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  getUserInfo() {
    return new Promise((resolve, reject) => {
      this.api.getUserInfo({}).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  chatInputMessage = '';

  send() {
    let body = { id: this.local_data.id, content: this.chatInputMessage };

    this.apiR.createComment(body).subscribe((res) => {
      console.log('create comment' + JSON.stringify(res));
      this.apiR.getComment(body).subscribe(async (res2) => {
        console.log('get comment' + JSON.stringify(res2));
        this.local_data.requestComments = res2;
        this.chatInputMessage = '';
        console.log('pre scroll');
        this.isAddMsg = true;
        await this.scrollToBottomP();
      });
    });
  }
  scrollToBottom() {
    let maxScroll = Number(this.list?.nativeElement.scrollHeight);
    this.list?.nativeElement.scroll({ top: maxScroll, behavior: 'smooth' });
  }

  scrollToBottomP() {
    return new Promise((resolve, reject) => {
      let maxScroll = Number(this.list?.nativeElement.scrollHeight);
      this.oldMaxScroll = maxScroll;
      console.log('scroll to bottom');
      console.log(maxScroll);
      this.list?.nativeElement.scroll({ top: maxScroll, behavior: 'smooth' });
      resolve('');
    });
  }

  async doAction() {
    try {
      let result: any = await this.add(this.local_data);
      if (result['success']) {
        this.common.openSnackBar('Added Successful', 'Close');
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }
    } catch (error: any) {
      this.common.openSnackBar(error, 'Close');
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createAssistant(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  delete(cId: any) {
    let body = { id: this.local_data.id, commentId: cId };
    this.apiR.deleteComment(body).subscribe((res) => {
      this.apiR.getComment(body).subscribe((res2) => {
        this.local_data.requestComments = res2;

        this.chatInputMessage = '';
        this.scrollToBottom();
      });
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  isAllowToSave() {
    // if (this.setupForm.invalid && this.action !== "Delete") {
    //   return true;
    // }
    return false;
  }
  fileUploaded(val: any) {
    if (_.isUndefined(val.val)) {
      delete this.local_data[val.key];
    } else {
      this.local_data[val.key] = val.val.id;
    }
  }
  checkFormEditable() {
    if (this.action === 'Update') {
      // this.setupForm.disable();
      // this.setupForm.controls["userStatus"].enable();
    }
  }

  map: any;

  options = {
    layers: [
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '...',
      }),
    ],
    zoom: 18,
    center: L.latLng(46.879966, -121.726909),
  };
  drawnItems: L.FeatureGroup = L.featureGroup();

  drawOptions: any = {
    position: 'topright',
    draw: false,
    edit: {
      featureGroup: this.drawnItems,
      edit: false,
      remove: false,
    },
  };
  loadMap = false;
  markersLayer = new L.LayerGroup();

  onMapReady(map: L.Map) {
    this.map = map;
    this.markersLayer.addTo(this.map);
    this.map.panTo(
      new L.LatLng(this.local_data.latitude, this.local_data.longitude)
    );

    const icon = L.icon({
      iconUrl: 'assets/images/Pin_yellow.png',
      iconSize: [40, 55],
      // iconAnchor: [13, 0],
      // popupAnchor: [13, 0],
    });

    let lat = this.local_data.latitude;
    let lng = this.local_data.longitude;

    const marker = L.marker([lat, lng], { icon })
      .on('mouseover', (e) => {})
      .on('mouseout', (e) => {})
      .on('click', (e) => {});
    this.markersLayer.addLayer(marker);
  }

  // private loadBox() {
  //   const { left, top } = this.box?.nativeElement.getBoundingClientRect();
  //   this.boxPosition = { left, top };
  // }

  // private loadContainer() {
  //   const left = this.boxPosition.left - this.left;
  //   const top = this.boxPosition.top - this.top;
  //   const right = left + 500;
  //   const bottom = top + 1000;
  //   this.containerPos = { left, top, right, bottom };
  // }

  // setStatus(event: MouseEvent, status: number) {
  //   console.log('setStatus');
  //   if (status === 1) event.stopPropagation();
  //   else if (status === 2)
  //     this.mouseClick = {
  //       x: event.clientX,
  //       y: event.clientY,
  //       left: this.left,
  //       top: this.top,
  //     };
  //   else this.loadBox();
  //   this.status = status;
  // }

  // @HostListener('window:mousemove', ['$event'])
  // onMouseMove(event: MouseEvent) {
  //   console.log(event);
  //   this.mouse = { x: event.clientX, y: event.clientY };

  //   if (this.status === Status.RESIZE) this.resize();
  // }

  // private resize() {
  //   console.log('resize');
  //   if (this.resizeCondMeet()) {
  //     this.height = Number(this.mouse.y > this.boxPosition.top)
  //       ? this.mouse.y - this.boxPosition.top
  //       : 0;
  //   }
  // }

  // private resizeCondMeet() {
  //   return this.mouse.y < this.containerPos.bottom;
  // }
}
