import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "lodash";
import { ApiCollectionService } from "src/app/service/api.collection.service";
import { ApiReportService } from "src/app/service/api.report.service";
import { ApiScheduleService } from "src/app/service/api.schedule.service";

import { CommonService } from "src/app/service/common.service";
import { BackdateCollectionDialog } from "./backdate-collection-dialog/backdate-collection-dialog";

@Component({
  selector: "backdate-collection",
  templateUrl: "./backdate-collection.component.html",
  styleUrls: ["./backdate-collection.component.scss"],
})
export class BackdateCollectionComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api: ApiReportService,
    private apiCollection: ApiCollectionService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    this.changeDetectorRef
  );
  page = 0;
  pageSize = 10;
  formData = {
    fromDate: new Date(),
    toDate: new Date(),
    search: "",
  };

  displayedColumns = [
    "id",
    "collectedDate",
    "collectionDate",
    "collectionTime",
    "collectionWeek",
    "scheduleTitle",
    "lorryTitle",
    "city",
    "phase",
    "areaTitle",
    "address",
    "unitNumber",
    "yellowBox",
    "fullName",
    "assistant1",
    "assistant2",
    "action",
  ];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page || 0,
      pageSize: this.pageSize,
      sortByColumn: "createdDate",
      order: "desc",
    });
  }
  ngAfterViewInit() {}

  async initData(val: any) {
    let collection = await this.getCollections(val);
    this.dataSource = new MatTableDataSource(collection);
  }

  totalLength = 0;

  onDateChange(val: any) {
    this.page = 0;
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      sortByColumn: "createdDate",
      order: "desc",
    });
  }
  search() {
    this.page = 0;
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      sortByColumn: "createdDate",
      order: "desc",
    });
  }

  getCollections(body: any): any {
    return new Promise((resolve, reject) => {
      this.apiCollection.getCollection(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.totalLength = Number(res.headers.get("totalRecordCount")) || 0;
            resolve(res.body);
            console.log(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getStatus(val: any) {
    return this.common.getRequestStatus(val);
  }
  sortData(col: any) {
    this.initData({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      sortByColumn: col.active,
      order: col.direction,
    });
  }
  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(BackdateCollectionDialog, {
      width: "1024px",
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == "Delete") {
        this.deleteRowData(result.data);
      }
    });
  }

  async deleteRowData(row_obj: any) {
    try {
      let result: any = await this.delete(row_obj);
      if (result["success"]) {
        this.common.openSnackBar("Delete Successful", "Close");
        this.initData({
          fromDate: this.common.getFullDateTimeString(
            this.formData.fromDate,
            "from"
          ),
          toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
          search: this.formData.search,
          page: this.page || 0,
          pageSize: this.pageSize,
          sortByColumn: "createdDate",
          order: "desc",
        });
      }
    } catch (error) {
      this.common.openSnackBar(error, "Close");
    }
  }
  delete(body: any) {
    return new Promise((resolve, reject) => {
      this.apiCollection.deleteCollection(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async pageChanged(val: any) {
    this.page = val["pageIndex"] || 0;
    this.pageSize = val["pageSize"] || 10;

    let nextCollection = await this.getCollections({
      fromDate: this.common.getFullDateTimeString(
        this.formData.fromDate,
        "from"
      ),
      toDate: this.common.getFullDateTimeString(this.formData.toDate, "to"),
      search: this.formData.search,
      page: this.page,
      pageSize: this.pageSize,
      order: "desc",
    });

    this.dataSource = new MatTableDataSource(nextCollection);
  }
  getAssistant(ele: any, val: any) {
    if (ele.assistants.length > 0) {
      if (val === "1") {
        return ele.assistants[0].fullName;
      }
      if (val === "2") {
        if (!_.isUndefined(ele.assistants[1])) {
          return ele.assistants[1].fullName;
        }
      }
    }
    return "";
  }
  isFromToDateEmpty() {
    if (
      this.formData.fromDate.toDateString() !== "" &&
      this.formData.toDate.toDateString() !== ""
    ) {
      return false;
    }
    return true;
  }

  // Api Call
}
