<h1 mat-dialog-title style="margin-bottom: 5px">
  #{{local_data.id}}&nbsp;-&nbsp;{{local_data.requestedByFullName}}
</h1>
<div mat-dialog-content>
  <div style="padding: 5px; display: inline-flex; width: 100%; height: 100%">
    <div style="padding: 5px; width: 58%">
      <div style="display: inline-flex; width: 100%">
        <div style="padding: 5px; width: 50%; padding: 5px">
          <mat-form-field appearance="fill">
            <mat-label> Area </mat-label>
            <input
              [disabled]="true"
              placeholder="{{action}} Area"
              matInput
              [(ngModel)]="local_data.areaTitle"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> Address </mat-label>
            <input
              [disabled]="true"
              placeholder="{{action}} Address"
              matInput
              [(ngModel)]="local_data.address"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> Unit No. </mat-label>
            <input
              [disabled]="true"
              placeholder="{{action}} Unit No."
              matInput
              [(ngModel)]="local_data.unitNumber"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> Est. Weight (kg)</mat-label>
            <input
              [disabled]="true"
              placeholder="{{action}} Est. Weight"
              matInput
              [(ngModel)]="local_data.estimateWeightKg"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> Remark</mat-label>
            <input
              [disabled]="true"
              placeholder="{{action}} Remark"
              matInput
              [(ngModel)]="local_data.remark"
            />
          </mat-form-field>
        </div>
        <div style="padding: 5px; width: 50%; padding: 5px">
          <div
            leaflet
            style="height: 100%"
            leafletDraw
            [leafletDrawOptions]="drawOptions"
            [leafletOptions]="options"
            (leafletMapReady)="onMapReady($event)"
          >
            <div [leafletLayer]="drawnItems"></div>
          </div>
        </div>
      </div>

      <div style="width: 100%; padding-top: 5px">
        <ng-image-slider
          [imageSize]="{width: 100, height: 100, space: 1}"
          style="width: 100%; height: 100px"
          [images]="images"
          #nav
        ></ng-image-slider>
      </div>
    </div>
    <div style="padding: 5px; width: 42%; padding: 5px" *ngIf="userInfo">
      <div class="container">
        <div class="card">
          <div class="chat-list" #chatListContainer>
            <ng-container *ngFor="let item of local_data.requestComments">
              <div
                class="chat-item"
                [ngClass]="{'right' : item?.createdById == userInfo.id, 'left': item?.createdById != userInfo.id}"
              >
                <img
                  id="avatar"
                  [src]="item.createdByProfilePictureUrl"
                  style="background-color: lightgray"
                />
                <div id="message-body" style="display: block">
                  <p style="margin: 0px">{{item?.content}}</p>
                  <p style="font-size: 10px; margin: 0px">
                    {{item.createdDate|date:'dd-MMM-yy hh:mm aa'}}

                    <button
                      *ngIf="item?.createdById == userInfo.id"
                      style="margin: auto; width: 30px; height: 30px"
                      mat-icon-button
                      color="warn"
                      matTooltip="Basic"
                      aria-label="Example icon-button with a heart icon"
                      (click)="delete(item.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="chat-footer">
            <input
              matInput
              id="chat-textarea"
              [(ngModel)]="chatInputMessage"
              required
            />

            <button
              mat-fab
              color="primary"
              aria-label="Example icon button with a home icon"
              id="send-button"
              (click)="send()"
              [disabled]="chatInputMessage === ''"
            >
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div style="display: inline-flex; width: 100%">
    <div style="width: 25%; padding: 5px; display: inline-flex">
      <div style="width: 100%; display: inline-flex">
        <div style="width: 100%">
          <div style="width: 100%; height: fit-content">
            <mat-form-field appearance="fill">
              <mat-label> Area </mat-label>
              <input
                [disabled]="true"
                placeholder="{{action}} Area"
                matInput
                [(ngModel)]="local_data.areaTitle"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label> Address </mat-label>
              <input
                [disabled]="true"
                placeholder="{{action}} Address"
                matInput
                [(ngModel)]="local_data.address"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label> Unit No. </mat-label>
              <input
                [disabled]="true"
                placeholder="{{action}} Unit No."
                matInput
                [(ngModel)]="local_data.unitNumber"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label> Est. Weight (kg)</mat-label>
              <input
                [disabled]="true"
                placeholder="{{action}} Est. Weight"
                matInput
                [(ngModel)]="local_data.estimateWeightKg"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label> Remark</mat-label>
              <input
                [disabled]="true"
                placeholder="{{action}} Remark"
                matInput
                [(ngModel)]="local_data.remark"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 33%">
      <div
        leaflet
        style="height: 90%"
        leafletDraw
        [leafletDrawOptions]="drawOptions"
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
      >
        <div [leafletLayer]="drawnItems"></div>
      </div>
    </div>

    <div style="width: 42%; padding: 5px" *ngIf="userInfo">
      <div class="container">
        <div class="card">
          <div class="chat-list" #chatListContainer>
            <ng-container *ngFor="let item of local_data.requestComments">
              <div
                class="chat-item"
                [ngClass]="{'right' : item?.createdById == userInfo.id, 'left': item?.createdById != userInfo.id}"
              >
                <img
                  id="avatar"
                  [src]="item.createdByProfilePictureUrl"
                  style="background-color: lightgray"
                />
                <div id="message-body" style="display: block">
                  <p style="margin: 0px">{{item?.content}}</p>
                  <p style="font-size: 10px; margin: 0px">
                    {{item.createdDate|date:'dd-MMM-yy hh:mm aa'}}

                    <button
                      *ngIf="item?.createdById == userInfo.id"
                      style="margin: auto; width: 30px; height: 30px"
                      mat-icon-button
                      color="warn"
                      matTooltip="Basic"
                      aria-label="Example icon-button with a heart icon"
                      (click)="delete(item.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="chat-footer">
            <input
              matInput
              id="chat-textarea"
              [(ngModel)]="chatInputMessage"
              required
            />

            <button
              mat-fab
              color="primary"
              aria-label="Example icon button with a home icon"
              id="send-button"
              (click)="send()"
              [disabled]="chatInputMessage === ''"
            >
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="display: inline-flex; width: 100%">
    <div style="width: 58%">
      <ng-image-slider
        style="width: 100%; height: 300px"
        [images]="images"
        #nav
      ></ng-image-slider>
    </div>
  </div> -->
</div>

<div mat-dialog-actions style="justify-content: right">
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>
