import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ApiNewsService } from 'src/app/service/api.news.service';

import { CommonService } from 'src/app/service/common.service';
import { NewsDialog } from './news-dialog/news-dialog';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api_news: ApiNewsService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];
  displayedColumns = ['id', 'status', 'title', 'isEvent', 'action'];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  initData() {
    this.isLoading = true;
    this.api_news
      .getNewsLists({ page: this.currentPage, pageSize: this.pageSize })
      .subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.dataSource.data = res.body;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = res.headers.get('totalrecordcount');
            });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initData();
  }
  openDialog(action: any, obj: any) {
    if (action === 'Add') {
      obj = {
        content: '',
        title: '',
        videoUrl: null,
        logoAttachmentId: null,
        attachmentType: 0,
        isEvent: false,
        eventAttachmentId: null,
      };
    }
    obj.action = action;

    const dialogRef = this.dialog.open(NewsDialog, {
      width: '800px',
      minHeight: '500px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (_.isUndefined(result)) {
        return;
      }
      if (result.event == 'Add') {
        delete result.data.action;
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        delete result.data.action;

        if (result.data.logoAttachmentId === 0) {
          result.data.logoAttachmentId = null;
        }
        if (result.data.eventAttachmentId === 0) {
          result.data.eventAttachmentId = null;
        }

        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }
  async addRowData(row_obj: any) {
    try {
      let result: any = await this.add(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Added Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async updateRowData(row_obj: any) {
    try {
      row_obj.status = Number(row_obj.status) || 0;
      let result: any = await this.update(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Updated Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async deleteRowData(row_obj: any) {
    try {
      let result: any = await this.delete(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Delete Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api_news.createNews(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  update(body: any) {
    return new Promise((resolve, reject) => {
      this.api_news.updateNews(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  delete(body: any) {
    return new Promise((resolve, reject) => {
      this.api_news.deleteNews(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getStatus(val: any) {
    let result = 'Draft';
    if (val === 1) {
      result = 'Published';
    }
    if (val === 2) {
      result = 'Inactive';
    }
    return result;
  }
}
