<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content style="width: 100%; overflow-x: hidden">
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <div style="display: inline-flex; width: 100%">
      <div style="width: 25%">
        <mat-form-field appearance="fill">
          <mat-label>Collection Date</mat-label>
          <input
            formControlName="collectionDate"
            matInput
            [matDatepicker]="datepicker"
            [(ngModel)]="local_data.collectionDate"
            (ngModelChange)="collectionDateChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDatepickerApply>
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div style="width: 25%">
        <mat-form-field appearance="fill" *ngIf="schedules.length > 0">
          <mat-label>Schedule</mat-label>
          <mat-select
            formControlName="scheduleId"
            [(ngModel)]="local_data.scheduleId"
            (ngModelChange)="scheduleOnChange($event)"
          >
            <mat-option [value]="schedule" *ngFor="let schedule of schedules">
              {{ schedule.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 25%">
        <mat-form-field appearance="fill" *ngIf="areas.length > 0">
          <mat-label>Map</mat-label>
          <mat-select
            formControlName="areaId"
            [(ngModel)]="local_data.areaId"
            (ngModelChange)="areaOnChange($event)"
          >
            <mat-option [value]="area" *ngFor="let area of areas">
              {{ area?.areaTitle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 25%; text-align: right">
        <button mat-raised-button color="primary" (click)="clearAll()">
          Clear All
        </button>
      </div>
    </div>

    <div style="width: 50%">
      <mat-form-field appearance="fill" *ngIf="addresses.length > 0">
        <mat-label>Road</mat-label>
        <mat-select
          formControlName="road"
          [(ngModel)]="local_data.road"
          (ngModelChange)="addressOnChange($event)"
        >
          <mat-option [value]="road" *ngFor="let road of addresses">
            {{ road?.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <div *ngIf="units.length>0" style="width: 100%">
    <div style="width: 100%">
      <mat-button-toggle-group
        class="col-xs-12 col-lg-12"
        style="flex-wrap: wrap; border: none"
        name="units"
        aria-label="units"
        multiple
        [(ngModel)]="local_data.areaHouseUnitIds"
      >
        <mat-button-toggle
          style="min-width: 100px; padding: 5px; border: none"
          class="col-xs-4 col-lg-2"
          [value]="unit.id"
          *ngFor="let unit of units"
        >
          {{unit.unitNumber}}
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-button-toggle-group
        class="col-xs-12 col-lg-12"
        style="flex-wrap: wrap; border: none"
        name="newunits"
        aria-label="new units"
        multiple
        [(ngModel)]="local_data.newUnits"
      >
        <mat-button-toggle
          style="min-width: 100px; padding: 5px; border: none"
          class="col-xs-4 col-lg-2"
          [value]="unit.id"
          *ngFor="let unit of newUnits"
        >
          {{unit.unitNumber}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div style="width: 100%; padding-top: 10px" *ngIf="hide.selectAll">
      <button mat-raised-button color="primary" (click)="selectAllUnits()">
        Select All
      </button>
      &nbsp;&nbsp;
      <button mat-raised-button color="warn" (click)="clearAllUnits()">
        Clear All
      </button>
    </div>

    <div style="width: 50%; padding-top: 10px">
      <form [formGroup]="newHouseForm" #formDirective="ngForm">
        <mat-form-field appearance="fill">
          <mat-label>New House Unit</mat-label>
          <input
            formControlName="unit"
            matInput
            type="text"
            [(ngModel)]="newHouseUnit.unitNumber"
          />
        </mat-form-field>
        <mat-slide-toggle
          style="margin: auto"
          [labelPosition]="'before'"
          [(ngModel)]="newHouseUnit.yellowBox"
          formControlName="yellowBox"
        >
          Yellow Box
        </mat-slide-toggle>
      </form>

      <button
        [disabled]="newHouseUnit.unitNumber===''"
        mat-raised-button
        color="primary"
        (click)="addNewHouse()"
      >
        Add
      </button>
    </div>
  </div>
  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.id}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>
