import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiAttachmentService } from "src/app/service/api.attachment.service";
import { NgxImageCompressService } from "ngx-image-compress";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-upload-images",
  templateUrl: "./upload-images.component.html",
  styleUrls: ["./upload-images.component.scss"],
})
export class UploadImagesComponent implements OnInit {
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;

  constructor(
    private apiAttachment: ApiAttachmentService,
    private imageCompress: NgxImageCompressService,
    private common: CommonService
  ) {}
  @Output() fileChange = new EventEmitter<any>();
  @Input() isMultiple = false;
  @Input() isProfile = false;
  @Input() disable = false;
  @Input() fileKey = "";
  @Input() imgUrl = "";
  @Input() compress = false;
  ngOnInit(): void {
    if (
      this.imgUrl !== "" &&
      this.imgUrl !== undefined &&
      this.imgUrl !== null
    ) {
      this.previews.push(this.imgUrl);
    }
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];

    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;

      for (let i = 0; i < numberOfFiles; i++) {
        if (Number(this.selectedFiles[i].size) / 1024 / 1024 > 5) {
          this.common.openSnackBar(
            "Photo uploaded exceed 5MB, please upload smaller file size photo.",
            "Close"
          );
          return;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }
  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    const uploadData = new FormData();
    uploadData.append("file", file, file.name);
    if (file) {
      this.apiAttachment.uploadAttachment(uploadData).subscribe({
        next: (event: any) => {
          // if (event.type === HttpEventType.UploadProgress) {
          //   this.progressInfos[idx].value = Math.round(
          //     (100 * event.loaded) / event.total
          //   );
          // } else if (event instanceof HttpResponse) {
          const msg = "Uploaded file successfully: " + file.name;
          this.message.push(msg);
          this.fileChange.emit({ val: event, key: this.fileKey });
          // this.imageInfos = this.uploadService.getFiles();
          // }
        },
        error: (err: any) => {
          console.log(err);
          this.progressInfos[idx].value = 0;
          const msg = "Could not upload the file: " + file.name;
          this.message.push(msg);
        },
      });
    }
  }

  upload2(file: File): void {
    const uploadData = new FormData();
    uploadData.append("file", file, file.name);
    if (file) {
      this.apiAttachment.uploadAttachment(uploadData).subscribe({
        next: (event: any) => {
          const msg = "Uploaded file successfully: " + file.name;
          this.message.push(msg);
          this.fileChange.emit({ val: event, key: this.fileKey });
        },
        error: (err: any) => {
          console.log(err);
          const msg = "Could not upload the file: " + file.name;
          this.message.push(msg);
        },
      });
    }
  }

  compressFile() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      console.log(
        "Size in bytes of the uploaded image was:",
        this.formatBytes(this.imageCompress.byteCount(image))
      );

      this.imageCompress
        .compressFile(image, orientation, 50, 50) // 50% ratio, 50% quality
        .then((compressedImage) => {
          console.log(
            "Size in bytes of the compressed image was:",
            this.formatBytes(this.imageCompress.byteCount(compressedImage))
          );

          this.previews.push(compressedImage);
          let file = this.dataURLtoFile(compressedImage, "picture.png");
          this.upload2(file);
        });
    });
  }

  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  removePreview(idx: any) {
    if (!this.disable) {
      this.previews.splice(idx, 1);
      this.fileChange.emit({ val: undefined, key: this.fileKey });
    }
  }
}
