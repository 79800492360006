import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiMapService {
  apiurl = environment.api_url;
  endpoint = '/api/v1/admin/area';

  constructor(private http: HttpClient, private common: CommonService) {}

  getMaps(body: any) {
    let url = this.apiurl + this.endpoint;
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;
    let sortByColumn = body.sortBy || '';
    let sortDirection = body.sortDirection || 0;
    let search = body.search || '';

    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&order=' +
      sortDirection +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  getMap(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body;

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  createArea(body: any) {
    return this.http
      .post(this.apiurl + this.endpoint, body)
      .pipe(catchError(this.common.handleError));
  }
  updateArea(body: any) {
    return this.http
      .put(this.apiurl + this.endpoint + '/' + body.id, body)
      .pipe(catchError(this.common.handleError));
  }
  deleteArea(body: any) {
    return this.http
      .delete(this.apiurl + this.endpoint + '/' + body.id)
      .pipe(catchError(this.common.handleError));
  }
  getAllCity(body: any) {
    let url = this.apiurl + this.endpoint + '/city';

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  getAllPhases(body: any) {
    let url = this.apiurl + this.endpoint + '/phase';

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  getAreaByCity(body: any) {
    let url = this.apiurl + this.endpoint + '/simple';
    let city = body.city || '';
    let phase = body.phase || '';

    let para = 'city=' + city + '&phase=' + phase;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }

  getAreaAddress(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body + '/unit/address';

    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  getHouseUnits(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body.id + '/unit';
    let para = 'address=' + encodeURI(body.address);

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createHouseUnit(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body.areaId + '/unit';

    return this.http
      .post(url, body.body)
      .pipe(catchError(this.common.handleError));
  }
  updateHouseUnit(body: any) {
    let url =
      this.apiurl + this.endpoint + '/' + body.area + '/unit/' + body.body.id;

    return this.http
      .put(url, body.body)
      .pipe(catchError(this.common.handleError));
  }

  deleteHouseUnit(body: any) {
    let url =
      this.apiurl + this.endpoint + '/' + body.area + '/unit/' + body.body.id;

    return this.http
      .delete(url, body)
      .pipe(catchError(this.common.handleError));
  }
}
