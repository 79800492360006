<div style="text-align: right; padding: 10px">
  <button
    mat-button
    (click)="openDialog('Add', {})"
    mat-flat-button
    color="primary"
  >
    Add New
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 116px); overflow-y: scroll"
>
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Status
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ getStatus(element.status) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="title">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.title }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="content">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Content
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      style="
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
      "
    >
      {{ element.content }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="logoAttachmentUrl">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Article Image
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <img
        *ngIf="element.logoAttachmentUrl !== null"
        [src]="element.logoAttachmentUrl"
        style="width: 80px; height: 80px; padding: 5px"
      />
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="videoUrl">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Video Url
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element" style="padding: 0px 5px">
      <a
        style="
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          -webkit-box-orient: vertical;
        "
        href="{{ element.videoUrl }}"
        target="_blank"
      >
        {{ element.videoUrl }}
      </a>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="isEvent">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isEvent" [disabled]="true">
      </mat-slide-toggle>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="eventAttachmentUrl">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Event Attachment
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <img
        *ngIf="element.eventAttachmentUrl !== null"
        [src]="element.eventAttachmentUrl"
        style="width: 80px; height: 80px; padding: 5px"
      />
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      style="justify-content: flex-end"
    >
      Action
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      style="justify-content: flex-end"
    >
      <a
        style="margin: 5px"
        mat-raised-button
        color="primary"
        (click)="openDialog('Update', element)"
      >
        Edit
      </a>
      <a
        style="margin: 5px"
        mat-raised-button
        color="warn"
        (click)="openDialog('Delete', element)"
        >Delete</a
      >
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
  </mat-header-row>

  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginator
  showFirstLastButtons
  [length]="totalRows"
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
