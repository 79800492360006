import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultComponent } from "./default.component";
import { DashboardComponent } from "src/app/modules/dashboard/dashboard.component";
import { RouterModule } from "@angular/router";

import { SharedModule } from "src/app/shared/components/shared.module";

import { ScheduleComponent } from "src/app/modules/schedule/schedule.component";
import { ConfigurationComponent } from "src/app/modules/configuration/configuration.component";

import { MapComponent } from "src/app/modules/setup/map/map.component";
import { AssistantComponent } from "src/app/modules/setup/assistant/assistant.component";
import { DriverComponent } from "src/app/modules/setup/driver/driver.component";
import { LorryComponent } from "src/app/modules/setup/lorry/lorry.component";
import { UserComponent } from "src/app/modules/setup/user/user.component";
import { FaqComponent } from "src/app/modules/setup/faq/faq.component";
import { NewsComponent } from "src/app/modules/setup/news/news.component";
import { HouseUnitComponent } from "src/app/modules/setup/house-unit/house-unit.component";

import { UserNormalComponent } from "src/app/modules/setup/user-normal/user-normal.component";
import { UserAdminComponent } from "src/app/modules/setup/user-admin/user-admin.component";
import { RequestComponent } from "src/app/modules/report/requests/requests.component";
import { CollectionComponent } from "src/app/modules/report/collection/collection.component";
import { ShareInfoComponent } from "src/app/modules/setup/share-info/share-info.component";
import { NotificationComponent } from "src/app/modules/setup/notification/notification.component";
import { TimeTableComponent } from "src/app/modules/setup/time-table/time-table.component";
import { ApkComponent } from "src/app/modules/setup/apk/apk.component";
import { RepeatComponent } from "src/app/modules/setup/repeat/repeat.component";

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    ScheduleComponent,
    ConfigurationComponent,
    MapComponent,
    AssistantComponent,
    DriverComponent,
    LorryComponent,
    UserComponent,
    UserNormalComponent,
    UserAdminComponent,
    FaqComponent,
    NewsComponent,
    HouseUnitComponent,
    RequestComponent,
    CollectionComponent,
    ShareInfoComponent,
    NotificationComponent,
    TimeTableComponent,
    ApkComponent,
    RepeatComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
})
export class DefaultModule {}
