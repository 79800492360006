import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { ApiUserService } from "src/app/service/api.user.service";
import { CommonService } from "src/app/service/common.service";
import * as L from "leaflet";

@Component({
  selector: "app-collection-dialog",
  templateUrl: "./collection-dialog.html",
  styleUrls: ["./collection-dialog.scss"],
})
export class CollectionDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CollectionDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private api: ApiUserService,
    private common: CommonService
  ) {
    this.local_data = { ...data };

    this.local_data["userStatus"] =
      this.local_data["userStatus"] === 0 ? false : true;
    this.action = this.local_data.action;
  }
  action: string;
  hide = true;
  local_data: any;
  // email: new FormControl("", [Validators.required]),
  //   phoneNumber: new FormControl("", [Validators.required]),
  // password: new FormControl("", [Validators.required]),
  // setupForm = new FormGroup({
  //   userName: new FormControl("", [Validators.required]),
  //   fullName: new FormControl("", [Validators.required]),
  //   userStatus: new FormControl("", [Validators.required]),
  // });
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    this.checkFormEditable();
  }

  async doAction() {
    try {
      let result: any = await this.add(this.local_data);
      if (result["success"]) {
        this.common.openSnackBar("Added Successful", "Close");
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }
    } catch (error: any) {
      this.common.openSnackBar(error, "Close");
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createAssistant(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
  isAllowToSave() {
    // if (this.setupForm.invalid && this.action !== "Delete") {
    //   return true;
    // }
    return false;
  }
  fileUploaded(val: any) {
    if (_.isUndefined(val.val)) {
      delete this.local_data[val.key];
    } else {
      this.local_data[val.key] = val.val.id;
    }
  }
  checkFormEditable() {
    if (this.action === "Update") {
      // this.setupForm.disable();
      // this.setupForm.controls["userStatus"].enable();
    }
  }
  map: any;

  options = {
    layers: [
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 18,
    center: L.latLng(46.879966, -121.726909),
  };
  drawnItems: L.FeatureGroup = L.featureGroup();

  drawOptions: any = {
    position: "topright",
    draw: false,
    edit: {
      featureGroup: this.drawnItems,
      edit: false,
      remove: false,
    },
  };
  loadMap = false;
  markersLayer = new L.LayerGroup();

  onMapReady(map: L.Map) {
    this.map = map;
    this.markersLayer.addTo(this.map);
    this.map.panTo(
      new L.LatLng(this.local_data.latitude, this.local_data.longitude)
    );

    const icon = L.icon({
      iconUrl: "assets/images/Pin_yellow.png",
      iconSize: [40, 55],
      // iconAnchor: [13, 0],
      // popupAnchor: [13, 0],
    });

    let lat = this.local_data.latitude;
    let lng = this.local_data.longitude;

    const marker = L.marker([lat, lng], { icon })
      .on("mouseover", (e) => {})
      .on("mouseout", (e) => {})
      .on("click", (e) => {});
    this.markersLayer.addLayer(marker);
  }
}
