import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, throwError } from "rxjs";
import { CommonService } from "./common.service";
import * as _ from "lodash";
@Injectable({
  providedIn: "root",
})
export class ApiApkService {
  apiurl = environment.api_url;
  endpoint = "/api/v1/admin/apps/driver";

  constructor(private http: HttpClient, private common: CommonService) {}

  getApk(body: any) {
    let url = this.apiurl + this.endpoint;

    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(catchError(this.common.handleError));
  }
}
