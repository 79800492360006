<mat-card style="overflow: auto" *ngIf="scheduleDetail">
  <mat-card-header style="margin: auto">
    <mat-card-title>{{ scheduleDetail.title }}</mat-card-title>
    <mat-card-subtitle style="font-size: large; margin-bottom: 0px">
      {{ getStatus(scheduleDetail.status) }}
    </mat-card-subtitle>
    <button mat-fab color="primary" aria-label="Detail" (click)="goToDetail()">
      <mat-icon>more</mat-icon>
    </button>
  </mat-card-header>
</mat-card>
<div
  leaflet
  style="height: 100%; width: 100%"
  leafletDraw
  [leafletDrawOptions]="drawOptions"
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
>
  <!-- (leafletDrawCreated)="onDrawCreated($event)"
  (leafletDrawEdited)="onDrawEdited($event)"
  (leafletDrawDeleted)="onDrawDeleted($event)" -->
  <div [leafletLayer]="drawnItems"></div>
</div>
