<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 60px); overflow-y: scroll"
>
  <ng-container matColumnDef="createdDate">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Uploaded Date
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.createdDate | date: 'dd/MM/yyyy hh:mm' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="version">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Version
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.version }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="url">
    <mat-header-cell mat-header-cell *matHeaderCellDef> APK </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <a href="{{ element.url }}">Download</a>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
  </mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginator
  showFirstLastButtons
  [length]="totalRows"
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
