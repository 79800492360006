<div
  style="
    width: 100%;
    height: 100%;
    display: inline-flex;
    background-color: #f7f7f7;
  "
>
  <div
    style="
      width: 65%;
      height: 50%;
      margin: auto;
      display: inline-flex;
      background-color: white;
    "
  >
    <div style="width: 50%; height: 100%; display: inline-flex">
      <div style="margin: auto; width: 70%">
        <h2 style="color: #8a8a8a">Welcome to Recycle Collection Portal</h2>
        <form
          style="width: 100% !important"
          (ngSubmit)="Proceedlogin()"
          [formGroup]="loginForm"
        >
          <mat-form-field appearance="fill">
            <mat-label>Username</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              placeholder="Username"
            />
            <mat-error
              *ngIf="
                loginForm.controls['email'].hasError('email') &&
                !loginForm.controls['email'].hasError('required')
              "
            >
              Please enter a valid email address
            </mat-error>
            <mat-error
              *ngIf="!loginForm.controls['email'].hasError('required')"
            >
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input
              type="password"
              matInput
              formControlName="password"
              placeholder="Ex. Must have at least 8 characters"
            />
            <mat-error
              *ngIf="loginForm.controls['password'].hasError('required')"
            >
              Please enter a valid password
            </mat-error>
            <mat-error
              *ngIf="loginForm.controls['password'].hasError('minlength')"
            >
              Please enter minimum of 8 characters
            </mat-error>
          </mat-form-field>
          <div style="text-align: center">
            <button
              style="width: 100%"
              mat-raised-button
              color="primary"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
    <div style="width: 50%; height: 100%; display: inline-flex">
      <img
        style="margin: auto; height: 40%"
        src="../../../assets/images/3rfamili.svg"
      />
    </div>
  </div>
</div>

<!-- <span style="font-size: 3rem">{{ dt | date: "dd" }}</span>
<span style="margin-top: 1rem; font-size: 1.5rem; color: rgba(0, 0, 0, 0.5)">
  /{{ dt | date: "MM" }}
</span>

<span style="font-size: 3rem">{{ dt | date: "h:mm" }}</span>
<span style="margin-top: 1rem; font-size: 1.5rem; color: rgba(0, 0, 0, 0.5)">
  {{ dt | date: "a" }}
</span> -->

<!-- <mat-grid-list cols="4" rowHeight="10vh">
  <mat-grid-tile [colspan]="4" [rowspan]="2.5">
    <img src="../../../assets/images/kitaran_bersama_logo.png" width />
  </mat-grid-tile>
  <mat-grid-tile [colspan]="4" [rowspan]="4">
    <mat-card style="min-width: 400px; max-width: 400px; margin: auto">
      <form (ngSubmit)="Proceedlogin()" [formGroup]="loginForm">
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder="Ex. pat@example.com"
          />
          <mat-error
            *ngIf="
              loginForm.controls['email'].hasError('email') &&
              !loginForm.controls['email'].hasError('required')
            "
          >
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="!loginForm.controls['email'].hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input
            type="password"
            matInput
            formControlName="password"
            placeholder="Ex. Must have at least 8 characters"
          />
          <mat-error
            *ngIf="loginForm.controls['password'].hasError('required')"
          >
            Please enter a valid password
          </mat-error>
          <mat-error
            *ngIf="loginForm.controls['password'].hasError('minlength')"
          >
            Please enter minimum of 8 characters
          </mat-error>
        </mat-form-field>
        <div style="text-align: center">
          <button mat-raised-button color="primary" type="submit">Login</button>
        </div>
      </form>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2">
    <span style="font-size: 3rem">{{ dt | date: "dd" }}</span>
    <span
      style="margin-top: 1rem; font-size: 1.5rem; color: rgba(0, 0, 0, 0.5)"
    >
      /{{ dt | date: "MM" }}
    </span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2">
    <span style="font-size: 3rem">{{ dt | date: "h:mm" }}</span>
    <span
      style="margin-top: 1rem; font-size: 1.5rem; color: rgba(0, 0, 0, 0.5)"
    >
      {{ dt | date: "a" }}
    </span>
  </mat-grid-tile>
</mat-grid-list> -->
