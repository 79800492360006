import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiScheduleService } from "src/app/service/api.schedule.service";
import { CommonService } from "src/app/service/common.service";
import * as L from "leaflet";
import * as _ from "lodash";
@Component({
  selector: "app-schedule-summary",
  templateUrl: "./schedule-summary.component.html",
  styleUrls: ["./schedule-summary.component.scss"],
})
export class ScheduleSummaryComponent implements OnInit {
  id: any;
  scheduleDetail: any;
  constructor(
    private route: ActivatedRoute,
    private api_schedule: ApiScheduleService,
    private common: CommonService,
    private router: Router
  ) {
    this.route.params.subscribe((res: any) => (this.id = res.id));
  }

  async ngOnInit(): Promise<void> {
    try {
      this.scheduleDetail = await this.getScheduleById();
      this.loadMap = true;
    } catch (error) {
      this.common.openSnackBar(error, "Close");
    }
  }
  getScheduleById() {
    return new Promise((resolve, reject) => {
      this.api_schedule.getMap(this.id).subscribe(
        (res) => {
          if (res.status === 200) {
            resolve(res.body);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getStatus(val: any) {
    return this.common.getStatus(val);
  }

  map: any;
  drawnItems: L.FeatureGroup = L.featureGroup();
  loadMap = false;
  drawOptions: any = {
    position: "topright",
    draw: false,
    edit: {
      featureGroup: this.drawnItems,
      edit: false,
      remove: false,
    },
  };
  options = {
    layers: [
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 18,
    center: L.latLng(46.879966, -121.726909),
  };
  onMapReady(map: L.Map) {
    this.map = map;

    // this.map.panTo(
    //   new L.LatLng(this.scheduleDetail.latitude, this.scheduleDetail.longitude)
    // );
    this.map.panTo(new L.LatLng(46.879966, -121.726909));
    this.renderPolygonLayer();
  }
  renderPolygonLayer() {
    let polyLayers = [];

    let navRoutes = <any>[];
    if (
      _.isArray(this.scheduleDetail.areaGuidelineWayPoints) &&
      this.scheduleDetail.areaGuidelineWayPoints.length > 0
    ) {
      navRoutes = _.map(this.scheduleDetail.areaGuidelineWayPoints, (ele) => {
        return [ele.latitude, ele.longitude];
      });
    }
    var polyline = L.polyline(navRoutes, {
      color: "#e60000",
    });

    polyLayers.push(polyline);

    // Add the layers to the drawnItems feature group
    for (let layer of polyLayers) {
      this.drawnItems.addLayer(layer);
    }
  }
  goToDetail() {
    this.router.navigateByUrl("/schedule-detail/" + this.id);
  }
}
