import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiMapService } from "src/app/service/api.map.service";
import * as _ from "lodash";
@Component({
  selector: "app-select-location",
  templateUrl: "./select-location.component.html",
  styleUrls: ["./select-location.component.scss"],
})
export class SelectLocationComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialogRef: MatDialogRef<SelectLocationComponent>,
    private api_map: ApiMapService
  ) {}
  selected = {
    areaId: "",
    areaTitle: "",
    areaPhase: "",
    estimatedStartTime: "",
  };
  selectedCity = "";
  selectedPhase = "";
  myTimePicker: any;
  cities = <any>[];
  phases = <any>[];
  maps = <any>[];
  ngOnInit(): void {
    this.initData();
  }
  async initData() {
    this.cities = await this.getCity({});
    this.phases = await this.getPhases({});
    this.maps = await this.getAreas({ pageSize: 100 });
  }

  cityOnChange(val: any) {
    this.maps = <any>[];

    let obj = {
      city: this.selectedCity || "",
      phase: this.selectedPhase || "",
    };

    this.getAreaByCity(obj).then((res: any) => {
      if (!_.isUndefined(res)) {
        this.maps = this.sortMap(res);
      }
    });
  }

  phaseOnChange(val: any) {
    this.maps = <any>[];

    let obj = {
      city: this.selectedCity || "",
      phase: this.selectedPhase || "",
    };

    this.getAreaByCity(obj).then((res: any) => {
      if (!_.isUndefined(res)) {
        this.maps = this.sortMap(res);
      }
    });
  }

  getCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api_map.getAllCity(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getPhases(body: any) {
    return new Promise((resolve, reject) => {
      this.api_map.getAllPhases(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAreaByCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api_map.getAreaByCity(body).subscribe(
        (res) => {
          resolve(this.responseAreaHandling(res));
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  getAreas(body: any) {
    return new Promise((resolve, reject) => {
      this.api_map.getMaps(body).subscribe(
        (res) => {
          resolve(this.responseAreaHandling(res));
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  responseAreaHandling(res: any) {
    if (_.isArray(res.body) && res.body.length > 0) {
      for (let i = 0; i <= res.body.length - 1; i++) {
        res.body[i]["value"] = res.body[i]["id"];
        res.body[i]["viewValue"] = res.body[i]["title"];
      }
      return this.sortMap(res.body);
    } else {
      return [];
    }
  }

  timePickerOnChange(val: any) {
    let hours = String(val.getHours());
    let minutes = String(val.getMinutes());
    hours = hours.length <= 1 ? "0" + hours : hours;
    minutes = minutes.length <= 1 ? "0" + minutes : minutes;
    let time = hours + ":" + minutes + ":00";
    this.selected.estimatedStartTime = time;
  }
  close() {
    this.dialogRef.close("cancel");
  }
  isEmpty() {
    return this.selected.areaId === "";
  }
  selectArea(val: any) {
    this.selected.areaId = val.id;
    this.selected.areaTitle = val.title;
    this.selected.areaPhase = val.phase;
  }

  sortMap(res: any[]) {
    return _.orderBy(res, ["phase", "title"], "asc");
  }
}
