import { OverlayContainer } from "@angular/cdk/overlay";
import {
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiUserService } from "src/app/service/api.user.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  constructor(
    private router: Router,
    private api: ApiUserService,
    private common: CommonService,
    private overlayContainer: OverlayContainer
  ) {}

  @HostBinding("class") componentCssClass: any;

  @Output() menuClicked = new EventEmitter<Object>();
  navs = this.common.getNavRoute();
  userInfo: any;
  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    try {
      let result: any = await this.getUserInfo();
      if (result.body) {
        this.userInfo = result.body;
      }
    } catch (error) {
      this.common.openSnackBar(error, "Close");
    }
  }
  getUserInfo() {
    return new Promise((resolve, reject) => {
      this.api.getUserInfo({}).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  linkOnClicked(nav: any) {
    this.menuClicked.emit(nav);
    this.router.navigateByUrl(nav.path);
  }

  onSetTheme(theme: any) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }
}
