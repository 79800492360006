<div style="text-align: right; padding: 10px">
  <button
    mat-button
    (click)="openDialog('Add', {})"
    mat-flat-button
    color="primary"
  >
    Add New
  </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 116px); overflow-y: scroll"
>
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="question">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Question
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.question }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="answer">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Answer
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.answer }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      style="justify-content: flex-end"
    >
      Action
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      style="justify-content: flex-end"
    >
      <a
        style="margin: 5px"
        mat-raised-button
        color="primary"
        (click)="openDialog('Update', element)"
        >Edit</a
      >
      <a
        style="margin: 5px"
        mat-raised-button
        color="warn"
        (click)="openDialog('Delete', element)"
        >Delete</a
      >
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
  </mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginator
  showFirstLastButtons
  [length]="totalRows"
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
