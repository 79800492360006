import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxEditorModule } from 'ngx-editor';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateMapModalComponent } from './create-map-modal/create-map-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatRippleModule } from '@angular/material/core';
import { NewsDialog } from '../../modules/setup/news/news-dialog/news-dialog';
import { LoginComponent } from '../../modules/login/login.component';
import { SmartTableButtonComponent } from '../../components/smart-table-button/smart-table-button.component';
import { SelectLocationComponent } from '../../components/select-location/select-location.component';
import { LorryDialog } from '../../modules/setup/lorry/lorry-dialog/lorry-dialog';

import { DriverDialog } from '../../modules/setup/driver/driver-dialog/driver-dialog';
import { FaqDialog } from '../../modules/setup/faq/faq-dialog/faq-dialog';
import { ScheduleSummaryComponent } from '../../modules/schedule-summary/schedule-summary.component';
import { ScheduleDetailComponent } from '../../modules/schedule-detail/schedule-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UploadImagesComponent } from '../../components/upload-images/upload-images.component';
import { ChangePasswordDialog } from '../../components/change-password-dialog/change-password-dialog';
import { AssistantDialog } from '../../modules/setup/assistant/assistant-dialog/assistant-dialog';
import { UserNormalDialog } from '../../modules/setup/user-normal/user-normal-dialog/user-normal-dialog';
import { UserAdminDialog } from '../../modules/setup/user-admin/user-admin-dialog/user-admin-dialog';
import { RequestDialog } from '../../modules/report/requests/requests-dialog/requests-dialog';

import { AlertDialog } from '../../components/alert-dialog/alert-dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CollectionDialog } from 'src/app/modules/report/collection/collection-dialog/collection-dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgImageSliderModule } from 'ng-image-slider';
import { NotificationDialog } from 'src/app/modules/setup/notification/notification-dialog/notification-dialog';
import { TimeTableDialog } from 'src/app/modules/setup/time-table/time-table-dialog/time-table-dialog';
import { TableauModule } from 'ngx-tableau';
import { BiComponent } from 'src/app/modules/bi/bi.component';
import { BackdateCollectionDialog } from 'src/app/modules/backdate-collection/backdate-collection-dialog/backdate-collection-dialog';
import { BackdateCollectionComponent } from 'src/app/modules/backdate-collection/backdate-collection.component';
import { RepeatDialog } from 'src/app/modules/setup/repeat/repeat-dialog/repeat-dialog';
import { Select2Module } from 'ng-select2-component';

@NgModule({
  providers: [NgxImageCompressService],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CreateMapModalComponent,

    LoginComponent,
    SmartTableButtonComponent,
    SelectLocationComponent,

    ChangePasswordDialog,
    AlertDialog,
    NewsDialog,
    LorryDialog,
    AssistantDialog,
    DriverDialog,
    FaqDialog,
    NotificationDialog,
    TimeTableDialog,
    UserNormalDialog,
    UserAdminDialog,
    RequestDialog,
    RepeatDialog,
    CollectionDialog,
    BackdateCollectionDialog,
    ScheduleSummaryComponent,
    ScheduleDetailComponent,
    BackdateCollectionComponent,
    UploadImagesComponent,
    BiComponent,
  ],
  imports: [
    NgxEditorModule,
    TableauModule,
    NgImageSliderModule,
    CommonModule,
    RouterModule,
    LeafletModule,
    LeafletDrawModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatBadgeModule,
    FormsModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatChipsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    DragDropModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    OverlayModule,
    Select2Module,
  ],
  exports: [
    TableauModule,
    NgImageSliderModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    LeafletModule,
    LeafletDrawModule,
    MatDialogModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatChipsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CreateMapModalComponent,
    DragDropModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSidenavModule,
    Ng2SmartTableModule,
    OverlayModule,
    Select2Module,
  ],
})
export class SharedModule {}
