import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";

@Component({
  selector: "app-lorry-dialog",
  templateUrl: "./lorry-dialog.html",
  styleUrls: ["./lorry-dialog.scss"],
})
export class LorryDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LorryDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }
  action: string;
  local_data: any;
  setupForm = new FormGroup({
    title: new FormControl("", [Validators.required]),
  });

  ngOnInit(): void {}
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== "Delete") {
      return true;
    }
    return false;
  }
}
