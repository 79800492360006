import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class ApiConfigurationService {
  apiurl = environment.api_url;
  endpoint = "/api/v1/admin/configuration";

  constructor(private http: HttpClient, private common: CommonService) {}

  getShareText(body: any) {
    let url = this.apiurl + this.endpoint + "/shareText";
    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(catchError(this.common.handleError));
  }
  createShareText(body: any) {
    let url = this.apiurl + this.endpoint + "/shareText";
    return this.http.post(url, body).pipe(catchError(this.common.handleError));
  }

  getAlerts(body: any) {
    let url = this.apiurl + this.endpoint + "/alert";
    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(catchError(this.common.handleError));
  }
  createAlerts(body: any) {
    let url = this.apiurl + this.endpoint + "/alert";
    return this.http.post(url, body).pipe(catchError(this.common.handleError));
  }
}
