import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
  area: string;
  title: string;
}

@Component({
  selector: "app-create-map-modal",
  templateUrl: "./create-map-modal.component.html",
  styleUrls: ["./create-map-modal.component.scss"],
})
export class CreateMapModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateMapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
