<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <div style="width: 100%; display: inline-flex; padding: 5px">
      <div style="width: 50%">
        <app-upload-images
          [imgUrl]="local_data.alertIconAttachmentUrl"
          [fileKey]="'alertIconAttachmentId'"
          (fileChange)="fileUploaded($event)"
          [isProfile]="true"
          [compress]="true"
        ></app-upload-images>
      </div>
      <div style="width: 50%; text-align: right">
        <mat-slide-toggle
          style="margin: auto"
          [labelPosition]="'before'"
          [(ngModel)]="local_data.active"
          formControlName="active"
        >
          Status
        </mat-slide-toggle>
      </div>
    </div>

    <mat-form-field>
      <input
        placeholder="{{action}} Alert Message"
        matInput
        [(ngModel)]="local_data.alertMessage"
        formControlName="alertMessage"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        placeholder="{{action}} Alert Color"
        matInput
        [(ngModel)]="local_data.alertColourCode"
        formControlName="alertColourCode"
      />
    </mat-form-field>
  </form>

  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.title}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>

<!-- <h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <mat-form-field>
      <input
        placeholder="{{action}} Alert Message"
        matInput
        [(ngModel)]="local_data.alertMessage"
        formControlName="alertMessage"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        placeholder="{{action}} Alert Color"
        matInput
        [(ngModel)]="local_data.alertColourCode"
        formControlName="alertColourCode"
      />
    </mat-form-field>
  </form>

  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.alertMessage}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancel
  </button>
</div> -->
