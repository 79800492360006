<!-- {{ data | json }} -->
<h1 mat-dialog-title>Add Location</h1>
<div mat-dialog-content>
  <section>
    <div style="width: 100%; display: inline-flex">
      <mat-form-field
        style="width: 25%; padding: 10px; margin: auto"
        appearance="fill"
        *ngIf="cities.length > 0"
      >
        <mat-label>Filter By City</mat-label>
        <mat-select
          (selectionChange)="cityOnChange($event)"
          [(ngModel)]="selectedCity"
        >
          <mat-option>None</mat-option>
          <mat-option *ngFor="let city of cities" [value]="city">
            {{ city }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        style="width: 25%; padding: 10px; margin: auto"
        appearance="fill"
        *ngIf="phases.length > 0"
      >
        <mat-label>Filter By Phase</mat-label>
        <mat-select
          (selectionChange)="phaseOnChange($event)"
          [(ngModel)]="selectedPhase"
        >
          <mat-option>None</mat-option>
          <mat-option *ngFor="let city of phases" [value]="city">
            {{ city }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        style="
          width: 50%;
          display: inline-flex;
          border: solid 1px lightgrey;
          border-radius: 15px;
        "
      >
        <mat-label style="margin: auto">Estimated Start Time</mat-label>
        <ngx-mat-timepicker
          [(ngModel)]="myTimePicker"
          (ngModelChange)="timePickerOnChange($event)"
          [showSpinners]="true"
          [enableMeridian]="true"
        >
        </ngx-mat-timepicker>
      </div>
    </div>
    <mat-grid-list cols="6" rowHeight="100px" *ngIf="maps.length > 0">
      <mat-grid-tile *ngFor="let map of maps">
        <button
          style="
            width: 100%;
            height: 80%;
            margin: 5px;
            white-space: pre-wrap !important;
            font-size: 10px;
            font-size: 0.8vw;
            line-height: 20px;
          "
          mat-raised-button
          color="primary"
          matBadge="S"
          matBadgePosition="below after"
          matBadgeColor="accent"
          [matBadgeHidden]="selected.areaId !== map.id"
          (click)="selectArea(map)"
        >
          <span
            *ngIf="map.phase !== null"
            style="font-size: smaller; text-decoration: underline"
          >
            {{ map.phase }}
          </span>
          <br />
          {{ map.title }}
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </section>
</div>

<div mat-dialog-actions style="margin: auto; display: flex; float: right">
  <button mat-raised-button color="warn" (click)="close()">Close</button>
  <button
    [disabled]="isEmpty()"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="selected"
    cdkFocusInitial
  >
    Add
  </button>
</div>
