<div style="width: 100%; display: inline-flex">
  <div style="display: inline-flex; width: 80%; padding: 5px">
    <mat-form-field appearance="fill">
      <mat-label>From Date</mat-label>
      <input
        matInput
        [matDatepicker]="datepickerFrom"
        [(ngModel)]="formData.fromDate"
        (dateChange)="onDateChange($event)"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datepickerFrom"
      ></mat-datepicker-toggle>
      <mat-datepicker #datepickerFrom>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    &nbsp; &nbsp;
    <mat-form-field appearance="fill">
      <mat-label>To Date</mat-label>
      <input
        matInput
        [matDatepicker]="datepickerTo"
        [(ngModel)]="formData.toDate"
        (dateChange)="onDateChange($event)"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datepickerTo"
      ></mat-datepicker-toggle>
      <mat-datepicker #datepickerTo>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    &nbsp; &nbsp;
    <mat-form-field appearance="fill" style="width: 30%">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="formData.search" />
    </mat-form-field>
    &nbsp;&nbsp;
    <button
      style="height: 52px"
      color="primary"
      mat-fab
      aria-label="Search"
      (click)="search()"
    >
      <mat-icon>search</mat-icon>
    </button>
    <!-- <button
      [disabled]="isFromToDateEmpty()"
      mat-raised-button
      color="primary"
      style="min-width: 100px; height: 52px"
      (click)="downloadCSV()"
    >
      Search
    </button> -->
  </div>
  <div style="width: 20%; text-align: right">
    <button
      [disabled]="isFromToDateEmpty()"
      mat-raised-button
      color="primary"
      style="min-width: 100px; height: 60px"
      (click)="downloadCSV()"
    >
      Download
    </button>
  </div>
</div>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 140px); overflow-y: scroll"
  matSort
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="id">
      No.
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="collectedDate">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="collectedDate"
    >
      Collected Date
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.collectedDate | date: "dd-MMM-yy hh:mm aa" }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="collectionDate">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.collectionDate }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="collectionTime">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Time </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.collectionTime }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="collectionWeek">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Week </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.collectionWeek }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="scheduleTitle">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="scheduleTitle"
    >
      Schedule Title
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.scheduleTitle }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lorryTitle">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="lorryTitle"
    >
      Lorry
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.lorryTitle }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="city">
    <mat-header-cell mat-header-cell *matHeaderCellDef> City </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.city }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="phase">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Phase </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.phase }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="areaTitle">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Area </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.areaTitle }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="address">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="address"
    >
      Address
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.address }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="yellowBox">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Is Yellow Box
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.yellowBox === true ? "Yes" : "No" }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="unitNumber">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Unit No.
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.unitNumber }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="fullName">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Driver
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.driver.fullName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assistant1">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Assistant 1
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ getAssistant(element, "1") }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assistant2">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Assistant 2
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ getAssistant(element, "2") }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="estimateWeightKg">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="estimateWeightKg"
    >
      Est. Weights
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.estimateWeightKg }} kg
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="latitude">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="latitude"
    >
      Latitude
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.latitude }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="longitude">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="longitude"
    >
      Longitude
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.longitude }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="remark">
    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="remark">
      Remark
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.remark }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="requestedByFullName">
    <mat-header-cell mat-header-cell *matHeaderCellDef> User </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.requestedByFullName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="requestedByUserName">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="requestedByUserName"
    >
      Requested By User
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.requestedByUserName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="status">
      Status
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ getStatus(element.status) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="collectedByFullName">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="collectedByFullName"
    >
      Collected By
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.collectedByFullName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="collectedByUserName">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="collectedByUserName"
    >
      Collected By User
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.collectedByUserName }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      style="justify-content: flex-end"
    >
      Action
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      style="justify-content: flex-end"
    >
      <a
        style="margin: 5px"
        mat-raised-button
        color="primary"
        (click)="openDialog('View', element)"
      >
        View
      </a>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  [pageSizeOptions]="[10, 20, 50, 100]"
  showFirstLastButtons
  [pageIndex]="page"
  [pageSize]="pageSize"
  [length]="totalLength"
  (page)="pageChanged($event)"
  aria-label="Select page of periodic elements"
>
</mat-paginator>
