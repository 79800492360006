<h1 mat-dialog-title>{{action}}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <mat-form-field>
      <input
        placeholder="{{action}} Name"
        matInput
        [(ngModel)]="local_data.title"
        formControlName="title"
      />
    </mat-form-field>
    <div style="width: 100%; display: inline-flex; margin: auto; padding: 5px">
      <select2
        *ngIf="areas.length>0"
        placeholder="Area"
        style="width: 100%"
        formControlName="areaId"
        [styleMode]="'material'"
        [(ngModel)]="local_data.areaId"
        [data]="areas"
        (update)="areaOnChange($event)"
      >
      </select2>
    </div>

    <mat-form-field appearance="fill" *ngIf="dayOfWeeks.length > 0">
      <mat-label>Day Of Week</mat-label>
      <mat-select
        (selectionChange)="dayOfWeekOnChange($event)"
        [(ngModel)]="local_data.dayOfWeek"
        formControlName="dayOfWeek"
      >
        <mat-option *ngFor="let city of dayOfWeeks" [value]="city.id">
          {{ city.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="NgxEditor__Wrapper" style="height: 350px">
      <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
      <ngx-editor
        (ngModelChange)="onChange($event)"
        formControlName="description"
        [editor]="editor"
        [ngModel]="local_data.description"
        [disabled]="false"
        [placeholder]="'Type here...'"
      ></ngx-editor>
    </div>
  </form>

  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.title}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancel
  </button>
</div>
