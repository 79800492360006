<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 60px); overflow-y: scroll"
>
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="userName">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Username
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.userName }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="fullName">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Full Name
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.fullName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.email }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Phone Number
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.phoneNumber }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="areaTitle">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Area </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.areaTitle }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="userStatus">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Status
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <!-- {{ element.userStatus === 0 ? "Inactive" : "Active" }} -->
      <!-- style="margin: auto" -->
      <mat-slide-toggle
        [(ngModel)]="element.userStatus"
        (toggleChange)="slideToggleChange(element)"
      >
      </mat-slide-toggle>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="profilePictureUrl">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Profile Picture
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <img
        style="border-radius: 50%; width: 50px; height: 50px; padding: 5px"
        [src]="element.profilePictureUrl"
        alt="..."
      />
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
  </mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginator
  showFirstLastButtons
  [length]="totalRows"
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
