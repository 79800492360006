import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectionList } from "@angular/material/list";
import * as _ from "lodash";
import { SelectLocationComponent } from "src/app/components/select-location/select-location.component";
import { ApiLorryService } from "src/app/service/api.lorry.service";
import { ApiMapService } from "src/app/service/api.map.service";
import { ApiScheduleService } from "src/app/service/api.schedule.service";
import { ApiUserService } from "src/app/service/api.user.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CommonService } from "src/app/service/common.service";
import { AlertDialog } from "src/app/components/alert-dialog/alert-dialog";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Router } from "@angular/router";
@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
  providers: [FormGroupDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements OnInit {
  selectedTabIndex = 0;
  panelOpenState = true;

  scheduleForm = new FormGroup({
    title: new FormControl("", [Validators.required]),
    driverUserId: new FormControl("", [Validators.required]),
    lorryId: new FormControl("", [Validators.required]),
    scheduleDate: new FormControl("", [Validators.required]),
    assistant1: new FormControl("", []),
    assistant2: new FormControl("", []),
  });
  formData = <any>{};
  schedules = <any>[];
  currentSelect: any;

  showButton = {
    add: false,
    edit: false,
    delete: false,
    close: false,
    save: false,
    publish: false,
    repeat: false,
  };
  drivers = <any>[];
  assistants = <any>[];
  maps = <any>[];
  lorries = <any>[];
  action = { isEdit: false, isAdd: false };

  constructor(
    private api_schedule: ApiScheduleService,
    private api_user: ApiUserService,
    private api_map: ApiMapService,
    private api_lorry: ApiLorryService,
    private dialog: MatDialog,
    private formDirective: FormGroupDirective,
    private common: CommonService,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.defaultFlow();
    this.schedules = [];
    await this.loadSchedules();
    this.drivers = await this.getDriverSimple({});
    this.assistants = await this.getAssistantSimple({});
    this.lorries = await this.getLorry({});
  }

  resetFormData() {
    this.formData = <any>{
      title: "",
      driverUserId: 0,
      lorryId: 0,
      scheduleDate: "",
      assistant1: "",
      assistant2: "",
      areas: <any>[],
    };
  }

  async onTabChanged(tab: any) {
    await this.defaultFlow();
    this.schedules = [];
    this.loadSchedules();
  }

  async defaultFlow() {
    this.currentSelect = {};
    this.resetButton();
    this.resetFormData();
    this.showButton.add = true;
    this.formDirective.reset();
    this.scheduleForm.reset();
    this.scheduleForm.disable();
  }

  async loadSchedules() {
    let result: any;
    if (this.selectedTabIndex === 0) {
      result = await this.getLiveSchedules({});
      if (_.isArray(result["body"]) && result["body"].length > 0) {
        this.schedules = _.sortBy(result.body, (ele) => {
          return String(ele.date);
        });
        this.ref.detectChanges();
      }
    } else {
      result = await this.getPastSchedules({});
      if (_.isArray(result["body"]) && result["body"].length > 0) {
        this.schedules = result.body;
        this.ref.detectChanges();
      }
    }
  }

  selectAreaChange(schedule: any) {
    this.defaultFlow();

    this.currentSelect = _.cloneDeep(schedule);
    this.formData = _.cloneDeep(this.currentSelect);

    this.showButton.add = true;
    this.showButton.repeat = true;

    if (this.getStatus(this.currentSelect.status) === "Draft") {
      this.showButton.edit = true;
      this.showButton.publish = true;
      this.showButton.delete = true;
      this.showButton.repeat = false;
    }

    this.formData.areas = this.currentSelect.areas;
    if (this.formData.areas.length > 0) {
      for (let i = 0; i <= this.formData.areas.length - 1; i++) {
        this.formData.areas[i].estimatedStartTime =
          this.formData.areas[i].estimatedStartTime.substring(11);
      }
    }

    this.formData.lorryId = this.currentSelect.lorry.id;
    this.formData.driverUserId = this.currentSelect.driver.id;

    this.formData.assistant1 = 0;
    this.formData.assistant2 = 0;
    if (this.currentSelect.assistants.length > 0) {
      for (let i = 0; i <= this.currentSelect.assistants.length - 1; i++) {
        if (i === 0) {
          this.formData.assistant1 = this.currentSelect.assistants[i].id || 0;
        }
        if (i === 1) {
          this.formData.assistant2 = this.currentSelect.assistants[i].id || 0;
        }
      }
    }

    this.scheduleForm.setValue({
      title: this.formData.title,
      driverUserId: this.formData.driverUserId,
      lorryId: this.formData.lorryId,
      scheduleDate: this.formData.scheduleDate,
      assistant1: this.formData.assistant1,
      assistant2: this.formData.assistant2,
    });
  }

  getDateDiffInHours(startDt: any, endDt: any) {
    const diffInMs = Date.parse(endDt) - Date.parse(startDt);

    if (_.isNaN(diffInMs)) {
      return "00:00:00";
    }
    let hr = Math.floor(diffInMs / (1000 * 60 * 60)).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    });
    let mm = (Math.floor(diffInMs / (1000 * 60)) % 60).toLocaleString(
      undefined,
      {
        minimumIntegerDigits: 2,
      }
    );
    let ss = (Math.floor(diffInMs / 1000) % 60).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    });

    return hr + ":" + mm + ":" + ss;
  }
  // selectLocationChange(val: any) {

  // }

  btnAdd() {
    this.defaultFlow();
    this.action.isAdd = true;
    this.action.isEdit = false;

    this.showButton.add = false;
    this.showButton.close = true;
    this.showButton.save = true;

    this.scheduleForm.enable();
  }

  btnClose() {
    this.defaultFlow();
  }

  btnSave() {
    let body: any = _.cloneDeep(this.formData);

    body["scheduleDate"] = this.common.getAPIDateString(body["scheduleDate"]);

    for (let i = 0; i <= body.areas.length - 1; i++) {
      delete body.areas[i].areaTitle;
    }
    body.assistantIds = [];
    body.assistant1 = Number(body.assistant1);
    body.assistant2 = Number(body.assistant2);

    if (body.assistant1 !== 0) {
      body.assistantIds.push(Number(body.assistant1));
    }
    if (body.assistant2 !== 0) {
      body.assistantIds.push(Number(body.assistant2));
    }

    if (!this.dataValidation(body)) {
      return;
    }
    delete body.assistant1;
    delete body.assistant2;

    if (this.action.isAdd) {
      this.addSchedule(body).then(async (res) => {
        this.defaultFlow();
        this.schedules = [];
        await this.loadSchedules();
      });
    } else {
      this.editSchedule(body).then(async (res) => {
        this.ref.detectChanges();
        this.defaultFlow();
        this.schedules = [];
        await this.loadSchedules();
      });
    }
  }
  btnDelete() {
    let body: any = _.cloneDeep(this.formData);
    this.deleteSchedule(body).then(async (res) => {
      this.defaultFlow();
      this.schedules = [];
      await this.loadSchedules();
    });
  }

  btnEdit() {
    this.action.isAdd = false;
    this.action.isEdit = true;

    this.showButton.close = true;
    this.showButton.save = true;
    this.showButton.add = false;
    this.showButton.edit = false;
    this.scheduleForm.enable();
  }

  btnPublish() {
    this.publishSchedule(this.currentSelect).then(
      async (res: any) => {
        if (res.success) {
          // [Schedule 'WallAce Test' is ready to start by Driver : Wallace Tan]
          let msg =
            "Schedule " +
            this.currentSelect.title +
            " is ready to start by Driver: " +
            this.currentSelect.driver.fullName;
          this.common.openSnackBar(msg, "Close");
          this.defaultFlow();
          this.schedules = [];
          await this.loadSchedules();
        }
      },
      (err) => {
        // Location "Taman Putra King" does not have any house unit registered.
        this.common.openSnackBar(err, "Close");
      }
    );
  }

  dataValidation(body: any) {
    let result = true;
    if (body.assistant1 !== 0 && body.assistant2 !== 0) {
      if (body.assistant1 === body.assistant2) {
        this.common.openSnackBar(
          "Assistant 1 and Assistant 2 cannot be the same person.",
          "Close"
        );
        result = false;
      }
    }
    return result;
  }

  addSchedule(body: any) {
    return new Promise((resolve, reject) => {
      this.api_schedule.createSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  editSchedule(body: any) {
    return new Promise((resolve, reject) => {
      this.api_schedule.updateSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  deleteSchedule(body: any) {
    return new Promise((resolve, reject) => {
      this.api_schedule.deleteSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          console.log(err);
          reject(err);
        }
      );
    });
  }
  publishSchedule(body: any) {
    return new Promise((resolve, reject) => {
      this.api_schedule.publishSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          console.log(err);
          reject(err);
        }
      );
    });
  }

  showCancelButton() {
    let result = false;
    if (
      _.includes(
        ["Scheduled", "Started", "Reached"],
        this.getStatus(this.currentSelect.status)
      )
    ) {
      result = true;
    }

    return result;
  }
  cancelSchedule(body: any, status: any) {
    this.openDialog(status, body);
  }
  cancelScheduleApi(body: any) {
    let obj = { id: this.currentSelect.id, areaId: body.areaId };

    return new Promise((resolve, reject) => {
      this.api_schedule.cancelSchedule(obj).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          console.log(err);
          reject(err);
        }
      );
    });
  }
  completeScheduleApi(body: any) {
    let obj = { id: this.currentSelect.id, areaId: body.areaId };

    return new Promise((resolve, reject) => {
      this.api_schedule.completeSchedule(obj).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          console.log(err);
          reject(err);
        }
      );
    });
  }
  reachedScheduleApi(body: any) {
    let obj = { id: this.currentSelect.id, areaId: body.areaId };

    return new Promise((resolve, reject) => {
      this.api_schedule.reachedSchedule(obj).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          console.log(err);
          reject(err);
        }
      );
    });
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    obj.msg =
      "Are you sure you want to " +
      _.lowerCase(action) +
      "  this schedule location?";

    const dialogRef = this.dialog.open(AlertDialog, {
      width: "600px",
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (_.isUndefined(result)) {
        return;
      } else if (result.event === "Ok") {
        if (_.lowerCase(action) === "cancel") {
          this.cancelScheduleApi(obj).then(async (res) => {
            this.defaultFlow();
            this.schedules = [];
            await this.loadSchedules();
          });
        }
        if (_.lowerCase(action) === "complete") {
          this.completeScheduleApi(obj).then(async (res) => {
            this.defaultFlow();
            this.schedules = [];
            await this.loadSchedules();
          });
        }
        if (_.lowerCase(action) === "reached") {
          this.reachedScheduleApi(obj).then(async (res) => {
            this.defaultFlow();
            this.schedules = [];
            await this.loadSchedules();
          });
        }
      }
    });
  }

  resetButton() {
    this.showButton.add = false;
    this.showButton.edit = false;
    this.showButton.delete = false;
    this.showButton.close = false;
    this.showButton.save = false;
    this.showButton.publish = false;
    this.showButton.repeat = false;
  }

  getLiveSchedules(body: any) {
    return new Promise((resolve, reject) => {
      this.api_schedule.getLiveSchedules(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  getPastSchedules(body: any) {
    return new Promise((resolve, reject) => {
      this.api_schedule.getPastSchedules(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  getDriverSimple(body: any) {
    return new Promise((resolve, reject) => {
      this.api_user.getDriverSimple(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  getMaps(body: any) {
    return new Promise((resolve, reject) => {
      this.api_map.getMaps(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  getAssistantSimple(body: any) {
    return new Promise((resolve, reject) => {
      this.api_user.getAssistantSimple(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  getLorry(body: any) {
    return new Promise((resolve, reject) => {
      this.api_lorry.getLorryLists(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
          reject(err);
        }
      );
    });
  }

  openSelectLocation() {
    let dialogRef = this.dialog.open(SelectLocationComponent, {
      data: this.maps,
      height: "760px",
      width: "1024px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (!_.isString(result) && !_.isUndefined(result)) {
        console.log(result);
        this.formData.areas.push(result);
        this.ref.detectChanges();
      }
    });
  }

  removeLocation(location: any) {
    if (this.formData.areas.length > 0) {
      let fndIdx = _.findIndex(this.formData.areas, (ele: any) => {
        return ele.areaId === location.areaId;
      });
      if (fndIdx !== -1) {
        this.formData.areas.splice(fndIdx, 1);
      }
    }
  }

  isAllowToSave() {
    if (this.scheduleForm.invalid) {
      return true;
    }
    if (this.formData.areas.length <= 0) {
      return true;
    }
    return false;
  }

  getStatus(val: any) {
    return this.common.getStatus(val);
  }
  getStatusColor(val: any) {
    if (val === 0) {
      // Draft
      return "disabled";
    }
    if (val === 1) {
      // Scheduled
      return "accent";
    }
    if (val === 2) {
      // Started
      return "primary";
    }
    if (val === 3) {
      // Reached
      return "foreground";
    }
    if (val === 4 || val === 7) {
      // Completed
      return "";
    }

    // if (val === 5) {
    //   // Cancelled
    //   return "accent";
    // }
    return "warn";
  }
  drop(event: any, schedules: any) {
    moveItemInArray(schedules, event.previousIndex, event.currentIndex);
  }

  btnRepeat() {
    let body = {
      scheduleDate: this.currentSelect.scheduleDate,
      scheduleId: this.currentSelect.id,
      scheduleTitle: this.currentSelect.title,
    };
    this.router.navigateByUrl("/repeat/" + JSON.stringify(body));
  }
}
