import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import * as _ from "lodash";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { CommonService } from "src/app/service/common.service";
import { ApiMapService } from "src/app/service/api.map.service";
import { AlertDialog } from "src/app/components/alert-dialog/alert-dialog";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  providers: [FormGroupDirective],
})
export class MapComponent implements OnInit, AfterViewInit {
  constructor(
    public dialog: MatDialog,
    private common: CommonService,
    private formDirective: FormGroupDirective,
    private api: ApiMapService
  ) {}
  provider = new OpenStreetMapProvider();

  map: any;
  selectedTabIndex = 0;
  selectedMap = "";
  selectedCity = "";
  selectedPhase = "";
  showButton = {
    add: false,
    edit: false,
    delete: false,
    close: false,
    save: false,
    next: false,
  };
  areaForm = new FormGroup({
    title: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.required]),
    phase: new FormControl("", [Validators.required]),
    latitude: new FormControl("", []),
    longitude: new FormControl("", []),
  });

  formData = <any>{};
  areas = [{ value: 1, viewValue: "Petaling Jaya" }];
  cities = <any>[];
  phases = <any>[];
  maps = <any>[];
  mapsOptions = [{ value: 1, viewValue: "Tropicana Golf", areaId: 1 }];
  location = [{ latitude: 3.1443720245539515, longitude: 101.37338884165163 }];
  options = {
    layers: [
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 18,
    center: L.latLng(46.879966, -121.726909),
    touchExtend: false,
  };
  drawnItems: L.FeatureGroup = L.featureGroup();

  drawOptions: any = {
    position: "topright",
    draw: false,
    edit: {
      featureGroup: this.drawnItems,
      edit: false,
      remove: false,
    },
  };
  loadMap = false;
  action = {
    edit: false,
    add: false,
  };

  ngOnInit() {
    this.defaultFlow();

    this.loadCities();
    this.loadPhases();
  }
  loadCities() {
    this.maps = <any>[];
    this.getCity({}).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        this.cities = res.body;
      }
    });
  }
  loadPhases() {
    this.phases = <any>[];
    this.getPhases({}).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        this.phases = res.body;
      }
    });
  }

  async defaultFlow() {
    this.selectedTabIndex = 0;
    this.resetFormData();
    this.formDirective.reset();
    this.areaForm.reset();
    this.areaForm.disable();
    this.resetFabButton();
    this.drawnItems.clearLayers();

    this.showButton.add = true;
  }

  getGeoLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          resolve({ lat: res.coords.latitude, lng: res.coords.longitude });
        },
        () => {
          resolve({
            lat: "Please enable location access",
            lng: "Please enable location access",
          });
        },
        { timeout: 10000 }
      );
    });
  }
  resetFabButton() {
    this.showButton = {
      add: false,
      edit: false,
      delete: false,
      close: false,
      save: false,
      next: false,
    };
  }
  resetFormData() {
    this.formData = <any>{
      title: "",
      address1: "",
      address2: "",
      city: "",
      postalCode: "",
      state: "",
      latitude: null,
      longitude: null,
      coveredAreaPolygonPoints: <any>[],
      areaGuidelineWayPoints: <any>[],
    };
  }
  areaOnChange(val: any) {
    this.formData.area = val.value;
    if (this.formData.area !== "") {
      this.loadTitle(this.formData.area);
    }
  }
  reloadPage() {
    window.location.reload();
  }
  cityOnChange(val: any) {
    this.maps = <any>[];
    this.mapOnChange("");
    let obj = {
      city: this.selectedCity || "",
      phase: this.selectedPhase || "",
    };
    this.getAreaByCity(obj).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        for (let i = 0; i <= res.body.length - 1; i++) {
          res.body[i]["value"] = res.body[i]["id"];
          res.body[i]["viewValue"] = res.body[i]["title"];
        }

        this.maps = res.body;
      }
    });
  }
  phaseOnChange(val: any) {
    this.maps = <any>[];
    this.mapOnChange("");
    let obj = {
      city: this.selectedCity || "",
      phase: this.selectedPhase || "",
    };

    this.getAreaByCity(obj).then((res: any) => {
      if (_.isArray(res.body) && res.body.length > 0) {
        for (let i = 0; i <= res.body.length - 1; i++) {
          res.body[i]["value"] = res.body[i]["id"];
          res.body[i]["viewValue"] = res.body[i]["title"];
        }

        this.maps = res.body;
      }
    });
  }
  loadTitle(val: any) {
    this.maps = [];
    this.maps = _.filter(this.mapsOptions, (ele) => {
      return ele.areaId === val;
    });
  }
  mapOnChange(val: any) {
    if (val.value !== "" && !_.isUndefined(val.value)) {
      this.getArea(val.value).then((res: any) => {
        this.defaultFlow();
        this.loadMap = false;
        this.formData = _.cloneDeep(res.body);
        this.loadMap = true;
        if (this.map !== undefined && this.map !== null) {
          this.map.panTo(
            new L.LatLng(this.formData.latitude, this.formData.longitude)
          );
        }
        this.showButton.edit = true;
        this.showButton.delete = true;
        this.renderPolygonLayer();
      });
    } else {
      this.defaultFlow();

      if (this.map) {
        this.map.off();
        this.map = null;

        this.loadMap = false;
        this.selectedMap = "";
      }
    }
  }

  polygonDrawer: any;
  polylineDrawer: any;
  drawPolygonControl: any;
  drawPolylineControl: any;
  drawControlEdit: any;

  onMapReady(map: L.Map) {
    this.map = map;
    let searchControl = GeoSearchControl({
      provider: this.provider,
    });

    this.map.addControl(searchControl);
    if (this.action.add) {
      this.drawPolygonControl = new L.Control.Draw({
        position: "topright",
        draw: {
          polygon: {},
          marker: false,
          polyline: false,
          circle: false,
          circlemarker: false,
          rectangle: false,
        },
        edit: {
          featureGroup: this.drawnItems,
        },
      });
      this.map.addControl(this.drawPolygonControl);
    }
    this.map.panTo(
      new L.LatLng(this.formData.latitude, this.formData.longitude)
    );
  }
  ngAfterViewInit(): void {}

  async btnAdd() {
    this.selectedCity = "";
    this.selectedMap = "";

    this.loadMap = false;
    this.defaultFlow();
    this.action.edit = false;
    this.action.add = true;

    this.showButton.add = false;
    this.showButton.close = true;
    this.showButton.next = true;

    this.areaForm.enable();
    let location: any = await this.getGeoLocation();
    this.formData.latitude = location.lat;
    this.formData.longitude = location.lng;
  }

  btnEdit() {
    this.action.edit = true;

    this.drawPolygonControl = new L.Control.Draw({
      position: "topright",
      draw: {
        polygon: {},
        marker: false,
        polyline: {
          shapeOptions: {
            color: "#e60000",
          },
        },
        circle: false,
        circlemarker: false,
        rectangle: false,
      },
      edit: {
        featureGroup: this.drawnItems,
      },
    });
    this.map.addControl(this.drawPolygonControl);

    this.showButton.add = false;
    this.showButton.edit = false;
    this.showButton.delete = false;
    this.showButton.save = true;
    this.showButton.close = true;
    this.showButton.next = true;
    this.areaForm.enable();
  }
  btnDelete() {
    this.deleteArea(this.formData).then(
      (res) => {
        this.loadCities();
        this.selectedCity = "";
        this.selectedMap = "";
        this.defaultFlow();
        this.map.off();
        this.loadMap = false;
        this.reloadPage();
      },
      (err) => {
        let msg = `Unable delete ${this.formData.title}, it was reference by other module.`;
        this.common.openSnackBar(msg);
      }
    );
  }
  btnClose() {
    if (this.action.add) {
      this.defaultFlow();
      this.loadMap = false;
    } else {
      this.action.edit = false;
      this.drawPolygonControl.remove();

      this.showButton.add = true;
      this.showButton.edit = true;
      this.showButton.delete = true;
      this.showButton.save = false;
      this.showButton.close = false;
      this.showButton.next = false;
      this.areaForm.disable();
    }
  }
  btnSave() {
    if (this.formData.areaGuidelineWayPoints.length > 0) {
      let pos = this.formData.areaGuidelineWayPoints[0];
      this.formData.latitude = pos.latitude || this.formData.latitude;
      this.formData.longitude = pos.longitude || this.formData.longitude;
    }

    if (!this.action.edit) {
      this.saveArea(this.formData).then(
        (res) => {
          this.loadCities();
          this.selectedCity = "";
          this.selectedMap = "";
          this.defaultFlow();
          this.map.off();
          this.loadMap = false;
          this.reloadPage();
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
        }
      );
    } else {
      this.updateArea(this.formData).then(
        (res) => {
          this.loadCities();
          this.selectedCity = "";
          this.selectedMap = "";
          this.defaultFlow();
          this.map.off();
          this.loadMap = false;
          this.reloadPage();
        },
        (err) => {
          this.common.openSnackBar(err, "Close");
        }
      );
    }
  }

  formatLatLng(columnName: string) {
    if (this.formData[columnName].length > 0) {
      this.formData[columnName] = _.map(this.formData[columnName], (ele) => {
        return { latitude: ele.lat, longitude: ele.lng };
      });
    }
  }
  isFormValid() {
    return !this.areaForm.valid;
  }
  btnNext() {
    this.selectedTabIndex = 1;

    this.loadMap = true;
  }

  onDrawCreated(e: any) {
    if (e.layerType === "polygon") {
      this.drawnItems.addLayer((e as L.DrawEvents.Created).layer);
      if (e.layer._latlngs.length > 0) {
        this.formData.coveredAreaPolygonPoints = e.layer._latlngs[0];
        this.formatLatLng("coveredAreaPolygonPoints");
        this.drawPolygonControl.remove();
        this.drawPolylineControl = new L.Control.Draw({
          position: "topright",
          draw: {
            polygon: {},
            marker: false,
            polyline: {
              shapeOptions: {
                color: "#e60000",
              },
            },
            circle: false,
            circlemarker: false,
            rectangle: false,
          },
          edit: {
            featureGroup: this.drawnItems,
          },
        });
        this.map.addControl(this.drawPolylineControl);

        this.selectedTabIndex = 2;
      }
    }

    if (e.layerType === "polyline") {
      this.drawnItems.addLayer((e as L.DrawEvents.Created).layer);
      if (e.layer._latlngs.length > 0) {
        this.formData.areaGuidelineWayPoints = e.layer._latlngs;
        this.formatLatLng("areaGuidelineWayPoints");
        this.showButton.save = true;
      }
    }
  }
  onDrawEdited(e: any) {
    // this.onDrawCreated(e);
    if (e.type === "draw:edited") {
      var layers = e.layers;
      layers.eachLayer((layer: any) => {
        if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
          //Do marker specific actions here
          this.formData.coveredAreaPolygonPoints = layer.getLatLngs()[0];
          this.formatLatLng("coveredAreaPolygonPoints");
        }
        if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
          //Do marker specific actions here

          this.formData.areaGuidelineWayPoints = layer.getLatLngs();
          this.formatLatLng("areaGuidelineWayPoints");
          this.showButton.save = true;
        }
      });
    }
  }
  onDrawDeleted(e: any) {
    this.formData.coveredAreaPolygonPoints = [];
    this.formData.areaGuidelineWayPoints = [];
  }

  isMarkerInsidePolygon(marker: any, poly: any) {
    var polyPoints = poly;
    var x = marker.latitude,
      y = marker.longitude;

    var inside = false;
    for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      var xi = polyPoints[i].latitude,
        yi = polyPoints[i].longitude;
      var xj = polyPoints[j].latitude,
        yj = polyPoints[j].longitude;

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }
  getAreas(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getMaps(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getArea(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getMap(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  saveArea(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createArea(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  updateArea(body: any) {
    return new Promise((resolve, reject) => {
      this.api.updateArea(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  deleteArea(body: any) {
    return new Promise((resolve, reject) => {
      this.api.deleteArea(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  getCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAllCity(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  getPhases(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAllPhases(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  getAreaByCity(body: any) {
    return new Promise((resolve, reject) => {
      this.api.getAreaByCity(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  renderPolygonLayer() {
    let polyLayers = [];
    let coverArea = <any>[];

    if (
      _.isArray(this.formData.coveredAreaPolygonPoints) &&
      this.formData.coveredAreaPolygonPoints.length > 0
    ) {
      coverArea = _.map(this.formData.coveredAreaPolygonPoints, (ele) => {
        return [ele.latitude, ele.longitude];
      });
    }
    var polygon1 = L.polygon(coverArea);
    polyLayers.push(polygon1);

    let navRoutes = <any>[];
    if (
      _.isArray(this.formData.areaGuidelineWayPoints) &&
      this.formData.areaGuidelineWayPoints.length > 0
    ) {
      navRoutes = _.map(this.formData.areaGuidelineWayPoints, (ele) => {
        return [ele.latitude, ele.longitude];
      });
    }
    var polyline = L.polyline(navRoutes, {
      color: "#e60000",
    });

    polyLayers.push(polyline);

    // Add the layers to the drawnItems feature group
    for (let layer of polyLayers) {
      this.drawnItems.addLayer(layer);
    }
  }
  openDialog(action: any, obj: any) {
    obj.action = action;
    obj.msg =
      "Are you sure to delete the Map " +
      obj.title +
      ", all the records will be deleted and not able to recover.";

    const dialogRef = this.dialog.open(AlertDialog, {
      width: "600px",
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (_.isUndefined(result)) {
        return;
      } else if (result.event === "Ok") {
        this.btnDelete();
      }
    });
  }
}
