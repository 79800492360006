<div
  *ngIf="action != 'Delete'; else elseTitleTemplate"
  mat-dialog-title
  style="display: inline-flex; margin: auto; padding-bottom: 10px; width: 100%"
>
  <div style="width: 200px; margin: auto">
    <h1 style="margin: 0px">{{action}}</h1>
  </div>
  <div style="width: calc(100% - 200px)">
    <mat-button-toggle-group
      *ngIf="action === 'Update'"
      style="float: right"
      #group="matButtonToggleGroup"
      value="{{local_data.status}}"
    >
      <mat-button-toggle
        value="0"
        (change)="onValChange($event.value)"
        matTooltip="Draft"
      >
        Draft
      </mat-button-toggle>
      <mat-button-toggle
        (change)="onValChange($event.value)"
        value="1"
        matTooltip="Published"
      >
        Published
      </mat-button-toggle>
      <mat-button-toggle
        (change)="onValChange($event.value)"
        value="2"
        matTooltip="Inactive"
      >
        Inactive
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<ng-template #elseTitleTemplate>
  <h1>{{action}}</h1>
</ng-template>
<div mat-dialog-content>
  <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    <mat-form-field>
      <input
        placeholder="{{action}} Title"
        matInput
        [(ngModel)]="local_data.title"
        formControlName="title"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{action}} Content &nbsp;&nbsp;
        <span style="font-style: italic">
          Enter <strong>"&lt;br&gt;"</strong> for new paragraph
        </span>
      </mat-label>
      <textarea
        placeholder="{{action}} Content"
        matInput
        [(ngModel)]="local_data.content"
        formControlName="content"
      ></textarea>
    </mat-form-field>
    <div style="width: 100%; display: inline-flex">
      <div style="width: 60%; padding: 5px">
        <mat-tab-group
          mat-align-tabs=""
          [selectedIndex]="local_data.attachmentType"
          (selectedIndexChange)="tabOnChange($event)"
        >
          <mat-tab label="Article Photo">
            <div style="padding-top: 15px">
              <app-upload-images
                [imgUrl]="local_data.logoAttachmentUrl"
                [fileKey]="'logoAttachmentId'"
                (fileChange)="fileUploaded($event)"
              ></app-upload-images>
            </div>
          </mat-tab>
          <mat-tab label="Youtube">
            <div style="padding-top: 15px">
              <mat-form-field>
                <textarea
                  placeholder="Copy & Paste Youtube Link Here"
                  matInput
                  [(ngModel)]="local_data.videoUrl"
                  videoUrl
                  formControlName="videoUrl"
                >
                </textarea>
              </mat-form-field>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div style="width: 40%; padding: 5px; min-height: 220px">
        <div style="display: inline-flex">
          <mat-slide-toggle
            style="padding: 5px"
            [labelPosition]="'before'"
            [(ngModel)]="local_data.isEvent"
            formControlName="isEvent"
            (ngModelChange)="toggleOnChange()"
          >
            Event
          </mat-slide-toggle>
          <app-upload-images
            *ngIf="local_data.isEvent === true"
            [imgUrl]="local_data.eventAttachmentUrl"
            [fileKey]="'eventAttachmentId'"
            (fileChange)="fileUploaded($event)"
          >
          </app-upload-images>
        </div>
      </div>
    </div>
  </form>
  <ng-template #elseTemplate>
    Are you sure to Delete the Articles <b>{{local_data.title}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancel
  </button>
</div>
