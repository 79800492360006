import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCollectionService {
  apiurl = environment.api_url;
  collection_endpoint = '/api/v1/admin/collection';

  constructor(private http: HttpClient, private common: CommonService) {}

  getCollection(body: any) {
    let url = this.apiurl + this.collection_endpoint;
    let pg = body.page + 1 || 1;

    let pgSz = body.pageSize || 15;
    let from = body.fromDate;
    let to = body.toDate;
    let search = body.search;

    let sortByColumn = body.sortByColumn || 'id';
    let sortDirection = (body.order === 'asc' ? 0 : 1) || 0;
    let para =
      'page=' +
      pg +
      '&pageSize=' +
      pgSz +
      '&sortByColumn=' +
      sortByColumn +
      '&order=' +
      sortDirection +
      '&from=' +
      from +
      '&to=' +
      to +
      '&search=' +
      search;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  exportCollection(body: any) {
    let url = this.apiurl + this.collection_endpoint + '/export';

    let from = body.fromDate;
    let to = body.toDate;

    let para = 'from=' + from + '&to=' + to;

    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  createCollection(body: any) {
    return this.http
      .post(this.apiurl + '/api/v1/admin/request', body)
      .pipe(catchError(this.common.handleError));
  }
  deleteCollection(body: any) {
    return this.http
      .delete(this.apiurl + '/api/v1/admin/request/' + body.id)
      .pipe(catchError(this.common.handleError));
  }
}
