import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';

import { ApiTimetableService } from 'src/app/service/api.timetable.service';
import { CommonService } from 'src/app/service/common.service';
import { TimeTableDialog } from './time-table-dialog/time-table-dialog';

@Component({
  selector: 'time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.scss'],
})
export class TimeTableComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api_serv: ApiTimetableService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];

  displayedColumns = [
    'id',
    'title',
    'areaTitle',
    'dayOfWeek',
    'description',
    'action',
  ];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  initData() {
    this.isLoading = true;
    this.api_serv
      .getTimetables({ page: this.currentPage, pageSize: this.pageSize })
      .subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.dataSource.data = res.body;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = res.headers.get('totalrecordcount');
            });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initData();
  }

  openDialog(action: any, obj: any) {
    obj.action = action;

    const dialogRef = this.dialog.open(TimeTableDialog, {
      width: '1024px',
      height: '90%',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }
  async addRowData(row_obj: any) {
    try {
      let result: any = await this.add(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Added Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async updateRowData(row_obj: any) {
    try {
      let result: any = await this.update(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Updated Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async deleteRowData(row_obj: any) {
    try {
      let result: any = await this.delete(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Delete Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api_serv.createTimetable(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  update(body: any) {
    return new Promise((resolve, reject) => {
      this.api_serv.updateTimetable(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  delete(body: any) {
    return new Promise((resolve, reject) => {
      this.api_serv.deleteTimetable(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getDayOfWeek(val: any) {
    let dayOfWeeks = [
      { id: 0, name: 'Sunday' },
      { id: 1, name: 'Monday' },
      { id: 2, name: 'Tuesday' },
      { id: 3, name: 'Wednesday' },
      { id: 4, name: 'Thursday' },
      { id: 5, name: 'Friday' },
      { id: 6, name: 'Saturday' },
    ];
    let resultValue = '';

    let fndIdx = _.findIndex(dayOfWeeks, (ele) => {
      return ele.id === val;
    });
    if (fndIdx !== -1) {
      resultValue = dayOfWeeks[fndIdx].name;
    }
    return resultValue;
  }
}
