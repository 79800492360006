import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";

import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { LoginService } from "../service/login.service";
const TOKEN_HEADER_KEY = "Authorization"; // for Spring Boot back-end
// const TOKEN_HEADER_KEY = "x-access-token"; // for Node.js Express back-end
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(private authService: LoginService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.authService.GetToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    if (authReq.url.includes("user/refresh")) {
      const refreshtoken = this.authService.GetRefreshToken();
      authReq = this.addTokenHeader(req, refreshtoken);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes("user/login") &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }
        return throwError(error);
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authService.GetRefreshToken();
      if (token)
        return this.authService.GenerateRefreshToken().pipe(
          switchMap((newtoken: any) => {
            this.isRefreshing = false;
            this.authService.SaveTokens(newtoken);
            this.refreshTokenSubject.next(newtoken.refresh_token);

            return next.handle(
              this.addTokenHeader(request, newtoken.auth_token)
            );
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.authService.Logout();
            return throwError(err);
          })
        );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, "Bearer " + token),
    });
    /* for Node.js Express back-end */
    // return request.clone({
    //   headers: request.headers.set(TOKEN_HEADER_KEY, token),
    // });
  }
}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
// import {
//   HttpEvent,
//   HttpHandler,
//   HttpInterceptor,
//   HttpRequest,
// } from "@angular/common/http";
// import { Injectable } from "@angular/core";
// import { Observable } from "rxjs";

// import { LoginService } from "./login.service";

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   constructor(private loginServ: LoginService) {}
//   intercept(
//     req: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     req = req.clone({
//       setHeaders: {
//         "Content-Type": "application/json; charset=utf-8",
//         Accept: "application/json",
//         Authorization: `Bearer ${this.loginServ.GetToken()}`,
//       },
//     });

//     return next.handle(req);
//   }
// }
