import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ChangePasswordDialog } from 'src/app/components/change-password-dialog/change-password-dialog';
import { ApiLorryService } from 'src/app/service/api.lorry.service';
import { ApiUserService } from 'src/app/service/api.user.service';
import { CommonService } from 'src/app/service/common.service';
import { UserNormalDialog } from './user-normal-dialog/user-normal-dialog';

@Component({
  selector: 'app-user-normal',
  templateUrl: './user-normal.component.html',
  styleUrls: ['./user-normal.component.scss'],
})
export class UserNormalComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api_user: ApiUserService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];

  displayedColumns = [
    'id',
    'userName',
    'fullName',
    'email',
    'phoneNumber',
    'areaTitle',
    'userStatus',
    'profilePictureUrl',
  ];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  initData() {
    this.isLoading = true;
    this.api_user
      .getAppsUsers({ page: this.currentPage, pageSize: this.pageSize })
      .subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            for (let i = 0; i <= res.body.length - 1; i++) {
              res.body[i]['userStatus'] =
                res.body[i]['userStatus'] === 0 ? true : false;
            }

            this.dataSource.data = res.body;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = res.headers.get('totalrecordcount');
            });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initData();
  }
  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(UserNormalDialog, {
      width: '600px',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (_.isUndefined(result)) {
        this.initData();
      } else {
        this.initData();
      }
    });
  }
  slideToggleChange(val: any) {
    let body = {
      id: val.id,
      userStatus: val.userStatus === true ? 1 : 0,
    };
    this.updateUserStatus(body).then(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  async addRowData(row_obj: any) {
    try {
      let result: any = await this.add(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Added Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async updateRowData(row_obj: any) {
    try {
      this.initData();
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async deleteRowData(row_obj: any) {
    try {
      let result: any = await this.delete(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Delete Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api_user.createDriver(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  update(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.api_lorry.updateLorry(body).subscribe(
    //     (res) => {
    //       resolve(res);
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }
  delete(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.api_lorry.deleteLorry(body).subscribe(
    //     (res) => {
    //       resolve(res);
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }

  updateUserStatus(body: any) {
    return new Promise((resolve, reject) => {
      this.api_user.updateUserStatus(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  openChangePassword(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(ChangePasswordDialog, {
      width: '600px',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
