import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";

@Component({
  selector: "app-faq-dialog",
  templateUrl: "./faq-dialog.html",
  styleUrls: ["./faq-dialog.scss"],
})
export class FaqDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FaqDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }
  action: string;
  local_data: any;
  setupForm = new FormGroup({
    question: new FormControl("", [Validators.required]),
    answer: new FormControl("", [Validators.required]),
  });
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {}

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
  isAllowToSave() {
    if (this.setupForm.invalid && this.action !== "Delete") {
      return true;
    }
    return false;
  }
}
