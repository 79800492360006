<mat-nav-list>
  <div class="profile-card" *ngIf="userInfo">
    <div class="header">
      <h2>{{ userInfo.fullName }}</h2>
    </div>
    <img [src]="userInfo.profilePictureUrl" />
  </div>

  <mat-divider></mat-divider>

  <div *ngFor="let nav of navs">
    <a
      mat-list-item
      *ngIf="!nav.childrens"
      (click)="linkOnClicked(nav)"
      routerLinkActive="router-link-active"
    >
      <div mat-line>{{ nav.name }}</div>
      <mat-icon>{{ nav.icon }}</mat-icon>
      <img
        *ngIf="nav.icon === 'bi'"
        src="../../../../assets/images/ic_data_studio.svg"
        style="width: 30px; height: 30px"
      />
    </a>

    <mat-accordion *ngIf="nav.childrens && nav.childrens.length > 0">
      <mat-expansion-panel class="mat-panel">
        <mat-expansion-panel-header class="mat-header">
          <a mat-list-item routerLinkActive="router-link-active">
            <div mat-line>{{ nav.name }}</div>
          </a>
        </mat-expansion-panel-header>
        <div *ngIf="nav.childrens">
          <a
            mat-list-item
            *ngFor="let subNav of nav.childrens"
            (click)="linkOnClicked(subNav)"
            routerLinkActive="router-link-active"
          >
            <div mat-line>{{ subNav.name }}</div>
            <mat-icon>{{ subNav.icon }}</mat-icon>
          </a>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <mat-divider></mat-divider>
  <!-- <button
    mat-raised-button
    color="primary"
    (click)="onSetTheme('default-theme')"
  >
    Default
  </button>
  <button mat-raised-button color="primary" (click)="onSetTheme('dark-theme')">
    Dark
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onSetTheme('my-light-theme')"
  >
    Light
  </button> -->
</mat-nav-list>
