<div style="text-align: right; padding: 10px">
  <button
    mat-button
    (click)="openDialog('Add', {})"
    mat-flat-button
    color="primary"
  >
    Add New
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  style="height: calc(100% - 116px); overflow-y: scroll"
>
  <ng-container matColumnDef="id">
    <mat-header-cell mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="scheduleId">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Schedule's ID
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.scheduleId }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="scheduleTitle">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Schedule's Title</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.scheduleTitle }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="scheduleRepeatType">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Repeat Type</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">
      {{ getRepeatType(element.scheduleRepeatType) }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="scheduleRepeatStatus">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Repeat Status
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      {{ getRepeatStatus(element.scheduleRepeatStatus) }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="active">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      Status
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <mat-slide-toggle
        [(ngModel)]="element.active"
        (toggleChange)="slideToggleChange(element)"
      >
      </mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      style="justify-content: flex-end"
    >
      Action
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      style="justify-content: flex-end"
    >
      <a
        style="margin: 5px"
        mat-raised-button
        color="primary"
        (click)="openDialog('Update', element)"
        >Edit</a
      >
      <a
        style="margin: 5px"
        mat-raised-button
        color="warn"
        (click)="openDialog('Delete', element)"
        >Delete</a
      >
    </mat-cell>
  </ng-container>
  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginator
  showFirstLastButtons
  [length]="totalRows"
  [pageIndex]="currentPage"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
