import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ApiConfigurationService } from 'src/app/service/api.configuration.service';
import { CommonService } from 'src/app/service/common.service';
import { NotificationDialog } from './notification-dialog/notification-dialog';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api: ApiConfigurationService,
    public dialog: MatDialog,
    private common: CommonService
  ) {}
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];

  displayedColumns = [
    'alertMessage',
    'alertColourCode',
    'alertIconAttachmentUrl',
    'active',
    'action',
  ];

  dataSource = new MatTableDataSource<any>();
  alerts = <any>[];

  ngOnInit(): void {
    this.initData();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  initData() {
    this.isLoading = true;
    this.api
      .getAlerts({ page: this.currentPage, pageSize: this.pageSize })
      .subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.dataSource.data = res.body;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = res.headers.get('totalrecordcount');
            });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initData();
  }

  openDialog(action: any, obj: any) {
    let datas = {
      data: obj,
      action: action,
      lists: this.alerts,
    };

    const dialogRef = this.dialog.open(NotificationDialog, {
      width: '600px',
      data: datas,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.initData();
      // if (result.event == "Add") {
      //   this.addRowData(result.data);
      // } else if (result.event == "Update") {
      //   this.updateRowData(result.data);
      // } else if (result.event == "Delete") {
      //   this.deleteRowData(result.data);
      // }
    });
  }
  async addRowData(row_obj: any) {
    try {
      let result: any = await this.add(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Added Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async updateRowData(row_obj: any) {
    try {
      let result: any = await this.update(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Updated Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async deleteRowData(row_obj: any) {
    try {
      let result: any = await this.delete(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Delete Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createAlerts(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  update(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createAlerts(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  delete(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createAlerts(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  slideToggleChange(ele: any) {
    ele.active = !ele.active;
    this.update(this.alerts).then((res) => {
      this.initData();
    });
  }
}
