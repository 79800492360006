<!-- <h1 mat-dialog-title>Hi {{data.name}}</h1> -->
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Area</mat-label>
    <input matInput [(ngModel)]="data.area" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Title</mat-label>
    <input matInput [(ngModel)]="data.title" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Save</button>
</div>
