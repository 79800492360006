import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class ApiAttachmentService {
  apiurl = environment.api_url;
  endpoint = "/api/v1/user/attachment";
  constructor(private http: HttpClient, private common: CommonService) {}

  // Attachment Upload
  uploadAttachment(body: any) {
    let url = this.apiurl + this.endpoint;
    return this.http.post(url, body).pipe(catchError(this.common.handleError));
  }
}
