<h1 mat-dialog-title>
  #{{local_data.id}}&nbsp;-&nbsp;{{local_data.requestedByFullName}}
</h1>
<div mat-dialog-content style="display: inline-flex; width: 100%">
  <div style="width: 50%; padding: 5px">
    Locations
    <div
      leaflet
      style="height: 100%"
      leafletDraw
      [leafletDrawOptions]="drawOptions"
      [leafletOptions]="options"
      (leafletMapReady)="onMapReady($event)"
    >
      <div [leafletLayer]="drawnItems"></div>
    </div>
  </div>
  <div style="width: 50%; padding: 5px">
    <mat-form-field appearance="fill">
      <mat-label> Area </mat-label>
      <input
        [disabled]="true"
        placeholder="{{action}} Area"
        matInput
        [(ngModel)]="local_data.areaTitle"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Unit No. </mat-label>
      <input
        [disabled]="true"
        placeholder="{{action}} Unit No."
        matInput
        [(ngModel)]="local_data.unitNumber"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Address </mat-label>
      <input
        [disabled]="true"
        placeholder="{{action}} Address"
        matInput
        [(ngModel)]="local_data.address"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Est. Weight (kg)</mat-label>
      <input
        [disabled]="true"
        placeholder="{{action}} Est. Weight"
        matInput
        [(ngModel)]="local_data.estimateWeightKg"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Remarks</mat-label>
      <input
        [disabled]="true"
        placeholder="{{action}} Remarks"
        matInput
        [(ngModel)]="local_data.remark"
      />
    </mat-form-field>
  </div>
  <!-- <div style="width: 33%; padding: 5px">Uploaded Photo(s)</div> -->
  <!-- <form
    [formGroup]="setupForm"
    #formDirective="ngForm"
    *ngIf="action != 'Delete'; else elseTemplate"
  >
    {{local_data | json}} -->
  <!-- <div style="width: 100%; display: inline-flex; padding: 5px">
      <div style="width: 50%">
        <app-upload-images
          [imgUrl]="local_data.profilePictureUrl"
          [fileKey]="'profilePictureAttachmentId'"
          (fileChange)="fileUploaded($event)"
          [isProfile]="true"
        ></app-upload-images>
      </div>
      <div style="width: 50%; text-align: right">
        <mat-slide-toggle
          style="margin: auto"
          [labelPosition]="'before'"
          [(ngModel)]="local_data.userStatus"
          formControlName="userStatus"
        >
          Status
        </mat-slide-toggle>
      </div>
    </div>

    <mat-form-field appearance="fill">
      <mat-label> Username </mat-label>
      <input
        placeholder="{{action}} Username"
        matInput
        [(ngModel)]="local_data.userName"
        formControlName="userName"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Full Name </mat-label>
      <input
        placeholder="{{action}} Full Name"
        matInput
        [(ngModel)]="local_data.fullName"
        formControlName="fullName"
      />
    </mat-form-field> -->
  <!-- <mat-form-field appearance="fill"
      ><mat-label> Email </mat-label>
      <input
        type="email"
        [email]="true"
        placeholder="{{action}} Email"
        matInput
        [(ngModel)]="local_data.email"
        formControlName="email"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label> Phone Number </mat-label>
      <input
        placeholder="{{action}} Phone Number"
        matInput
        [(ngModel)]="local_data.phoneNumber"
        formControlName="phoneNumber"
      />
    </mat-form-field> -->

  <!-- <mat-form-field appearance="fill">
      <mat-label> Password </mat-label>
      <input
        placeholder="{{action}} Password"
        matInput
        [type]="hide ? 'password' : 'text'"
        [(ngModel)]="local_data.password"
        formControlName="password"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field> -->
  <!-- </form> -->

  <!-- <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.title}}</b>?
  </ng-template> -->
</div>
<div mat-dialog-actions style="justify-content: right">
  <!-- <button
    mat-button
    color="primary"
    [disabled]="isAllowToSave()"
    (click)="doAction()"
  >
    {{action}}
  </button> -->
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Close
  </button>
</div>
