<app-header
  (menuToggle)="drawer.toggle()"
  [(currentRoute)]="currentRoute"
></app-header>

<mat-drawer-container>
  <mat-drawer #drawer mode="side">
    <app-sidebar (menuClicked)="menuOnClicked($event)"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<app-footer></app-footer>
