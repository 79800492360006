import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ChangePasswordDialog } from 'src/app/components/change-password-dialog/change-password-dialog';
import { ApiRepeatScheduleService } from 'src/app/service/api.repeat-schedule.service';
import { CommonService } from 'src/app/service/common.service';
import { RepeatDialog } from './repeat-dialog/repeat-dialog';

@Component({
  selector: 'app-repeat',
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.scss'],
})
export class RepeatComponent implements OnInit, AfterViewInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private api: ApiRepeatScheduleService,
    public dialog: MatDialog,
    private common: CommonService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((res: any) => {
      if (_.isString(res.params)) {
        if (this.common.isJson(res.params)) {
          this.urlParams = JSON.parse(res.params);
        }
      }
    });
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  urlParams = { scheduleTitle: '', scheduleId: '', scheduleDate: '' };

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];

  displayedColumns = [
    'id',
    'scheduleId',
    'scheduleTitle',
    'scheduleRepeatType',
    'scheduleRepeatStatus',
    'active',
    'action',
  ];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.initData();
    if (this.urlParams.scheduleTitle !== '') {
      this.openDialog('Add', {
        scheduleDate: this.urlParams.scheduleDate,
        scheduleId: this.urlParams.scheduleId,
        scheduleTitle: this.urlParams.scheduleTitle,
      });
    }
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  initData() {
    this.isLoading = true;
    this.api
      .getRepeatSchedules({ page: this.currentPage, pageSize: this.pageSize })
      .subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            this.dataSource.data = res.body;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = res.headers.get('totalrecordcount');
            });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initData();
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(RepeatDialog, {
      width: '600px',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      } else if (_.isUndefined(result)) {
        this.initData();
      } else {
        this.initData();
      }
    });
  }
  slideToggleChange(val: any) {
    let body = _.cloneDeep(val);
    delete body.scheduleTitle;
    body.active = !body.active;

    this.update(body).then(
      (res) => {},
      (err) => {}
    );
  }

  async addRowData(row_obj: any) {
    try {
      let result: any = await this.add(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Added Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async updateRowData(row_obj: any) {
    try {
      let result: any = await this.update(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Updated Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      this.common.openSnackBar(error, 'Close');
    }
  }
  async deleteRowData(row_obj: any) {
    try {
      let result: any = await this.delete(row_obj);
      if (result['success']) {
        this.common.openSnackBar('Delete Successful', 'Close');
        this.initData();
      }
    } catch (error) {
      let msg = `Unable delete ${row_obj.userName}, it was reference by other module.`;
      this.common.openSnackBar(msg, 'Close');
    }
  }

  add(body: any) {
    return new Promise((resolve, reject) => {
      this.api.createSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  update(body: any) {
    return new Promise((resolve, reject) => {
      this.api.updateSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  delete(body: any) {
    return new Promise((resolve, reject) => {
      this.api.deleteSchedule(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  openChangePassword(action: any, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(ChangePasswordDialog, {
      width: '600px',
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getRepeatStatus(val: any) {
    let result = '';
    if (val === 0) {
      result = 'Draft';
    }
    if (val === 1) {
      result = 'Scheduled';
    }

    return result;
  }

  getRepeatType(val: any) {
    let result = '';
    if (val === 0) {
      result = 'Daily';
    }
    if (val === 1) {
      result = 'Weekly';
    }
    if (val === 2) {
      result = 'Monthly';
    }
    return result;
  }
}
