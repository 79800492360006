import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import * as L from "leaflet";
import * as _ from "lodash";
import { interval } from "rxjs";
import { ApiDashboardService } from "src/app/service/api.dashboard.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private api_dashboard: ApiDashboardService,
    private common: CommonService,
    private router: Router
  ) {}
  options = {
    layers: [
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 12,
    center: L.latLng(3.1443720245539515, 101.37338884165163),
  };
  showLayer = false;
  layers = <any>[];
  markerLayers: L.LayerGroup = new L.LayerGroup();
  layerMainGroup: L.LayerGroup<any>[] = [];

  private map: any;
  markersLayer = new L.LayerGroup();

  initMap(): void {
    this.map = L.map("map", {
      center: [this.geolocation.lat, this.geolocation.lng],
      zoom: 12,
      closePopupOnClick: false,
    });

    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);

    this.markersLayer.addTo(this.map);
  }

  location: any = [];
  async ngOnInit(): Promise<void> {
    this.geolocation = await this.getGeoLocation();
    this.initMap();
    this.initData();
  }

  getGeoLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          resolve({ lat: res.coords.latitude, lng: res.coords.longitude });
        },
        () => {
          resolve({
            lat: "Please enable location access",
            lng: "Please enable location access",
          });
        },
        { timeout: 10000 }
      );
    });
  }

  dashboard: any;
  geolocation: any;
  async initData() {
    try {
      this.loadDashboard();
    } catch (error) {
      this.common.openSnackBar(error, "Close");
    }
  }
  getDashboard(body: any): any {
    return new Promise((resolve, reject) => {
      this.api_dashboard.getDashboard(body).subscribe(
        (res) => {
          if (_.isArray(res.body) && res.body.length > 0) {
            resolve(res.body);
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  showMap() {
    this.markersLayer.clearLayers();

    if (this.dashboard.length > 0) {
      for (let i = 0; i <= this.dashboard.length - 1; i++) {
        const icon = L.icon({
          iconUrl: "assets/images/marker-icon6.png",
          iconSize: [100, 100],
          // iconAnchor: [13, 0],
          // popupAnchor: [13, 0],
        });
        let item = this.dashboard[i];

        let lat = item["latitude"];
        let lng = item["longitude"];
        let dt = this.common.getFullDateTimeString(item.lastUpdate);

        var popup = L.popup({ autoClose: false, closeOnClick: false })
          .setLatLng([lat, lng])
          .setContent(
            `
            <p><strong>${item.lorryTitle}</strong></p>
            <p><strong>${item.driver?.fullName}</strong></p>
            <p><strong>${item.driver?.phoneNumber}</strong></p>
            <p><strong>${dt}</strong></p>
            `
          );

        const marker = L.marker([lat, lng], { icon })
          .bindPopup(popup)
          .on("mouseover", (e) => {
            marker.openPopup();
          })
          .on("mouseout", (e) => {
            marker.closePopup();
          })
          .on("click", (e) => {
            // this.router.navigateByUrl(
            //   "/schedule-summary" + "/" + item.scheduleId
            // );
          });
        // var tooltip = L.tooltip()
        //   .setLatLng([lat, lng])
        //   .setContent(`<p><strong>${item.lorryTitle}</strong></p>`);

        // marker.bindTooltip(item.lorryTitle);
        // // marker.openTooltip();
        this.markersLayer.addLayer(marker);
      }
    }
  }
  intervallTimer = interval(5000);
  subscription: any;
  loadDashboard() {
    this.subscription = this.intervallTimer.subscribe(async () => {
      this.dashboard = await this.getDashboard({});
      this.showMap();
    });
  }
  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
