import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRepeatScheduleService {
  apiurl = environment.api_url;
  endpoint = '/api/v1/admin/schedule/repeat';
  constructor(private http: HttpClient, private common: CommonService) {}

  getRepeatSchedules(body: any) {
    let url = this.apiurl + this.endpoint;
    let pg = body.page + 1 || 1;
    let pgSz = body.pageSize || 15;

    let para = 'page=' + pg + '&pageSize=' + pgSz;
    console.log(para);
    return this.http
      .get(url, {
        observe: 'response',
        params: new HttpParams({ fromString: para }),
      })
      .pipe(catchError(this.common.handleError));
  }
  getRepeatSchedule(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body;
    return this.http
      .get(url, {
        observe: 'response',
      })
      .pipe(catchError(this.common.handleError));
  }
  createSchedule(body: any) {
    let url = this.apiurl + this.endpoint;
    return this.http.post(url, body).pipe(catchError(this.common.handleError));
  }
  updateSchedule(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body.id;
    return this.http.put(url, body).pipe(catchError(this.common.handleError));
  }
  deleteSchedule(body: any) {
    let url = this.apiurl + this.endpoint + '/' + body.id;
    return this.http.delete(url).pipe(catchError(this.common.handleError));
  }
}
